import React, { useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import CreateBundle from "./CreateBundle";
import axios from "axios";
import authHeader from "../../../../../../auth-header";
import EditBundle from "../EditBundle";
import EditBundleV1 from "../EditBundle";
import KeywordBundle from "./KeywordBundle";
import BlacklistBundle from "./BlacklistBundle";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useZoom } from "../../../../../../../_metronic/partials/layout/zoom/ZoomContext";
const FeedsBundle = () => {
  const [selectedKeywordId, setSelectedKeywordId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bundleSources, setBundleSources] = useState([]);
  const [isKeywordBundleOpen, setIsKeywordBundleOpen] = useState(false);
  const [isBlacklistBundleOpen, setIsBlacklistBundleOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  console.log(isEditModalOpen);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [isSourcePopupOpen, setIsSourcePopupOpen] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false);

  const [feedsToDelete, setFeedsToDelete] = useState([]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [bundleToDelete, setBundleToDelete] = useState(null);
  const [messageresponse, updatemessageresponse] = useState("");
  console.log(messageresponse);
  const [arraydelete, updatearraydelete] = useState([]);
  console.log(arraydelete);
  const { zoomLevel } = useZoom();

  const openModal = (keywordId) => {
    setSelectedKeywordId(keywordId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedKeywordId(null);
    setIsModalOpen(false);
  };

  const openEditModal = (bundle) => {
    console.log("Open Edit Modal Function Called");

    setSelectedBundle(bundle);
    setIsEditModalOpen(true);
  };
  const opendeleteConfirmation = (bundle) => {
    setFeedsToDelete(bundle.feeds);
    console.log("delete bundle.feeds", bundle.feeds);
    console.log("setFeedsToDelete", setFeedsToDelete);

    setShowDeleteConfirmationPopup(true);
    setShowDeleteConfirmation(false);
  };

  const closeEditModal = () => {
    console.log("Close Edit Modal Function Called");

    setSelectedBundle(null);
    setIsEditModalOpen(false);
  };
  const [reloadComponent, setReloadComponent] = useState(false);
  const handleDeleteConfirmation = async (confirmed) => {
    setShowDeleteConfirmationPopup(false);
    setShowDeleteConfirmation(false);

    if (confirmed) {
      setShowDeleteConfirmation(false);

      try {
        const feedIds = feedsToDelete.map((feed) => feed.id).join(",");
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URL}/policy/delete_bundle_after_confirmation/${bundleToDelete.id}`,
          {
            data: {
              delete_associated_feeds: true,
              bundle_feeds_id: feedIds,
            },
            headers: authHeader(),
          }
        );
        console.log("response:", response);
        setShowDeleteConfirmation(false);

        if (response.status === 200) {
          toast.success(response.data.message, { autoClose: 3000 });
          console.log("after delete", response);
          window.location.reload();

          fetchBundles();
        } else {
          console.error(response.data.message, { autoClose: 3000 });
        }
      } catch (error) {
        console.error("Error deleting bundle:", error);
      }
    } else {
      // Show toast error indicating deletion was canceled
    }
  };
  console.log("feedsToDelete:", feedsToDelete);

  const toggleBundle = async (bundleId) => {
    try {
      const bundleToUpdate = bundles.find((bundle) => bundle.id === bundleId);
      if (!bundleToUpdate) {
        console.error("Bundle not found for toggle.");
        return;
      }

      const newIsEnabled = !bundleToUpdate.bundle_is_enabled;
      const apiUrl = `${
        process.env.REACT_APP_API_URL
      }/policy/bundle_enable_disable_v2/${bundleId}/${
        newIsEnabled ? "enable" : "disable"
      }`;

      const response = await axios.post(apiUrl, null, {
        headers: authHeader(),
      });

      if (response.status === 200) {
        const updatedBundles = bundles.map((bundle) =>
          bundle.id === bundleId
            ? { ...bundle, bundle_is_enabled: newIsEnabled }
            : bundle
        );
        setBundles(updatedBundles);
        toast.success(response.data.message);
        console.log("enable disable", response);
        // window.location.reload();
      } else {
        console.error(
          "Failed to toggle bundle. API response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error toggling bundle:", error);
    }
  };
  // Get
  const fetchBundles = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/policy/get_bundles_v3`,
        {
          headers: authHeader(),
        }
      );
      console.log("get bundles", response.data);
      if (response.status === 200) {
        const data = response.data;
        if (data.status === 200) {
          const bundlesData = data.result;
          setBundles(bundlesData);
        } else {
          console.error(
            "NO Bundels API request successful, but data.status is not 200:",
            data.status
          );
        }
      } else if (response.status === 204) {
        // console.log("No data found.");
      } else {
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching bundles:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // Get

  useEffect(() => {
    fetchBundles();
  }, [reloadComponent]);

  const deleteBundle = async (deleteFeeds) => {
    try {
      if (bundleToDelete) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/policy/confirm_before_bundle_deletion/${bundleToDelete.id}`,
          { delete_feeds: deleteFeeds },
          { headers: authHeader() }
        );
        if (response.status === 200) {
          if (
            response.data.message ===
            "Bundle Deleted Successfully along with feeds."
          ) {
            setShowDeleteConfirmationPopup(false);
            setShowDeleteConfirmation(false);
            window.location.reload();
          } else {
            // If the message is different, open the popup
            setShowDeleteConfirmationPopup(false);
          }

          toast.success(response.data.message, { autoClose: 3000 });
          // window.location.reload();

          fetchBundles();
        }
        if (response.status === 200) {
          if (
            response.data.message ===
            "Bundle Deleted Successfully without feeds."
          ) {
            // setReloadComponent(true);

            setShowDeleteConfirmationPopup(false);
            setShowDeleteConfirmation(false);
            window.location.reload();
          } else {
            // If the message is different, open the popup
            setShowDeleteConfirmationPopup(true);
          }

          // toast.success(response.data.message, { autoClose: 3000 });
          fetchBundles();
        }
        if (response.status === 200) {
          // toast.success(response.data.message, { autoClose: 3000 });
          fetchBundles();
          console.log("before delete", response);
          updatemessageresponse(response.data.message);
          updatearraydelete([...response.data.result]);
        } else {
          console.error(
            "Failed to delete bundle. API response status:",
            response.status
          );
        }
      }
    } catch (error) {
      console.error("Error deleting bundle:", error);
    }
  };

  const confirmDeleteBundle = (confirmed) => {
    if (bundleToDelete) {
      const deleteFeeds = confirmed ? true : null;
      deleteBundle(deleteFeeds);
      if (confirmed) {
        closeDeleteConfirmation();
        setShowDeleteConfirmationPopup(true);
      }
    }
  };

  const addNewBundle = async (newBundleData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/policy/create_bundle_v3_with_periodic_source`,
        newBundleData,
        {
          headers: authHeader(),
        }
      );
      // console.log("bundle", response.data);
      if (response.status === 200) {
        // console.log("Bundle created successfully.");
        const updatedBundles = [
          ...bundles,
          { ...newBundleData, bundle_is_enabled: true },
        ];
        setBundles(updatedBundles);
        window.location.reload();

        closeModal();
      } else {
        console.error(
          "Failed to create bundle. API response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error creating bundle:", error);
    }
  };

  const openKeywordBundle = () => {
    setIsKeywordBundleOpen(true);
  };
  const openBlacklistBundle = () => {
    setIsBlacklistBundleOpen(true);
  };
  const closeKeywordBundle = () => {
    setIsKeywordBundleOpen(false);
  };
  const closeBlacklistBundle = () => {
    setIsBlacklistBundleOpen(false);
  };
  const openDeleteConfirmation = (bundle) => {
    setBundleToDelete(bundle);
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setBundleToDelete(null);
    setShowDeleteConfirmation(false);
  };
  const feedfn = () => {
    setShowDeleteConfirmationPopup(true);
    deleteBundle(true);
  };
  const firstclose = () => {
    setShowDeleteConfirmation(false);
    deleteBundle(false);
  };
  const secondclose = () => {
    setShowDeleteConfirmationPopup(false);
    confirmDeleteBundle(false);
  };
  return (
    <div>
      {" "}
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">Bundle</span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              Bundle You Have Added
            </span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a keyword"
          ></div>
        </div>
        <div
          className="card-body py-3"
          style={{ transform: `scale(${zoomLevel})` }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <div className="d-flex justify-content-end gap-4">
                <a
                  href="#"
                  className="btn btn-sm btn-light-primary"
                  onClick={() => openModal(selectedKeywordId)}
                >
                  <KTIcon iconName="plus" className="fs-3" />
                  Add Bundle
                </a>
              </div>
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="w-25px"></th>
                    <th className="min-w-150px text-dark fw-bold fs-6">
                      Title
                    </th>
                    {/* <th className="min-w-140px text-dark fw-bold fs-6">Type</th> */}
                    <th className="min-w-150px text-dark fw-bold fs-6">
                      Feeds
                    </th>

                    <th className="min-w-150px text-end text-dark fw-bold fs-6">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bundles.map((bundle, index) => (
                    <tr key={index}>
                      <td></td>
                      <td className="text-dark">
                        <Link
                          className="text-dark"
                          to={`/user/bundletitle/${bundle.id}`}
                        >
                          {bundle.bundle_title}
                        </Link>
                      </td>
                      <td className="text-dark">
                        {Array.isArray(bundle.feeds) &&
                          bundle.feeds.map((feed, feedIndex) => (
                            <span key={feedIndex}>
                              {feed.feed_title}
                              {feedIndex !== bundle.feeds.length - 1 && ", "}
                            </span>
                          ))}
                      </td>

                      <td>
                        <div className="d-flex justify-content-end flex-shrink-0">
                          <a
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                            // href="#"
                            onClick={() => openEditModal(bundle)}
                          >
                            <KTIcon iconName="pencil" className="fs-3" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                            onClick={() => openDeleteConfirmation(bundle)}
                          >
                            <KTIcon iconName="trash" className="fs-3" />
                          </a>

                          <button
                            type="button"
                            className="btn"
                            style={{
                              width: "50px",
                              height: "25px",
                              backgroundColor: bundle.bundle_is_enabled
                                ? "#4caf50"
                                : "#e0e0e0",
                              borderRadius: "25px",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleBundle(bundle.id)}
                          >
                            <span
                              style={{
                                display: "block",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                left: bundle.bundle_is_enabled ? "25px" : "5px",
                                transition: "left 0.3s ease",
                              }}
                            ></span>
                            <span
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "5px",
                                transform: "translateY(-50%)",
                                color: bundle.bundle_is_enabled
                                  ? "#fff"
                                  : "#000",
                                fontSize: "8px",
                              }}
                            >
                              {/* {bundle.bundle_is_enabled ? "ON" : "OFF"} */}
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showDeleteConfirmation && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowDeleteConfirmation(false)}
                        aria-label="Close"
                      ></button>
                    </div>

                    <p>
                      Do you also want to delete bundle feeds? If no, only
                      bundle will be deleted.
                    </p>
                    <div style={{ marginTop: "20px" }}>
                      <button
                        className="btn btn-primary"
                        onClick={feedfn} // Show the second confirmation popup
                        // () => feedfn(),setShowDeleteConfirmationPopup(true)
                      >
                        Yes
                      </button>{" "}
                      <button
                        className="btn btn-secondary"
                        // onClick={() => deleteBundle(false)}
                        onClick={firstclose}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {isSourcePopupOpen && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "5px",
                      maxWidth: "80%",
                      overflow: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        cursor: "pointer",
                      }}
                      className="btn btn-link btn-sm close-button fw-bold text-lg"
                      onClick={() => setIsSourcePopupOpen(false)}
                    >
                      Sources
                      <RxCross1 style={{ marginLeft: "75px" }} />
                    </button>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {selectedSources.map((source, sourceIndex) => (
                        <li style={{ marginBottom: "20px" }} key={sourceIndex}>
                          <span>{source.platform}</span>
                          {/* ({source.type}) */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CreateBundle
          isOpen={isModalOpen}
          onClose={closeModal}
          onAdd={addNewBundle}
        />
        {isEditModalOpen && (
          <div>
            <EditBundleV1
              isOpen={isEditModalOpen}
              onClose={closeEditModal}
              initialBundle={selectedBundle}
              news_sources={
                selectedBundle
                  ? selectedBundle.sources
                      .filter((source) => source.source_type === "news")
                      .map((filteredSource) => filteredSource.id)
                  : []
              }
              social_sources={
                selectedBundle
                  ? selectedBundle.sources
                      .filter((source) => source.source_type === "social")
                      .map((filteredSource) => filteredSource.id)
                  : []
              }
            />
          </div>
        )}

        {isKeywordBundleOpen && (
          <KeywordBundle
            isOpen={isKeywordBundleOpen}
            onClose={closeKeywordBundle}
            initialBundle={selectedBundle}
            initialSources={selectedBundle ? selectedBundle.sources : []}
          />
        )}
        {isBlacklistBundleOpen && (
          <BlacklistBundle
            isOpen={openBlacklistBundle}
            onClose={closeBlacklistBundle}
            initialBundle={selectedBundle}
            initialSources={selectedBundle ? selectedBundle.sources : []}
          />
        )}
      </div>
      {showDeleteConfirmationPopup && (
        <div
          className="modal fade show"
          tabIndex={-1}
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirmation</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDeleteConfirmationPopup(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>{messageresponse}</p>
                {/* <p>{`Feeds given below are also present in other Bundles. Do you still want to delete those feeds?`}</p> */}
                <ul>
                  {arraydelete.map((element) => (
                    <>
                      <div>
                        <div className="d-flex gap-2 align-items-center">
                          {/* <div>Feed ID :</div>
                          <div>{element.feed_id}</div> */}
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <div>Feed Title :</div>
                          <div>{element.feed_title}</div>
                        </div>
                      </div>
                    </>
                  ))}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleDeleteConfirmation(true)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={secondclose}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedsBundle;

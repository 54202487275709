import React, { FC, useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import axios from "axios";
import { AxiosRequestHeaders } from "axios";
import { UpdateKeyword } from "../../../../_metronic/partials/modals/add-keyword/UpdateKeyword";
import authHeader from "../../../auth-header";
import Editkeyword from "./Editkeyword";

type KeywordData = {
  id: number;
  platform: string;
  domain: string;
  keyword: string;
  keyword_active: boolean;
};

const Keyword: FC = () => {
  const [keywords, setKeywords] = useState<KeywordData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKeywordId, setSelectedKeywordId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBundle, setSelectedBundle] = useState(null); // New state for selected bundle
  const [isEditBundleModalOpen, setIsEditBundleModalOpen] = useState(false); // New state for edit bundle modal
  const [bundleSources, setBundleSource] = useState([])
  const [sources, setSources] = useState([]);
  useEffect(() => {
    fetchKeywords();
  }, []);

  const fetchKeywords = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_new_keywords`, {
        headers: authHeader() as AxiosRequestHeaders,
      });
      const data = response.data;
      if (data.status === 200) {
        setKeywords(data.result);
      }
    } catch (error) {
      console.error("Error fetching keywords:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteKeyword = async (id: number) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/policy/delete_new_keyword/${id}`, {
        headers: authHeader() as AxiosRequestHeaders,
      });
      fetchKeywords();
      alert("Keyword Deleted Successfully.");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting keyword:", error);
      alert("An error occurred while deleting the keyword. Please try again later.");
    }
  };

  const openModal = (keywordId: number) => {
    setSelectedKeywordId(keywordId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedKeywordId(null);
    setIsModalOpen(false);
  };

  const openEditBundleModal = (bundle: any) => {
    setSelectedBundle(bundle);
    setIsEditBundleModalOpen(true);
  };

  const closeEditBundleModal = () => {
    setSelectedBundle(null);
    setIsEditBundleModalOpen(false);
  };

  return (
    <div className={`card `}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Keyword</span>
          <span className="text-muted mt-1 fw-semibold fs-7">Keywords you have added</span>
        </h3>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a keyword"
        >
          <a
            href="#"
            className="btn btn-sm btn-light-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_keyword"
          >
            <KTIcon iconName="plus" className="fs-3" />
            Add Keyword
          </a>
        </div>
      </div>
    
      <div className="card-body py-3">

      {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (     <div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr className="fw-bold text-muted">
              <th className="w-25px"></th>
              <th className="min-w-150px text-dark fw-bold fs-6">Platform</th>
              <th className="min-w-140px text-dark fw-bold fs-6">Domain</th>
              <th className="min-w-120px text-dark fw-bold fs-6">Keyword</th>
              <th className="min-w-120px text-dark fw-bold fs-6">Status</th>
              <th className="min-w-100px text-end text-dark fw-bold fs-6">Actions</th>
            </tr>
          </thead>

          <tbody>
            {keywords.map((data) => (
              <tr key={data.id}>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input className="form-check-input widget-9-check" type="checkbox" value="1" />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                      <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
                        {data.platform}
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <span className="text-muted fw-semibold text-muted d-block fs-7">{data.domain}</span>
                </td>
                <td className="text-end">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-semibold">{data.keyword}</span>
                    </div>
                  </div>
                </td>
                <td className="text-end">
                  <div className="d-flex flex-column w-100 me-2">
                    <div className="d-flex flex-stack mb-2">
                      <span className="text-muted me-2 fs-7 fw-semibold">
                        {data.keyword_active ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="d-flex justify-content-end flex-shrink-0">
                    <a
                      className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      href="#"
                      onClick={() => openEditBundleModal(data)} 
                    >
                      <KTIcon iconName="pencil" className="fs-3" />
                    </a>
                    <a
                      href="#"
                      className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                      onClick={() => deleteKeyword(data.id)}
                    >
                      <KTIcon iconName="trash" className="fs-3" />
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>) }
   
      </div>
       {isModalOpen && (
        <UpdateKeyword isOpen={isModalOpen} onClose={closeModal} keywordId={selectedKeywordId as number} />
      )}

      {isEditBundleModalOpen && (
        <Editkeyword
          isOpen={isEditBundleModalOpen}
          onClose={closeEditBundleModal}
          initialBundle={selectedBundle} 
          initialSources={sources} 
           bundleSources={bundleSources}
        />
      )}
    </div>
  );
};

export { Keyword };

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from '../../auth-header';
import { FaCheck } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';

const Subscriptionplans = () => {
  const [plans, setPlans] = useState([]);
  const [activeDuration, setActiveDuration] = useState('monthly');
  const [addOns, setAddOns] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [showAddOnPopup, setShowAddOnPopup] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [addonIdFromGetRequest, setAddonIdFromGetRequest] = useState(null);

  const durationMap = {
    'Monthly Plan': 'monthly',
    'Yearly Plan': 'yearly',
    'Bi Annual Plan': 'biannual',
  };

  useEffect(() => {
    fetchPlans(activeDuration);
    fetchAddOns();
  }, [activeDuration]);

  const fetchPlans = (duration) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_subscription_plans/${duration}`, {
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        if (data.status === 200) {
          setPlans(data.result);
        } else {
          console.error('Failed to fetch plans:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching plans:', error);
      });
  };

  const handleChange = (label) => {
    const duration = durationMap[label];
    setActiveDuration(duration);
  };

  const handleAddOnChange = (addOnId) => {
    
    setSelectedAddOns((prevSelected) =>
      prevSelected.includes(addOnId)
        ? prevSelected.filter((id) => id !== addOnId)
        : [...prevSelected, addOnId]
    );
  };
  

  const fetchAddOns = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_addons`, {
        headers: authHeader(),
      })
      .then((response) => {
        const { data } = response;
        if (data.status === 200) {
          setAddOns(data.result);
  
          // Assuming you want to use the ID from the first addon in the response
          if (data.result.length > 0) {
            setAddonIdFromGetRequest(data.result[0].id);
          }
        } else {
          console.error('Failed to fetch add-ons:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching add-ons:', error);
      });
  };
  

  const handleSubmit = async () => {
    // console.log('handleSubmit called'); 
  
    if (!selectedPlan || !selectedPlan.id || !addonIdFromGetRequest) {
      console.error('Selected plan or addon ID is missing.');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('id', selectedPlan.id);
      formData.append('add_on', addonIdFromGetRequest);
  
      // Append selected addon IDs to the formData
      selectedAddOns.forEach((addOnId) => {
        formData.append('addon_ids[]', addOnId);
      });
  
      // Convert FormData to an object
      const formDataObject = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
  
      // Send the request with formDataObject
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/policy/create_checkout_session`,
        formDataObject,
        { headers: authHeader() }
      );
      // console.log('Response:', res);
  
      // console.log('showAddOnPopup:', showAddOnPopup);
      // console.log('Redirecting to Stripe checkout:', res.data.url);
  
      if (res.data.status === 200) {
        if (showAddOnPopup) {
          setShowAddOnPopup(false);
          window.location.href = res.data.url;
          // console.log('Setting showAddOnPopup to false.');

        } else {
          window.location.href = res.data.url;        }
      } else if (res.data.status === 400) {
        console.error('Request failed with status code 400:', res.data);
        alert('Cannot proceed with payment');
      } else {
        console.error('Request failed with an unexpected status code:', res.data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  
  
  
  

  const popupStyles = {
    position: 'fixed',
    top: '150px', // Adjust the top position as needed
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    zIndex: '1000',
    borderRadius:"10px"
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    border:"none", backgroundColor:"white", 
    fontSize: '24px', 
  fontWeight: 'bold',
    
  };

  return (
    <>
   
      <div className="pricing-billing-duration text-center">
        <ul className="list-inline">
          {['Monthly Plan', 'Yearly Plan', 'Bi Annual Plan'].map((label) => (
            <li className="list-inline-item" key={label}>
              <button
                onClick={() => handleChange(label)}
                style={{ fontSize: '18px' }}
                className={`btn btn-outline-info ${
                  durationMap[label] === activeDuration ? 'active' : ''
                }`}
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="row">
        {plans.map((plan) => (
          <div className="col-lg-4" key={plan.id}>
            <div className="pricing-table pricing-bordered border">
              <div className="pricing-header">
                <h3 className="title">{plan.title}</h3>
                <div className="price-wrap">
                  <div className="yearly-pricing">
                    <span className="amount">${plan.price}</span>
                  </div>
                </div>
                <div className="pricing-btn d-flex justify-content-center align-items-center">
                  <button
                    className="axil-btn btn-lg btn-borderd"
                    style={{
                      padding: '10px 20px',
                      borderRadius: '10px',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      // console.log('Get Started button clicked');
                      // console.log('showAddOnPopup before:', showAddOnPopup);

                      setShowAddOnPopup(true);
                      setSelectedPlan(plan);
                      // console.log('showAddOnPopup after:', showAddOnPopup);
                    }}
                  >
                    Get Started
                  </button>
                </div>
              </div>
              <div className="pricing-body">
                <p>{plan.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showAddOnPopup && (
        <div style={popupStyles}>
          <button
            style={closeButtonStyles}
            onClick={() => {
              setShowAddOnPopup(false);
            }}
          >
            &times;
          </button>
          <div className="add-on-popup-content gap-4">
            <h3 className='text-center mt-5'>Select Add-Ons</h3>
            {addOns.map((addOn) => (
              <div key={addOn.id} className='mt-2'>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => handleAddOnChange(addOn.id)}
                    checked={selectedAddOns.includes(addOn.id)}
                  />
                  {addOn.add_on}
                </label>
              </div>
            ))}
            <div className="d-flex justify-content-center align-items-center">
      <button
        className="axil-btn btn-lg btn-borderd mt-5"
        style={{
          padding: '5px 10px',
          borderRadius: '10px',
          fontWeight: 'bold',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
        onClick={() => {
          // Before calling handleSubmit, make sure addonIdFromGetRequest is set
          if (addonIdFromGetRequest) {
            handleSubmit();
          } else {
            console.error('Addon ID is missing.');
          }
        }}
      >
        Submit
      </button>
    </div>
           
          </div>
        </div>
      )}
    </>
  );
};

export default Subscriptionplans;

// @flow
import * as React from "react";

import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import DashboardTable from "./Tables/DashboardTable";
import DashboardResults from "./Tables/DashboardResults";
const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "",
    path: "",
    isSeparator: false,
    isActive: false,
  },
];

export default function UserFeeds() {
  return (
    <div>
      <PageTitle >User Feeds</PageTitle>
      <div className="row g-5 g-xl-12">
      {/* <DashboardTable/> */}



          <DashboardResults className=""/>

          
      
           
        
    
          
     
   
  
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import axios from "axios";
import authHeader from "../../../../../../auth-header";
import CreateBundleSocialDropdown from "./CreateBundleSocialDropdown";
import CreateBundleNewsDropdown from "./CreateBundleNewsDropdown";
import CreateKeywordBundleDropdown from "./CreateKeywordBundleDropdown";
import { ToastContainer } from "react-toastify";
import { toast } from 'react-toastify';
import CreateBlacklistBundleDropdown from "./CreateBlacklistBundleDropdown";

const BlacklistBundle = ({ isOpen, onClose, initialBundle, initialSources }) => {
  const initialFormState = {
    keyword: "", 
    bundle_id: "", 
  };

  const [formData, setFormData] = useState(initialBundle || initialFormState);
  const [validationError, setValidationError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setFormData({
      ...initialBundle,
      bundle_id: initialBundle?.bundle_id || "", // Set bundle_id
    });
    // console.log("Initial Sources:", initialSources);
  }, [initialBundle, initialSources]);

  const handleSourceChange = (selectedSources) => {
    setFormData({
      ...formData,
      bundle_id: selectedSources, // Set bundle_id based on selectedSources
    });
  };

  const handleSaveChanges = (event) => {
    event.preventDefault();
  
    if (!formData.bundle_id) {
      setValidationError("Please select a bundle.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }
  
    const bundleId = parseInt(formData.bundle_id);
  
    if (isNaN(bundleId)) {
      setValidationError("Bundle ID is not valid.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }
  
    const dataToSend = {
      keyword: formData.keyword,
      bundle_id: bundleId,
    };
  
    axios
      .post(`${process.env.REACT_APP_API_URL}/policy/add_blacklist_keyword_to_bundle`, dataToSend, {
        headers: authHeader(),
      })
      .then((response) => {
        // console.log("API Response:", response.data);
  
        if (response.status === 200) {
          toast.success("Changes saved successfully");
          // window.location.reload();
        } else {
          toast.error("Failed to save changes");
        }
  
        onClose();
      })
      .catch((error) => {
        console.error("Error adding keyword to bundle:", error);
        toast.error("An error occurred while saving changes");
      });
  };
  

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Blacklist</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={handleSaveChanges}>
            <div className="modal-body">
              {validationError && (
                <div className="alert alert-danger" role="alert">
                  {validationError}
                </div>
              )}
              {saveSuccess && (
                <div className="alert alert-success" role="alert">
                  Changes saved successfully.
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="keyword" className="form-label">
                  Keyword
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="keyword"
                  name="keyword"
                  value={formData.keyword}
                  onChange={handleInputChange}
                  placeholder="Enter Keyword"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="bundle_id" className="form-label">
                  Add to Bundle
                </label>
                <div className="d-flex">
                  <CreateBlacklistBundleDropdown
                    selectedSources={formData.bundle_id}
                    onChange={handleSourceChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  
};

export default BlacklistBundle;

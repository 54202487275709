// @flow
import * as React from "react";
import Profile from "./Profile";
import Sharing from "./Sharing";
import ExportData from "./ExportData";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "Setting Page",
    path: "/setting",
    isSeparator: false,
    isActive: false,
  },
];

export default function AdminSetting() {
  return (
    <div>
      <PageTitle breadcrumbs={wizardsBreadCrumbs}>Setting</PageTitle>
      <div className="row g-5 g-xl-12">
        <div className="d-flex justify-content-center align-items-center   mb-3">
          <div>
            <div className="card pt-9 pb-0">
              <Profile />
            </div>
            {/* <div className="card pt-9 pb-0 mt-5">
              <Sharing />
            </div> */}
          </div>
        </div>
        {/* <div className="col-xl-6  mb-3">
          <div>
            <div className="card pt-9 pb-0">
              <ExportData />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

import React from 'react';



import FooterOne from './FooterOne';
import HeaderOne from './Header/HeaderOne';
import PricingOne from '../../../../../pages/billing/Pricing';

import Main from './Main/Main';
import HeadlineNews from './Main/Headlinenews';
import News from './News';
import AboveFooterArticles from './Abovefooter';
const Publiclandingpage = () => {

    return (
        <>
     
        <main className="main-wrapper" style={{overflowX:"hidden"}}>
            <HeaderOne />
           
           
            <div style={{marginTop:"100px"}}>
            <Main/>
            </div>
        <div className='d-flex justify-content-center align-items-center'>
            <HeadlineNews/>
        </div>
        <News/>
{/* <div className='mt-10 '>
    <h2 className='text-center fs-1 'style={{marginTop:"150px"}}>Subcription Plans</h2>
    <div style={{marginTop:"80px"}}><PricingOne className='mt-10' />
</div>
</div> */}
            {/* <div className="section section-padding-2 bg-color-dark  " style={{marginTop:"250px"}}>
                <div className="container">
                  
                  
               
                </div>
           
            </div> */}
           
     <AboveFooterArticles/>
           <FooterOne/>
   
        </main>
        </>
    )
}

export default Publiclandingpage;


// Getpocket.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import { toast } from "react-toastify";
import { BsFillEyeFill } from "react-icons/bs";
import PocketDiscover from "./Pocketdiscover";
import { useZoom } from "../../../_metronic/partials/layout/zoom/ZoomContext";
function Getpocket() {
  const [articleData, setArticleData] = useState([]);
  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const [articleNotFound, setArticleNotFound] = useState(false);
  const [responseStatus, setResponseStatus] = useState(null);
  const { zoomLevel } = useZoom();

  const handleAuthenticate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/article/authenticate_get_pocket`,
        {
          headers: authHeader(),
        }
      );
      const authorize_url = response.data.result.authorize_url;
      const request_token = response.data.result.request_token;
      localStorage.setItem("pocket_token", request_token);
      window.location.href = authorize_url;
    } catch (error) {
      console.error("Error authenticating with GetPocket:", error);
      setResponseStatus(400);
    }
  };

  useEffect(() => {
    const pocket_token = localStorage.getItem("pocket_token");

    if (pocket_token) {
      const saveTokenUrl = `${process.env.REACT_APP_API_URL}/article/thank_you_for_get_pocket?request_token=${pocket_token}`;

      axios
        .get(saveTokenUrl, {
          headers: authHeader(),
        })
        .then((saveTokenResponse) => {
          const { status, data } = saveTokenResponse;

          if (status === 200) {
            const get_pocket_token = data.result;
            localStorage.setItem("get_pocket_token", get_pocket_token);
            localStorage.removeItem("pocket_token");
            setIsTokenAvailable(true);
          } else if (status === 400) {
            if (data && data.message === "User Already has a Token.") {
              toast.error("User Already has a Token.");
            } else {
              toast.error("An error occurred while saving the request token.");
            }
          } else if (status === 204) {
            if (data && data.message === "Article Not Found") {
              setArticleNotFound(true);
            } else {
              toast.error("An error occurred while saving the request token.");
            }
          } else {
            toast.error("An error occurred while saving the request token.");
          }
        })
        .catch((error) => {
          console.error("Error saving token:", error);
        });
    }
  }, []);

  useEffect(() => {
    const get_pocket_token = localStorage.getItem("get_pocket_token");

    if (get_pocket_token === "null" || get_pocket_token === "undefined") {
      setIsTokenAvailable(false);
    } else {
      setIsTokenAvailable(true);
    }
  }, []);

  useEffect(() => {
    if (isTokenAvailable) {
      fetchArticleData();
    }
  }, [isTokenAvailable]);

  const fetchArticleData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/article/fetch_pocket_articles`;
      const response = await axios.get(apiUrl, {
        headers: authHeader(),
      });
// console.log("pocket",response.data)
      if (response.status === 204 && response.data.message === "Article Not Found") {
        setArticleNotFound(true);
      } else if (response.status === 200 && response.data.message === "Articles Found") {
        setArticleData(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching article data:", error);
    }
  };

  function convertUnixTimestamp(timestamp) {
    const unixTimestamp = timestamp * 1000;
    const utcDate = new Date(unixTimestamp);

    const year = utcDate.getFullYear();
    const month = (utcDate.getMonth() + 1).toString().padStart(2, '0');
    const day = utcDate.getDate().toString().padStart(2, '0');
    const hours = utcDate.getHours().toString().padStart(2, '0');
    const minutes = utcDate.getMinutes().toString().padStart(2, '0');
    const seconds = utcDate.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
  }

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div style={{ transform: `scale(${zoomLevel})` }}>
      {articleNotFound ? (
        <p>Article Not Found. Add an article firstly.</p>
      ) : isTokenAvailable ? (
        <div>
          <h1 className="card-title pt-5">
            <strong className="text-dark">Pocket Library</strong>
          </h1>

          <div className="row d-flex">
         
            <div className="col-lg-6">
              {articleData && Object.keys(articleData).length > 0 ? (
                Object.values(articleData).map((article) => (
                  <div key={article.item_id} className="card mb-4" style={{ maxWidth: '100%', borderRadius: '20px ' }}>
                     <img
            src={article.top_image_url}
            alt="Top Image"
            style={{ width: '100%', height: 'auto', borderRadius: '20px' }}
          />
                    <div className="row">
                      
                      <div className="col-lg-10">
                      
                        <div className="clearfix p-5">
                          <div className="d-flex " style={{ gap: "20px" }}>
                            {/* <img
                              src={article.domain_metadata.greyscale_logo}
                              alt="..."
                              style={{
                                height: "40px",
                                width: "40px",
                                marginTop: "10px",
                              }}
                            /> */}
                            <h1 className="card-title p-5">
                              <strong className="text-dark">
                                {truncateText(article.resolved_title, 50)}
                              </strong>
                            </h1>
                          </div>
                          
                          <div className="mb-4 p-5">
                            <br />
                            <br />
                            <span className="mt-5">
                              <a
                                href={article.resolved_url}
                                target="_blank"
                                style={{
                                  fontSize: "12px",
                                  display: "inline-block",
                                  padding: "10px 20px",
                                  background: "#007bff",
                                  color: "#fff",
                                  borderRadius: "5px",
                                  textDecoration: "none",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                View on Get Pocket
                                <BsFillEyeFill style={{ marginLeft: "5px" }} />
                              </a>
                              
                            </span>
                            <p className="text-dark mt-2 font-weight-bold">
                              {truncateText(article.excerpt, 150)}
                            </p>
                          </div>
                          <div className="mb-5 gap-4  d-flex">
                            <p className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                              Word Count: {article.word_count}
                            </p>
                            <p className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                              Article Language: {article.lang}
                            </p>
                           
                          </div>
                          <p className="text-dark mt-2 font-weight-bold">
                            Time Added: {convertUnixTimestamp(article.time_added)}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div
                          className="card text-white mb-5"
                          style={{
                            maxWidth: "400px",
                            backgroundColor: "#FDFEFE",
                          }}
                        >
                          {/* <img
                            src={article.domain_metadata.logo}
                            alt="Domain Logo"
                          /> */}
                        </div>
                        <div className="symbol symbol-100px mt-5 p-5">
                        </div>
                        <div
                          className="d-flex justify-content-end mb-5 "
                          style={{
                            position: "absolute",
                            bottom: 0,
                            marginLeft: "1rem",
                          }}
                        >
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                "No articles found. Please add articles to your Get Pocket account."
              )}
            </div>
            <div className="col-lg-6">
  <div className="row">
    {articleData && Object.keys(articleData).length > 0 ? (
      Object.values(articleData).map((article) => (
        
        <div key={article.item_id} className="col-md-6 mb-4">
         
          <div className="card " style={{ height: "450px", width: "100%", borderRadius: '20px' }}>
          <img
               src={article.top_image_url}
               alt=".."
               style={{
                 height: "150px",
                 width: "100%",
                 objectFit:"cover"
                 , borderRadius: '20px'
               }}
              />
            <div className="clearfix p-5">
             
              <div className="d-flex">
             
              <h5 className="card-title mt-3">
                <strong className="text-dark">
                  {truncateText(article.resolved_title, 20)}
                </strong>
              </h5>
              </div>
              
             
              <p className="text-dark mt-2 font-weight-bold">
                {truncateText(article.excerpt, 40)}
              </p>
              <a
                href={article.resolved_url}
                target="_blank"
                style={{
                  fontSize: "12px",
                  display: "inline-block",
                  padding: "10px 20px",
                  background: "#007bff",
                  color: "#fff",
                  borderRadius: "5px",
                  textDecoration: "none",
                  textAlign: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                View on Get Pocket
                <BsFillEyeFill style={{ marginLeft: "5px" }} />
              </a>
              <p className="text-dark mt-2 font-weight-bold">
                Time Added: {convertUnixTimestamp(article.time_added)}
              </p>
            </div>
          </div>
        </div>
      ))
    ) : (
      null
    )}
  </div>
</div>

          </div>
        </div>
      ) : (
        responseStatus === 204 ? (
          "Loading article data"
        ) : (
          <div className="d-flex justify-content-center align-items-center p-2">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleAuthenticate}
            >
              Authenticate
            </button>
          </div>
        )
      )}
      <div style={{ width: '80%', margin: 'auto', marginTop:"100px" }}>
        {/* <PocketDiscover /> */}
      </div>
    </div>
  );
}

export default Getpocket;

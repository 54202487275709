// @flow
import * as React from "react";
import Profile from  "../../../app/pages/settings/Profile"
import Sharing from "../../../app/pages/settings/Sharing";
import ExportData from "../../../app/pages/settings/ExportData";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import UserDetailTable from "./Tables/UserDetailTable";
const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "Setting Page",
    path: "/setting",
    isSeparator: false,
    isActive: false,
  },
];

export default function User() {
  return (
    <div>
      <PageTitle >User</PageTitle>
      <div className="row g-5 g-xl-12">
        <div className="col-xl-12  mb-3">
          <div>
    
            <div className="card pt-9 pb-0 mt-5">
              <UserDetailTable/>
            </div>
          </div>
        </div>
        <div className="col-xl-12  mb-3">
          <div>
            <div className="card pt-9 pb-0">
              {/* <ExportData /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

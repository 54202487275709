import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { MdCancel } from "react-icons/md";
import axios from "axios"; // Import Axios
import authHeader from "../../../../../../auth-header";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateKeywordBundleDropdown({ onChange }) {
  const theme = useTheme();
  const [sources, setSources] = useState([]); // Rename personName to sources
  const [selectedSources, setSelectedSources] = useState([]); // New state variable for selected options

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_bundles`, {
        headers: authHeader(),
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 200) {
          const bundlesData = data.result.map((bundle) => ({
            id: bundle.id,
            bundleTitle: bundle.bundle_title,
          }));
          setSources(bundlesData);
        }
      })
      .catch((error) => {
        console.error("Error fetching bundles:", error);
      });
  }, []);

  const handleChange = (event) => {
    setSelectedSources(event.target.value);
    // Extract the selected bundle IDs from the sources array
    const selectedBundleIds = event.target.value.map((selectedBundle) => selectedBundle.id);
    onChange(selectedBundleIds);
  };

  const handleDeleteOption = (optionToDelete) => (event) => {
    event.stopPropagation();
    setSelectedSources((prevSelected) =>
      prevSelected.filter((bundle) => bundle.id !== optionToDelete.id)
    );
    // Extract the updated selected bundle IDs from the sources array
    const updatedBundleIds = selectedSources.filter((bundle) => bundle.id !== optionToDelete.id).map((bundle) => bundle.id);
    onChange(updatedBundleIds);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-multiple-chip-label">Bundles</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedSources}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value.id}
                  label={value.bundleTitle}
                  onDelete={handleDeleteOption(value)}
                  deleteIcon={<MdCancel />}
                  onMouseDown={(event) => event.stopPropagation()}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {sources.map((bundle) => (
            <MenuItem
              key={bundle.id}
              value={bundle}
              style={{
                fontWeight:
                  selectedSources.findIndex((selected) => selected.id === bundle.id) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
              }}
            >
              {bundle.bundleTitle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

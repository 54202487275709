import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";

const News = () => {
  const [selectedCategory, setSelectedCategory] = useState("Sport");
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      const firstCategory = categories[0].toLowerCase();
      setSelectedCategory(firstCategory);
      fetchDataForCategory(firstCategory);
    }
  }, [categories]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/policy/get_articles_categories_for_public_users`
      );
      setCategories(
        response.data.result.map((item) =>
          capitalizeFirstLetter(item.topic_name)
        )
      );
      console.log(response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  function capitalizeFirstLetter(str) {
    if (str === null) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const fetchDataForCategory = async (category) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/policy/home_page_articles_for_public_users?topic=${category}&section=topics_section`
      );
      setCategoryData(response.data.results.result);
      console.log(response);
    } catch (error) {
      console.error(`Error fetching data for category ${category}:`, error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    fetchDataForCategory(category.toLowerCase());
  };

  const getTruncatedContent = (content, maxLength) => {
    if (content.length <= maxLength) {
      return content;
    } else {
      return content.substring(0, maxLength) + "...";
    }
  };

  return (
    <div className="news-container">
      <div className="news-buttons">
        <div
          className="row"
          style={{ overflowX: "auto", whiteSpace: "nowrap" }}
        >
          {categories.map((category) => (
            <button
              key={category}
              className={`border border-dark fs-4 mr-2 ${
                selectedCategory === category.toLowerCase()
                  ? "bg-dark text-white"
                  : ""
              }`}
              onClick={() => handleCategoryClick(category.toLowerCase())}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="news-category">
        <div className="news-cards mt-10">
          {/* <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3"> */}
          <div className="d-flex flex-wrap justify-content-center">
            {categoryData.map((article, index) => (
              <div key={article.id} className="col mb-3">
                {index < 3 && (
                  <Card
                    className="news-card h-100"
                    style={{ marginRight: "20px" }}
                  >
                    <h2 className=" fs-6 " style={{ height: "40px" }}>
                      {article.title}
                    </h2>

                    <div className="d-flex mb-2 mt-2 gap-2">
                      <h5 className="text-bold fs-6 ">
                        {capitalizeFirstLetter(article.source)}
                      </h5>
                      <h5 className="text-bold fs-6 "> | </h5>
                      <h5 className="text-bold fs-6 ">{article.author}</h5>
                    </div>
                    <Card.Img
                      variant="top"
                      src={article.image}
                      alt={article.title}
                    />
                    <Card.Body>
                      <Card.Text>
                        <p>
                          {getTruncatedContent(article.summarized_content, 150)}
                        </p>
                      </Card.Text>
                      <a
                        href={article.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read more
                      </a>
                    </Card.Body>
                  </Card>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;

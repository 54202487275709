/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMEnuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useState } from 'react';
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function AsideMenuMain(Props: any) {
  const intl = useIntl();
  const navigate = useNavigate();

  const [flag, setflag] = useState<string>()
  // 'ABC','CNN','Associate Press','Axios','BBC','CBS','FOXNEWS','CNBC','YAHOO','POLITICO','REUTERS'
  const temp = JSON.parse(localStorage.getItem("Enable") || "[]");

  // Api Call For News Items 
  const [menuItems, setMenuItems] = useState<any[]>([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/article/newsbycount_v1`, {
          headers: authHeader() as AxiosRequestHeaders,
        });
  
        if (response.status === 200) {
          const data = response.data;
          const newMenuItems = data.result.map((item: any) => {
            return (
              <AsideMenuItem
                key={item.source_name}
                to={`/news/pages/${item.source_name.toLowerCase()}`}
                icon="shield-tick"
                title={item.source_name}
                enable={temp.includes(item.source_name) ? true : false}
              />
            );
          });
          setMenuItems(newMenuItems);
        } else if (response.status === 401) {
          // Handle 401 Unauthorized: Redirect user to login page
          window.location.href = '/auth/login'; // Redirect the user to /auth/login
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    if (menuItems.length === 0) {
      fetchData();
    }
  }, [temp]);
  
  return (
    <>
      {/* ----------Dashboard--------- */}
      <AsideMenuItem
        to="/admin/dashboard"
        icon="element-11"
        title="Dashboard"
      />
      {/* ----------My Feeds--------- */}
      {/* <AsideMenuItem to="userfeeds" icon="shield-tick" title="User Feeds" />{" "} */}
      {/* -----------Setting---------- */}
      <AsideMenuItem to="userfeeds" icon="setting" title="User Feeds" />
      
      <AsideMenuItem to="settings" icon="setting" title="Settings" />{" "}
      <AsideMenuItem to="users" icon="setting" title="User" />{" "}
      {/* -----------Billing---------- */}
      {/* <AsideMenuItem to="adminuser" icon="wallet" title="User" />{" "} */}
      {/* ----------Home--------- */}
      {/* <AsideMenuItem to="/builder" icon="switch" title="Layout Builder" /> */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Home
          </span>
        </div>
      </div> */}
     
      {/* <AsideMenuItem
        to="/apps/home/favourite"
        icon="element-4"
        title="Favourites"
      />
      <AsideMenuItem
        to="/apps/home/read-later"
        icon="element-4"
        title="Read Later"
      />{" "}
       <AsideMenuItem
        to="/apps/home/bundle"
        icon="element-4"
        title="Bundles"
      />{" "}
      <AsideMenuItem
        to="/apps/home/concerts"
        icon="element-4"
        title="Concerts"
      />
      <AsideMenuItem
        to="/apps/home/politics"
        icon="element-4"
        title="Politics"
      />
      <AsideMenuItem to="/apps/home/people" icon="element-4" title="People" /> */}
      
      {/* <AsideMenuItem
        to="/apps/home/on-the-wire"
        icon="element-4"
        title="On The Wire"
      />{" "} */}
      {/* <AsideMenuItem to="/apps/home/upgrade" icon="element-4" title="Upgrade" />{" "} */}
      {/* ----------News--------- */}



   
      {/* <AsideMenuItem
        to="/news/pages/reuters"
        icon="shield-tick"
        title="Wikipedia"
        enable={temp.includes("REUTERS")?true:false}
      />
          <AsideMenuItem
        to="/news/pages/politico"
        icon="shield-tick"
        title="Medium"
        enable={temp.includes("POLITICO")?true:false}
      /> */}
     
      {/* <AsideMenuItem to="/news/pages/cnn" icon="shield-tick" title="CNN" enable={temp.includes("CNN")?true:false}/>{" "}
      <AsideMenuItem to="/news/pages/abc" icon="shield-tick" title="Bing" enable={temp.includes("ABC")?true:false}/>{" "} */}
      {/* <AsideMenuItem
        to="/news/pages/ap"
        icon="shield-tick"
        title="Associate Press"
        enable={temp.includes("AssociatePress")?true:false}
      /> */}
      {/* <AsideMenuItem to="/news/pages/axios" icon="shield-tick" title="Axios" enable={temp.includes("ABC")?true:false} /> */}
      {/* <AsideMenuItem to="/news/pages/bbc" icon="shield-tick" title="BBC"enable={temp.includes("ABC")?true:false} />{" "} */}
      {/* <AsideMenuItem to="/news/pages/cbs" icon="shield-tick" title="CBS"enable={temp.includes("CBS")?true:false} /> */}
      {/* <AsideMenuItem
        to="/news/pages/foxnews"
        icon="shield-tick"
        title="FOX NEWS"
        enable={temp.includes("FOXNEWS")?true:false}
      /> */}
      {/* <AsideMenuItem to="/news/pages/cnbc" icon="shield-tick" title="CNBC"enable={temp.includes("CNBC")?true:false} />{" "}
      <AsideMenuItem to="/news/pages/yahoo" icon="shield-tick" title="YAHOO" enable={temp.includes("YAHOO")?true:false}/>{" "}
      <AsideMenuItem
        to="/news/pages/politico"
        icon="shield-tick"
        title="Medium"
        enable={temp.includes("POLITICO")?true:false}
      />{" "}
        <AsideMenuItem
        to="/news/pages/google"
        icon="shield-tick"
        title="Google"
        enable={temp.includes("GOOGLE")?true:false}
      />
   {" "} */}
      {/* ----------Policies--------- */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Policies
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/apps/chat"
        title="Create Policy"
        icon="message-text-2"
      >
        <AsideMenuItem
          to="/apps/policies/keyword"
          title="Keyword"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/policies/company"
          title="Company"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/policies/vendor"
          title="Vendor"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/policies/indiviual"
          title="Indiviual"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/policies/brands"
          title="Brands"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/policies/products"
          title="Products"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/policies/projects"
          title="Projects"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* ----------Crafted--------- */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Crafted
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub to="/crafted/pages" title="Pages" icon="gift">
        <AsideMenuItemWithSub
          to="/crafted/pages/profile"
          title="Profile"
          hasBullet={true}
        >
          <AsideMenuItem
            to="/crafted/pages/profile/overview"
            title="Overview"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/projects"
            title="Projects"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/campaigns"
            title="Campaigns"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/documents"
            title="Documents"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/profile/connections"
            title="Connections"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub
          to="/crafted/pages/wizards"
          title="Wizards"
          hasBullet={true}
        >
          <AsideMenuItem
            to="/crafted/pages/wizards/horizontal"
            title="Horizontal"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/crafted/pages/wizards/vertical"
            title="Vertical"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/crafted/accounts"
        title="Accounts"
        icon="profile-circle"
      >
        <AsideMenuItem
          to="/crafted/account/overview"
          title="Overview"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/account/settings"
          title="Settings"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/error" title="Errors" icon="cross-circle">
        <AsideMenuItem to="/error/404" title="Error 404" hasBullet={true} />
        <AsideMenuItem to="/error/500" title="Error 500" hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/crafted/widgets"
        title="Widgets"
        icon="element-plus"
      >
        <AsideMenuItem
          to="/crafted/widgets/lists"
          title="Lists"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/statistics"
          title="Statistics"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/charts"
          title="Charts"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/mixed"
          title="Mixed"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/tables"
          title="Tables"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/crafted/widgets/feeds"
          title="Feeds"
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}
      {/* ----------Apps--------- */}
      {/* <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Apps
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub to="/apps/chat" title="Chat" icon="message-text-2">
        <AsideMenuItem
          to="/apps/chat/private-chat"
          title="Private Chat"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/chat/group-chat"
          title="Group Chart"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/apps/chat/drawer-chat"
          title="Drawer Chart"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to="/apps/user-management/users"
        icon="shield-tick"
        title="User management"
      /> */}
      {/* <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
      <div className="menu-item">
        <a
          target="_blank"
          className="menu-link"
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + "/docs/changelog"}
        >
          <span className="menu-icon">
            <KTIcon iconName="document" className="fs-2" />
          </span>
          <span className="menu-title">
            Changelog {process.env.REACT_APP_VERSION}
          </span>
        </a>
      </div> */}
    </>
  );
}

import React, { FC, useState, useEffect } from "react";
import { KTIcon } from "../../../helpers";
import axios from "axios";
import { AxiosRequestHeaders } from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authHeader from "../../../../app/auth-header";
import { useZoom } from "../../layout/zoom/ZoomContext";
interface ContactFormData {}
interface IntervalType {
  interval_name: string;
  interval_type: string;
}

const AddFeed: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const { zoomLevel } = useZoom();
  const [intervalTypes, setIntervalTypes] = useState<IntervalType[]>([]);
  const [newKeywordPrompt, setNewKeywordPrompt] = useState(false);

  const fetchIntervalTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_interval_types`, {
        headers: authHeader() as AxiosRequestHeaders,
      })
      .then((response) => {
        if (response.data && response.data.result) {
          setIntervalTypes(response.data.result);
        } else {
          console.error("Invalid response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching interval types:", error);
      });
  };
  useEffect(() => {
    fetchIntervalTypes();
  }, []);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const url = formData.get("url");
    const feed_keyword = formData.get("feed_keyword"); // Get the keyword value
    const feed_title = formData.get("feed_title");
    const interval_type = formData.get("interval_type");

    const feed_black_list_keyword = formData.get("feed_black_list_keyword");
    const source_type = formData.get("platform") || ""; // Declare source_type here

    // if (!source_type || source_type.toString().trim() === "") {
    //   setValidationError("Please select a platform.");

    //   setTimeout(() => {
    //     setValidationError("");
    //   }, 5000);
    //   return;
    // }
    if (!feed_title || feed_title.toString().trim() === "") {
      setValidationError("Please enter a title.");

      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }

    // if (!url || url.toString().trim() === "") {
    //   setValidationError("Please enter a URL.");
    //   setTimeout(() => {
    //     setValidationError("");
    //   }, 5000);
    //   return;
    // }

    // const urlRegex = /^[^.]{3,}\..+$/;
    // if (!urlRegex.test(url.toString())) {
    //   setValidationError("Please enter a proper URL with at least 3 characters before '.' and at least one character after '.'");
    //   setTimeout(() => {
    //     setValidationError("");
    //   }, 5000);
    //   return;
    // }

    if (!feed_keyword || feed_keyword.toString().trim() === "") {
      setValidationError("Please enter a Keyword.");

      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }
    // if (!interval_type || interval_type.toString().trim() === "") {
    //   setValidationError("Please select Interval type.");

    //   setTimeout(() => {
    //     setValidationError("");
    //   }, 5000);
    //   return;
    // }
    if (interval_type && feed_keyword && feed_title) {
      // const source_type = formData.get("platform") || "";

      try {
        setIsLoading(true);
        // console.log("Keyword:", feed_keyword);
        // console.log("Title:", feed_title);
        console.log("source_type:", source_type);

        const data = {
          // url: url.toString(),
          feed_keyword: feed_keyword.toString(),
          feed_black_list_keyword: feed_black_list_keyword?.toString(),
          feed_title: feed_title.toString(),
          // source_type: source_type.toString(),
          interval_type: interval_type.toString(),
        };
        // console.log("Feed created:", data);

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/policy/createfeed_v3`,
          data,
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );
        console.log("Feed created:", response.data);

        setIsLoading(false);
        // alert(
        //   "Feed Created Successfully. Data is being fetched for this Feed. Please check your Dashboard after few minutes."
        // );
        if (response.data.status === 201) {
          console.log("Feed created:", response.data);
          toast.success(response.data.message);
          setTimeout(() => {}, 5000);
          window.location.reload();
        } else if (response.data.status === 204) {
          toast.error(response.data.message);
          setTimeout(() => {}, 5000);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
          setTimeout(() => {}, 5000);
        } else if (response.data.status === 409) {
          toast.error(response.data.message);
          setTimeout(() => {}, 5000);

          // window.location.reload();
        } else if (response.data.status === 204) {
          if (
            response.data.message === "Please Create Bundle First To Add Feed."
          ) {
            toast.error(response.data.message);
            setTimeout(() => {}, 5000);
          } else {
            setValidationError("");

            toast.error(response.data.message);
          }
        }

        setTimeout(() => {}, 2000);
      } catch (error) {
        console.error("Error creating feed:", error);
        setIsLoading(false);
      }
    } else {
      // alert("Selected source not found.");
      toast.error("Please Fill the manadatory Fields");
      setTimeout(() => {}, 2000);

      // console.log("URL is empty!");
    }
  };

  return (
    <div
      style={{ transform: `scale(${zoomLevel})` }}
      className="modal fade"
      id="kt_modal_add_feed"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Add Feed</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                {validationError && (
                  <div className="alert alert-danger" role="alert">
                    {validationError}
                  </div>
                )}
                <div className="mb-3">
                  <div>
                    <label
                      htmlFor="feed_title"
                      className="required form-label fs-6 fw-bolder text-dark"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      id="feed_title"
                      name="feed_title"
                      className="form-control bg-transparent"
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="feed_keyword"
                    className="required form-label fs-6 fw-bolder text-dark"
                  >
                    Whitelisted Keyword
                  </label>
                  <input
                    type="text"
                    id="feed_keyword"
                    name="feed_keyword"
                    className="form-control bg-transparent"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="feed_black_list_keyword"
                    className=" form-label fs-6 fw-bolder text-dark"
                  >
                    Blacklisted Keyword
                  </label>
                  <input
                    type="text"
                    id="feed_black_list_keyword"
                    name="feed_black_list_keyword"
                    className="form-control bg-transparent"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="interval_type"
                    className=" form-label fs-6 fw-bolder text-dark"
                  >
                    Interval Type
                  </label>
                  <select
                    id="interval_type"
                    name="interval_type"
                    className="form-select bg-transparent"
                  >
                    <option value="">Select Interval Type</option>
                    {intervalTypes.map((type) => (
                      <option
                        key={type.interval_name}
                        value={type.interval_type}
                      >
                        {type.interval_name}
                      </option>
                    ))}
                  </select>
                  {/* <label htmlFor="interval_type" className="form-label">
                  Interval Type
                </label> */}
                  {/* <select
                  className="form-select"
                  id="interval_type"
                  name="interval_type"
                  value={formData.interval_type}
                  onChange={handleInputChange}
                  
                > */}
                </div>
              </div>
              <div className="text-center mt-13">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary me-3"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding...Please Wait" : "Add Feed"}
                </button>
              </div>
            </form>
            {/* <form onSubmit={handleSubmit}>
            {validationError && (
                <div className="alert alert-danger" role="alert">
                  {validationError}
                </div>
              )}
              <div className="d-flex flex-row justify-content-center">
                <div className="align-items-center">
                  <select
                    className="required form-select form-select-sm form-select-solid w-150px w-xxl-125px"
                    data-control="select2"
                    data-placeholder="Latest"
                    data-hide-search="true"
                    name="platform"
                    
                  >
                    <option value="">Select Platform</option>
                    <option value="News">News</option>
                    <option value="Social">Social</option>
                  </select>
                </div>
              </div>
              <div className="d-flex gap-2 mt-5 justify-content-center flex-sm-row flex-column">
                <div>
                  <label
                    htmlFor="url"
                    className="required form-label fs-6 fw-bolder text-dark"
                  >
                    URL
                  </label>
                  <input
                    type="text"
                    id="url"
                    name="url"
                    className="form-control bg-transparent"
                  />
                </div>
                <div>
                  <label
                    htmlFor="feed_title"
                    className="required form-label fs-6 fw-bolder text-dark"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="feed_title"
                    name="feed_title"
                    className="form-control bg-transparent"
                  />
                </div>
              </div>
              <div className="d-flex gap-2 mt-5 justify-content-center flex-sm-row flex-column">
                <div>
                  <label
                    htmlFor="feed_keyword"
                    className="required form-label fs-6 fw-bolder text-dark"
                  >
                    Whitelisted Keyword
                  </label>
                  <input
                    type="text"
                    id="feed_keyword"
                    name="feed_keyword"
                    className="form-control bg-transparent"
                  />
                </div>
                <div>
                  <label
                    htmlFor="feed_black_list_keyword"
                    className=" form-label fs-6 fw-bolder text-dark"
                  >
                    Blacklisted Keyword
                  </label>
                  <input
                    type="text"
                    id="feed_black_list_keyword"
                    name="feed_black_list_keyword"
                    className="form-control bg-transparent"
                  />
                </div>
              </div>
              <div className="text-center mt-13">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary me-3"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding...Please Wait" : "Add Feed"}
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddFeed };

// import React, { useEffect, useState } from "react";
// import { Card, Row, Col, Table } from "react-bootstrap";
// import axios from "axios";
// import { FaCheck } from "react-icons/fa";
// import { Link } from "react-router-dom";

// import authHeader from "../../auth-header";
// import { CircularProgressbar } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import { useNavigate } from "react-router-dom";
// import premium from "./pricing/business.webp"
// import free from "./pricing/free.webp"
// import pro from "./pricing/pro.webp"
// import basic from "./pricing/basic.webp"
// function Usage() {
//   //  Api call

//   // For Getting Data\
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [usageData, setUsageData] = useState([]);
//   useEffect(() => {});
//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_API_URL}/policy/get_user_credits`, {
//         headers: authHeader(),
//         "Access-Control-Allow-Origin": "*",
//       })

//       .then((res) => {
//         console.log("usage", res);
//         setUsageData(res.data.result);
//         setLoading(false);
//       })
//       .catch((error) => {
//         if (error.response) {
//         }
//       });
//   }, []);
//   const formattedExpiryDate = new Date(
//     usageData?.expiry_date
//   ).toLocaleDateString();
//   let subscriptionImage;
//   let subscriptionTitle;

//   switch (usageData.plan    ) {
//     case "free":
//       subscriptionImage = free;
//       subscriptionTitle = "Free";
//       break;
//     case "basic":
//       subscriptionImage = basic;
//       subscriptionTitle = "Basic";
//       break;
//     case "pro":
//       subscriptionImage = pro;
//       subscriptionTitle = "Pro";
//       break;
//     case "premium":
//       subscriptionImage = premium;
//       subscriptionTitle = "Premium";
//       break;
//     default:
//       subscriptionImage = null;
//       subscriptionTitle = "Unknown Plan";
//   }
//   return (
//     <>
//       <Card className="py-4 px-2">
//         <div className="justify-content-center align-item-center">
//           {/* <div className="flex p-1 justify-start items-center  mb-4">
//             <h2 className=" text-center">Subscription Plan Details and Usage</h2>
//           </div> */}

//           <div >
//             <div className="text-center mt-5">
//               <h1>{usageData.plan_type} Subscription Details</h1>
//             </div>

//             <Row className="d-flex justify-content-center align-item-center mb-5 mt-5">

//                 <div
//                   className=" pricing-table  "
//                   style={{ backgroundColor: "#6610f2" ,width:"50%" }}
//                 >
//                   <div className="pricing-header " style={{ color: "white" }}>
//                   {subscriptionImage && (
//               <img src={subscriptionImage} alt={subscriptionTitle} style={{ maxWidth: "200px", margin: "20px auto" }} />
//             )}
//                     <h5 className="title" style={{ color: "white" }}>
//                       {usageData.plan}
//                     </h5>

//                     {/* <span className="subtitle">{usageData.description}</span> */}
//                     <div className="price-wrap">

//                       <div>
//                       <ul className="list-unstyled text-start" style={{marginTop:"-20px"}}>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Analytics addon: {usageData.analytics_addon} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Cost per search: {usageData.cost_per_search} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Country Selection: {usageData.country_selection} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Curated ai news: {usageData.curated_ai_news} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Hide sponsored ads: {usageData.hide_sponsored_ads} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Language selection: {usageData.language_selection} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Manual bundle update: {usageData.manual_bundle_update} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Public newsfeed dashboard: {usageData.public_newsfeed_dashboard} </li>
//                           <li className="d-flex gap-3 mb-3"><FaCheck /> Share via link: {usageData.share_via_link} </li>
//                           <li className="d-flex gap-3"><FaCheck /> Timeframe selection: {usageData.timeframe_selection} </li>

//                         </ul>
//                         {usageData?.expired ? (
//                           <>
//                             <h3 className="text-white">
//                               You'r Subscription plan is expired on{" "}
//                               {formattedExpiryDate}
//                             </h3>
//                           </>
//                         ) : (
//                           <>
//                             <h4 className="text-white">
//                               You'r Plan will expire on {formattedExpiryDate}
//                             </h4>
//                           </>
//                         )}
//                       </div>
//                     </div>
//                     <div>
//                       <Link to="/pricing" className="link-primary">
//                         <button
//                           className="pricing-btn axil-btn"
//                           // onClick={() => handleSubmit(data.id, data.title)}
//                         >
//                           Upgrade
//                         </button>
//                       </Link>
//                     </div>
//                   </div>
//                   <div className="pricing-body">

//                   </div>
//                 </div>

//             </Row>
//             <div >
//               <Table striped bordered hover>
//                 <thead>
//                   <tr className="text-center">
//                     <th>Usage</th>
//                     <th>Remaining</th>
//                     <th>Given</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>
//                       Feed result limit per feed used :{" "}
//                       {usageData.feed_result_limit_per_feed_used}
//                     </td>
//                     <td>
//                       Filter by blacklisted_keywords remaining :{" "}
//                       {usageData.filter_by_blacklisted_keywords_remaining}
//                     </td>
//                     <td>
//                       Filter by blacklisted_keywords given :{" "}
//                       {usageData.filter_by_blacklisted_keywords_given}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       Filter by blacklisted_keywords used :{" "}
//                       {usageData.filter_by_blacklisted_keywords_used}
//                     </td>
//                     <td>
//                       Filter by blacklisted keywords remaining :{" "}
//                       {usageData.filter_by_blacklisted_keywords_remaining}
//                     </td>
//                     <td>
//                       Filter by blacklisted keywords given :{" "}
//                       {usageData.filter_by_blacklisted_keywords_given}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       Filter by whitelisted keywords used :{" "}
//                       {usageData.filter_by_whitelisted_keywords_used}
//                     </td>
//                     <td>
//                       Filter by blacklisted keywords remaining :{" "}
//                       {usageData.filter_by_whitelisted_keywords_remaining}
//                     </td>
//                     <td>
//                       Filter by whitelisted keywords given :{" "}
//                       {usageData.filter_by_whitelisted_keywords_given}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       Number of bundles used :{" "}
//                       {usageData.number_of_bundles_used}
//                     </td>
//                     <td>
//                       Number of bundles remaining :{" "}
//                       {usageData.number_of_bundles_remaining}
//                     </td>
//                     <td>
//                       Number of bundles given :{" "}
//                       {usageData.number_of_bundles_given}
//                     </td>
//                   </tr>

//                   {/* <tr>
//                     <td>
//                       number_of_feeds_given : {usageData.umber_of_feeds_given}
//                     </td>
//                     <td>
//                       number_of_feeds_remaining :{" "}
//                       {usageData.number_of_feeds_remaining}
//                     </td>
//                     <td>
//                       number_of_feeds_given : {usageData.number_of_feeds_given}
//                     </td>
//                   </tr> */}

//                   <tr>
//                     <td>
//                       Number of feeds used : {usageData.number_of_feeds_used}
//                     </td>
//                     <td>
//                       Number of feeds remaining :{" "}
//                       {usageData.number_of_feeds_remaining}
//                     </td>
//                     <td>
//                       Number of feeds given : {usageData.number_of_feeds_given}
//                     </td>
//                   </tr>

//                   <tr>
//                     <td>
//                       number_of_sources_per_bundle_given :{" "}
//                       {usageData.number_of_sources_per_bundle_given}
//                     </td>
//                     <td>
//                       number_of_feeds_remaining :{" "}
//                       {usageData.number_of_sources_per_bundle_remaining}
//                     </td>
//                     <td>
//                       number_of_feeds_given :{" "}
//                       {usageData.number_of_sources_per_bundle_used}
//                     </td>
//                   </tr>

//                   <tr>
//                   <td>
//                       Number of user generated feeds used :{" "}
//                       {usageData.number_of_user_generated_feeds_used}
//                     </td>
//                     <td>
//                       Number of user generated feeds remaining :{" "}
//                       {usageData.number_of_user_generated_feeds_remaining}
//                     </td>

//                     <td>
//                       Number of user generated feeds given :{" "}
//                       {usageData.number_of_user_generated_feeds_given}
//                     </td>
//                   </tr>

//                   <tr>
//                   <td>
//                       Total searches per month used :{" "}
//                       {usageData.total_searches_per_month_used}
//                     </td>
//                     <td>
//                       Total searches per month remaining :{" "}
//                       {usageData.total_searches_per_month_remaining}
//                     </td>

//                     <td>
//                       Total searches per month given :{" "}
//                       {usageData.total_searches_per_month_given}
//                     </td>
//                   </tr>
//                   {/* Add more rows based on your data */}
//                 </tbody>
//               </Table>
//             </div>
//           </div>

//           <div />
//         </div>
//       </Card>
//     </>
//   );
// }

// export default Usage;
import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import premium from "./pricing/business.webp";
import free from "./free.webp";
import pro from "./pricing/pro.webp";
import basic from "./pricing/basic.webp";
import authHeader from "../../auth-header";

function Usage() {
  const [loading, setLoading] = useState(true);
  const [usageData, setUsageData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_user_credits`, {
        headers: authHeader(),
        "Access-Control-Allow-Origin": "*",
      })
      .then((res) => {
        if (res.data.status === 204) {
          setErrorMessage(
            "Please buy any subscription to view subscription details."
          );
        } else {
          setUsageData(res.data.result || {});
        }
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
        }
      });
  }, []);

  if (errorMessage) {
    return (
      <div className="text-center " style={{ marginTop: "200px" }}>
        <h2>{errorMessage}</h2>
        <Link to="/pricing" className="link-primary">
          <button className="pricing-btn axil-btn">Upgrade</button>
        </Link>
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const formattedExpiryDate = usageData.expiry_date
    ? new Date(usageData.expiry_date).toLocaleDateString()
    : null;
  const subscriptionImage = getSubscriptionImage(usageData.plan);
  const subscriptionTitle = getSubscriptionTitle(usageData.plan);

  function getSubscriptionImage(plan) {
    switch (plan) {
      case "free":
        return free;
      case "basic":
        return basic;
      case "pro":
        return pro;
      case "premium":
        return premium;
      default:
        return null;
    }
  }

  function getSubscriptionTitle(plan) {
    switch (plan) {
      case "free":
        return "Free";
      case "basic":
        return "Basic";
      case "pro":
        return "Pro";
      case "premium":
        return "Premium";
      default:
        return "Unknown Plan";
    }
  }
  return (
    <Card className="py-4 px-2">
      <div className="justify-content-center align-item-center">
        <div className="text-center mt-5">
          <h1>{usageData.plan_type} Subscription Details</h1>
        </div>

        <Row className="d-flex justify-content-center align-item-center mb-5 mt-5">
          <div
            className="pricing-table"
            style={{ backgroundColor: "#6610f2", width: "50%", height: "50%" }}
          >
            <div className="pricing-header" style={{ color: "white" }}>
              {subscriptionImage && (
                <img
                  src={subscriptionImage}
                  alt={subscriptionTitle}
                  style={{ maxWidth: "200px", margin: "20px auto" }}
                />
              )}
              <h5 className="title" style={{ color: "white" }}>
                {usageData.plan}
              </h5>
              <div className="mt-5">
                {usageData?.expired ? (
                  <>
                    <h3 className="text-white fs-3">
                      Your Subscription plan is expired on {formattedExpiryDate}
                    </h3>
                  </>
                ) : (
                  <>
                    <h4 className="text-white fs-3">
                      Your Plan will expire on {formattedExpiryDate}
                    </h4>
                  </>
                )}
              </div>

              <div>
                <ul
                  className="list-unstyled text-start"
                  style={{ marginTop: "30px" }}
                >
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Analytics addon: {usageData.analytics_addon}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Cost per search: {usageData.cost_per_search}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Country Selection: {usageData.country_selection}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Curated ai news: {usageData.curated_ai_news}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Hide sponsored ads:{" "}
                    {usageData.hide_sponsored_ads}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Language selection:{" "}
                    {usageData.language_selection}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Manual bundle update:{" "}
                    {usageData.manual_bundle_update}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3  fs-4">
                    <FaCheck /> Public newsfeed dashboard:{" "}
                    {usageData.public_newsfeed_dashboard}{" "}
                  </li>
                  <li className="d-flex gap-5 mb-3 fs-4">
                    <FaCheck /> Share via link: {usageData.share_via_link}{" "}
                  </li>
                  <li className="d-flex gap-5 fs-4">
                    <FaCheck /> Timeframe selection:{" "}
                    {usageData.timeframe_selection}{" "}
                  </li>
                </ul>
              </div>

              <div>
                <Link to="/pricing" className="link-primary">
                  <button className="pricing-btn axil-btn">Upgrade</button>
                </Link>
              </div>
            </div>
            <div className="pricing-body">
              {/* Additional content if needed */}
            </div>
          </div>
        </Row>
        <div>
          <Table striped bordered hover>
            <thead>
              <tr className="text-center">
                <th>Usage</th>
                <th>Remaining</th>
                <th>Given</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* used */}

                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Feed result limit per feed used :{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.feed_result_limit_per_feed_used}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                {/* used */}
                {/* remaining */}
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by blacklisted_keywords remaining :{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_blacklisted_keywords_remaining}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                {/* remaining */}
                {/* given */}
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by blacklisted_keywords given :{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_blacklisted_keywords_given}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>

                {/* given */}
              </tr>

              <tr>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by blacklisted_keywords used{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_blacklisted_keywords_used}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by blacklisted_keywords remaining{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {/* {usageData.filter_by_blacklisted_keywords_used} */}
                          {usageData.filter_by_blacklisted_keywords_remaining}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by blacklisted keywords given{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_blacklisted_keywords_given}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by whitelisted keywords used{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_whitelisted_keywords_used}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by blacklisted keywords remaining{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_whitelisted_keywords_remaining}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Filter by whitelisted keywords given{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.filter_by_whitelisted_keywords_given}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              <tr>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of bundles used{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_bundles_used}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>{" "}
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of bundles remaining{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_bundles_remaining}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of bundles given{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_bundles_given}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
              {/* <tr> */}
              {/* <td scope="col">
                  <Table striped bordered hover >
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        number_of_feeds_given                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        {usageData.umber_of_feeds_given}                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover >
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        number_of_feeds_remaining                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        {usageData.number_of_feeds_remaining}                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td> */}
              {/* <td scope="col">
                  <Table striped bordered hover >
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        number_of_feeds_given{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        {usageData.number_of_feeds_given}                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td> */}

              {/* </tr>  */}

              <tr>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of feeds used
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_feeds_used}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of feeds remaining{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_feeds_remaining}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of feeds given
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_feeds_given}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>

              {/* <tr>
                <td scope="col">
                  <Table striped bordered hover >
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        number_of_sources_per_bundle_used             
                                       </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        {usageData.number_of_sources_per_bundle_used}
            </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover >
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        Number of feeds remaining :                     </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        {usageData.number_of_feeds_remaining}                     </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover >
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        Number of feeds given : 
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px" ,height:"80px"}}>
                        {usageData.number_of_feeds_given}                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
             
                
                 </tr> */}

              <tr>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of user generated feeds used
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_user_generated_feeds_used}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of user generated feeds remaining{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_user_generated_feeds_remaining}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Number of user generated feeds given
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.number_of_user_generated_feeds_given}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>

              <tr>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Total searches per month used
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.total_searches_per_month_used}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Total searches per month remaining{" "}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.total_searches_per_month_remaining}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
                <td scope="col">
                  <Table striped bordered hover>
                    <tbody className="d-flex mt-3">
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          Total searches per month given
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "200px", height: "80px" }}>
                          {usageData.total_searches_per_month_given}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
}

export default Usage;

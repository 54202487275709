import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import FeedResultWidget from "./../../../_metronic/partials/widgets/tables/FeedResultWidget";
import { useZoom } from "../../../_metronic/partials/layout/zoom/ZoomContext";
import {
  ListsWidget1,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  StatisticsWidget5,
  TablesWidget10,
  TablesWidget5,
} from "../../../_metronic/partials/widgets";
import Billing from "../billing/Billing";
import { useNavigate } from "react-router-dom";
import PopularTopicsWidget from "../../../_metronic/partials/widgets/lists/Populartopicswidget";

const DashboardPage = () => {

  const { zoomLevel } = useZoom();

  return (

  <>
    <div  style={{ transform: `scale(${zoomLevel})` }} className="row row-flex">
      {/* begin::Col */}
      <div className="col-xl-3 p-3">
        <ListsWidget1 className="card-xl-stretch mb-xl-8 mb-5" />
        <ListsWidget5 className="card-xl-stretch" />
      </div>
      {/* end::Col */}
      <div className="col-xl-6 p-3">
        <ListsWidget4 className="card-xl-stretch" />
      </div>
      {/* begin::Col */}
      <div className="col-xl-3 ">
        {/* <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' /> */}
        <FeedResultWidget className="card-xl-stretch  mb-xl-8" />
      </div>
      <ListsWidget3 className="card-xl-stretch" />
    </div>
  
  </>
);
  }
const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };

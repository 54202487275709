import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useAuth } from "../core/Auth";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import AppleLogin from "react-apple-login";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KTIcon } from "../../../../_metronic/helpers";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";

interface FormData {
  email: string;
  password: string;
}
interface GoogleOAuthResponse {
  tokenId: string;
  // Add other properties as needed
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  interface GoogleOAuthResponse {
    tokenId: string;
    credential: string;
  }

  const handleGoogleLoginSuccess = async (response: GoogleOAuthResponse) => {
    try {
      const credential = response?.credential;

      if (credential) {
        const token = credential;

        // console.log('Access Token:', token);

        // localStorage.setItem('auth_token', token);

        const apiResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/user_portal/api/google_login`,
          {
            auth_token: token,
          }
        );
        // console.log(apiResponse.data);

        if (apiResponse.data.status === 200) {
          // console.log(apiResponse.data.result)
          // console.log('User Info:', apiResponse.data.result);

          const token = apiResponse.data.result.token.access;
          localStorage.setItem("token", token);
          localStorage.setItem(
            "get_pocket_token",
            apiResponse.data.result.get_pocket_token
          );
          localStorage.setItem("provider", apiResponse.data.result.provider);

          toast.success("Login Successful: " + apiResponse.data.message);
          navigate("/user/dashboard");
        } else {
          // console.log('Login with Google failed on the server side');
          toast.error("Login Failed: " + apiResponse.data.message);
        }
      } else {
        // console.log('Token not found in the response');
        toast.error("Token not found in the response");
      }
    } catch (error) {
      console.error("Error handling Google login:", error);
      toast.error("An error occurred");
    }
  };

  const handleGoogleLoginFailure = (error: any) => {
    // console.log('Login with Google failed');
    // console.error('Google Login Error:', error);
  };

  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.email || formData.email.trim() === "") {
      //  console.log("email validation error");
      // setValidationError("Please enter Email.");
      // setTimeout(() => {
      //   setValidationError("");
      // }, 5000);
      toast.error("Please enter Email.", {
        autoClose: 5000,
      });

      return;
    }
    if (!formData.password || formData.password.trim() === "") {
      console.log("password validation error");

      toast.error("Please enter password", {
        autoClose: 5000,
      });
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_portal/api/login_user`,
        {
          email: formData.email,
          password: formData.password,
        }
      );
      // console.log("Login",response.data);
      if (response.data.status === 200) {
        const token = JSON.stringify(response.data.result);
        // localStorage.setItem('token', token);
        localStorage.setItem("email", response.data.result.email);
        // const loginTimestamp = new Date().toISOString();
        // localStorage.setItem("loginTimestamp", loginTimestamp);
        // console.log(response, 'Login Successfully');
        localStorage.setItem('token', token);
        localStorage.setItem('email', response.data.result.email);

        console.log(response, 'Login Successfully');
        toast.success(response.data.message);
        navigate("/auth/mfa-code");
      } else if (response.data.status === 400) {
        toast.error(response.data.message);
      } else {
        // toast.error(response.data.message);
        console.error("Login Failed: Invalid User");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };
  return (
    <div className="container1">
      <form
        className="form w-100"
        onSubmit={handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Sign In</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            Your Social Campaigns
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 ">
            <GoogleOAuthProvider clientId="418076685952-brome20fnhgsdjl6ddqvee8ne63ibdn5.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={(credentialResponse) =>
                  handleGoogleLoginSuccess(
                    credentialResponse as GoogleOAuthResponse
                  )
                }
                onError={() => {
                  // console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
            {/* <GoogleLogin
              clientId="65747309490-8bgpp11s75mmd2l9b0q4s4ruq11ve83h.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={handleGoogleLoginSuccess}
              onFailure={handleGoogleLoginFailure}
              cookiePolicy={'single_host_origin'}
              className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
            >
              Sign in with Google
            </GoogleLogin> */}
          </div>
          {/* <div className="col-md-6">
            <AppleLogin
              clientId="YOUR_APPLE_CLIENT_ID"
              redirectURI="YOUR_REDIRECT_URI"
              render={({ onClick }: { onClick: () => void }) => (
                <div className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/svg/brand-logos/apple-black.svg")}
                    className="theme-light-show h-15px me-3"
                  />
                  <img
                    alt="Logo"
                    src={toAbsoluteUrl("/media/svg/brand-logos/apple-black-dark.svg")}
                  />
                  <span onClick={onClick}>Sign in with Apple</span>
                </div>
              )}
            />
          </div> */}
        </div>

        <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Or with email
          </span>
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Email
          </label>
          <input
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            type="email"
            autoComplete="off"
            className="form-control bg-transparent"
          />
        </div>

        <div className="fv-row mb-3">
          <label className="form-label fw-bolder text-dark fs-6 mb-0">
            Password
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              className="form-control bg-transparent"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            <button
              type="button"
              className="d-flex justify-content-center align-items-center btn btn-primary btn-outline-secondary"
              onClick={togglePasswordVisibility}
              style={{ width: "25px", height: "44px" }}
            >
              <KTIcon iconName={showPassword ? "eye-slash" : "eye"} />
            </button>
          </div>
        </div>

        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <div />
          <Link to="/auth/forgot-password" className="link-primary">
            Forgot Password ?
          </Link>
        </div>

        <div className="d-grid mb-10">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-primary"
          >
            {!loading && <span className="indicator-label">Continue</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          Not a Member yet?{" "}
          <Link to="/auth/registration" className="link-primary">
            Sign up
          </Link>
        </div>
      </form>
    </div>
  );
}

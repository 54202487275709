import React, { useState, useEffect } from "react";
import axios from "axios";
import { Pagination } from "react-bootstrap";
import { useParams } from "react-router-dom";
import authHeader from "../../../../../../auth-header";
import { Carousel } from "react-bootstrap";


function BundleArticle() {
  const [articleData, setArticleData] = useState([]);
  const [articleNotFound, setArticleNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [backendMessage, setBackendMessage] = useState("");
  const [pagination, setPagination] = useState({
    activePage: 1,
    count: 0,
    next: null,
    previous: null,
    pageSize: 5,
  });
  const { bundleId } = useParams();

  const [view, setView] = useState("1");
  const [articlesPerPage, setArticlesPerPage] = useState("10");
  const [selectedViewCount, setSelectedViewCount] = useState("10");
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState(/* Provide a valid initial state or remove */ {});
  const [viewCountModified, setViewCountModified] = useState(/* Provide a valid initial state or remove */);

  const fetchArticleData = async (pageNumber) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/policy/bundle_navigate_to_articles_v1/${bundleId}`;
      const response = await axios.get(apiUrl, {
        headers: authHeader(),
        params: {
          page: pageNumber,
          articles_per_page: articlesPerPage,
        },
      });
  
      // console.log("API Response:", response.data.results);
      // console.log("Response:", response);
  
      if (response.data.results && response.data.results.status === 200) {
        const articles = response.data.results.result || [];
        // console.log("Fetched Articles:", articles);
        setArticleData(articles);
        setPagination({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          activePage: pageNumber,
          pageSize: 5,
        });
        setArticleNotFound(false);
      } else if (response.data && response.data.status === 204) {
        setArticleData([]);
        setPagination({
          count: 0,
          next: null,
          previous: null,
          activePage: pageNumber,
          pageSize: 5,
        });
        setArticleNotFound(true);
        setBackendMessage(response.data.message);
      } else {
        // Handle other cases or log an error
        console.error("Invalid response structure:", response.data);
      }
    } catch (error) {
      console.error("Error fetching article data:", error);
  
      if (
        error.response &&
        error.response.data &&
        error.response.data.result === "Invalid page."
      ) {
        console.error("Invalid page error:", error.response.data);
      } else {
        // Handle other errors
      }
    } finally {
      setLoading(false);
    }
  };
  

  const handlePageChange = (pageNumber) => {
    // console.log("Page Change - New Page:", pageNumber);
    fetchArticleData(pageNumber);
  };

  useEffect(() => {
    fetchArticleData(1);
  }, [bundleId]);

  const handleArticlesPerPageChange = (
    e
  ) => {
    const selectedArticlesPerPage = e.target.value;
    setArticlesPerPage(selectedArticlesPerPage);
    setIsLoading(true);
  
    if (state.activePage !== 1) {
      // If the user has selected a page, apply the view count change immediately
      handlePageChange(1, parseInt(selectedArticlesPerPage, 10));
      setViewCountModified(false);
    } else {
      setViewCountModified(true);
    }
  };

  const renderPagination = () => {
    if (pagination.count <= 1) {
      return null;
    }
  
    const totalPages = Math.ceil(pagination.count / parseInt(articlesPerPage, 10));
    const pageNumbers = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    );
  
    return (
      <Pagination className="custom-pagination" size="lg">
        <Pagination.Prev
          onClick={() =>
            pagination.previous &&
            handlePageChange(pagination.activePage - 1)
          }
          disabled={!pagination.previous}
        />
        {pageNumbers.map((pageNumber) => {
          const isActive = pageNumber === pagination.activePage;
          const isInRange =
            pageNumber <= totalPages &&
            pageNumber >= pagination.activePage - 2 &&
            pageNumber <= pagination.activePage + 2;
  
          return (
            isInRange && (
              <Pagination.Item
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                active={isActive}
                className={`custom-page-item ${isActive ? "active" : ""}`}
              >
                {pageNumber}
              </Pagination.Item>
            )
          );
        })}
        <Pagination.Next
          onClick={() =>
            pagination.next && handlePageChange(pagination.activePage + 1)
          }
          disabled={!pagination.next}
        />
      </Pagination>
    );
  };
  
  

  return (
    <div>
      <div className="d-flex justify-content-between mt-5">
        <div className="d-flex gap-4 ">
          <div className="align-items-center">
            <span className="fw-bolder text-gray-700">Select View:</span>
            <select
              className="form-select form-select-sm form-select-solid w-100px w-xxl-125px mb-15"
              data-control="select2"
              data-placeholder="Latest"
              data-hide-search="true"
              value={view}
              onChange={(e) => setView(e.target.value)}
            >
              <option value="1">List</option>
              <option value="2">Grid</option>
              <option value="3">Magazine</option>
              <option value="4">Card</option>
              <option value="5">Carousel</option>
            </select>
          </div>
          <div>
            <span className="fw-bolder text-gray-700">View Count</span>
            <select
              className="form-select form-select-sm form-select-solid w-125px w-xxl-125px mb-15"
              data-control="select2"
              data-placeholder="10"
              data-hide-search="true"
              value={selectedViewCount}
              onChange={(e) => {
                setSelectedViewCount(e.target.value);
                handleArticlesPerPageChange(e);
              }}
              onClick={() =>
                handlePageChange(1, parseInt(selectedViewCount, 10))
              }
            >
              <option value="10">10 articles</option>
              <option value="25">25 articles</option>
              <option value="50">50 articles</option>
              <option value="100">100 articles</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <h1 className="card-title pt-5">
          <strong className="text-dark">Bundle for Articles</strong>
        </h1>

        <div className="row g-5 g-xl-12 mt-8">
          {loading ? (
            <p>Loading...</p>
          ) : articleNotFound ? (
            <p>{backendMessage}</p>
          ) : (
            <>
              {/* view1 */}
              {view === "1" && (
                <>
                  {articleData.map((article) => (
                    <div key={article.id}>
                      <div className="col-xl-12">
                        <div
                          className=" card mb-3"
                          style={{ maxWidth: "1000px" }}
                        >
                          <div className="row no-gutters">
                            <div className="col-md-7">
                              <div className="card-body">
                                <a
                                  className="card-text  fw-bold mt-5"
                                  href={article.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {article.title}
                                </a>
                                <h5 className="card-title mt-5">
                                  Author : {article.author_name}
                                </h5>
                                <div className="d-flex mb-3">
                                  <p className="card-text">
                                    <small>
                                      {article.article_language &&
                                        article.article_language
                                          .charAt(0)
                                          .toUpperCase() +
                                          article.article_language.slice(1)}
                                    </small>
                                  </p>
                                  <p
                                    className="card-text"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <small>
                                      {article.article_country &&
                                        article.article_country
                                          .charAt(0)
                                          .toUpperCase() +
                                          article.article_country.slice(1)}
                                    </small>
                                  </p>
                                  <p
                                    className="card-text"
                                    style={{ paddingLeft: "10px" }}
                                  >
                                    <small>Rank : {article.rank}</small>
                                  </p>
                                </div>
                                <h5 className="fw-bold">
                                  Bundle Name : {article.bundle__bundle_title}
                                </h5>
                                <p className="card-text">
                                  <small>
                                    Date Published : {article.posted_on}
                                  </small>
                                </p>
                                <p className="card-text">
                                  <small className="text-muted">
                                    Last updated on {article.posted_on}
                                  </small>
                                </p>
                                <div className="d-flex align-items-center mb-5">
                                  {article.sentiment ? (
                                    article.sentiment === "positive" ? (
                                      <a
                                        className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                                        style={{
                                          backgroundColor: "green",
                                          cursor: "default",
                                        }}
                                      >
                                        {article.sentiment}
                                      </a>
                                    ) : article.sentiment === "negative" ? (
                                      <a
                                        className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                                        style={{ cursor: "default" }}
                                      >
                                        {article.sentiment}
                                      </a>
                                    ) : article.sentiment === "neutral" ? (
                                      <a
                                        className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                                        style={{ cursor: "default" }}
                                      >
                                        {article.sentiment}
                                      </a>
                                    ) : null
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <img
                                src={article.image}
                                className="card-img h-75 w-100"
                                alt="..."
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
{/* view2 */}
{view === "2" && 
  <div className="row">
    {articleData &&
      articleData.slice(0, 10).map((article, index) => (
        <div key={article.id} className="col-xl-6">
          <div className="card mb-3" style={{ maxWidth: "1000px" }}>
            <div className="row no-gutters">
              <div className="col-md-7">
                <div className="card-body">
                  {/* Add logging to check the structure of the 'article' object */}
                  {/* {console.log("Article Object:", article)} */}

                  <a
                    className="card-text  fw-bold mt-5"
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {article.title}
                  </a>

                  <h5 className="card-title mt-5">
                    Author: {article.author_name}
                  </h5>
                  <div className="d-flex mb-3">
                    <p className="card-text">
                      <small>Language: {article.article_language}</small>
                    </p>
                    <p
                      className="card-text"
                      style={{ paddingLeft: "10px" }}
                    >
                      <small>{article.article_country}</small>
                    </p>
                  </div>

                  <h5 className="fw-bold">
                    Bundle Name: {article.bundle__bundle_title}
                  </h5>
                  <p className="card-text">
                    <small>Date Published: {article.posted_on}</small>
                  </p>

                  <p className="card">
                    {/* {result.summarized_content && result.summarized_content.slice(0, 250)} */}
                  </p>

                  <p className="card-text">
                    <small className="text-muted">
                      Last updated on {article.posted_on}
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={article.image}
                  className="card-img"
                  style={{
                    width: "200px",
                    height: "100px",
                    marginTop: "150px",
                    objectFit: "cover",
                  }}
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
}
{/* view3 */}
{view === "3" && 
  <div className="row">
    {articleData &&
      articleData.slice(0, 10).map((article, index) => (
        <div className={index % 2 === 0 ? "col-lg-8" : "col-lg-4"}>
        <div className="card mb-4">
          <div className="row">
            <div className="col-lg-12 clearfix p-5">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                <h1 className="card-title ">
                       {" "}
                       <strong className="text-dark ">
                         <a
                           className="text-dark "
                           href={article.link}
                           target="_blank"
                           rel="noopener noreferrer"
                         >
                           {article.title}
                         </a>
                       </strong>
                     </h1>
                  {article.sentiment ? (
                    article.sentiment === "positive" ? (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                        style={{ backgroundColor: "green" }}
                      >
                        {article.sentiment}
                      </a>
                    ) : article.sentiment === "negative" ? (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                      >
                        {article.sentiment}
                      </a>
                    ) : article.sentiment === "neutral" ? (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                      >
                        {article.sentiment}
                      </a>
                    ) : null
                  ) : null}
                </div>
                <img src={article.image} alt="" className="w-100" />
                <h5 className="card-title mt-5">
                     Author : {article.author_name}
                   </h5>
                   <div className="d-flex mb-3">
                     <p className="card-text">
                       <small>
                         Language : {article.article_language}
                       </small>
                     </p>
                     <p
                       className="card-text"
                       style={{ paddingLeft: "10px" }}
                     >
                       <small>Country : {article.article_country}</small>
                     </p>
                   </div>
                   <h5 className="fw-bold">
                     Bundle Name : {article.bundle__bundle_title}
                   </h5>
                   <br></br>

<p className="text-dark  font-weight-bold">
{article.summarized_content &&
article.summarized_content.slice(0, 1050)}
</p>
<p className="card-text">
<small>Date Published : {article.posted_on}</small>
</p>
              </div>
            </div>
           <div className="col-lg-4"> <div
         className="card text-white mb-5"
         style={{ maxWidth: "400px", backgroundColor: "#FDFEFE" }}
       ></div>
        <div
         className="d-flex justify-content-end mb-5 "
         style={{
           position: "absolute",
           bottom: 0,
           marginLeft: "1rem",
         }}
       >
         <div className="d-flex align-items-center mb-5"></div>
       </div>
     
       </div>
          </div>
        </div>
      </div>
      ))}
  </div>
}
{/* view4 */}
{view === "4" && 
  <div className="row">
    {articleData &&
      articleData.slice(0, 10).map((article, index) => (
        <div>
        <div
          className="card mb-5 "
          style={{
            width: "70rem",
            // height: "55rem",
          }}
        >
          <div className="card-body">
            <img
              style={{
                height: "400px",
                width: "700px",
                objectFit: "cover",
                borderRadius: "20px",
              }}
              src={article.image}
              // className="card-img-top h-100"
              alt="..."
            />

            <br></br>
            {/* <div className="symbol symbol-25px me-5 mt-5 mb-2">
                <img src={data.source_logo} alt="" className="w-100" />
              </div> */}
            <div className="text-gray-400  fw-semibold mt-2">
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {article.link}
              </a>
            </div>
            <h5 className="card-title">{article.title}</h5>
            <p className="text-dark mt-2 font-weight-bold"></p>

            <p className="card-text">
              {/* {result.summarized_content && result.summarized_content.slice(0, 150)} */}
            </p>
            <div className="text-muted">
              Last Updated {article.posted_on}
            </div>
            <div className="d-flex align-items-center mb-2 mt-3">
              {/* <a
                href="#"
                className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
              >
                Negative
              </a> */}
              <div className="d-flex align-items-center mb-5">
                {/* <a
                    href={`/detailnews/${result.id}`}
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                  >
                    <KTIcon iconName="eye" className="fs-2" />
                  </a> */}
                {article.sentiment ? (
                  article.sentiment === "positive" ? (
                    <a
                      href="#"
                      className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                      style={{ backgroundColor: "green" }}
                    >
                      {article.sentiment}
                    </a>
                  ) : article.sentiment === "negative" ? (
                    <a
                      href="#"
                      className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                    >
                      {article.sentiment}
                    </a>
                  ) : article.sentiment === "neutral" ? (
                    <a
                      href="#"
                      className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                    >
                      {article.sentiment}
                    </a>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </div>
     
      </div>
      ))}
  </div>
}
{view === "5" && (
          <div>
            <div
              className="card mb-3 "
              style={{ width: "60rem", margin: "auto" }}
            >
              <Carousel>
                {articleData &&
                  articleData.slice(0, 10).map((article, index) => (
                    <Carousel.Item key={article.id}>
                      <div className="card-body">
                        {/* <div className="symbol symbol-25px me-5">
                      <img src={data.source_logo} alt="" className="w-100" />
                    </div> */}
                        <br></br>

                        <div className="text-gray-400 fw-semibold mt-5">
                          <a
                            href={article.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {article.link}
                          </a>
                        </div>
                        <h5 className="">{article.title}</h5>

                        <p className="text-dark mt-2 font-weight-bold">
                          {/* {result.summarized_content && result.summarized_content.slice(0, 550)} */}
                        </p>
                        <p className="">
                          <small className="text-muted">
                            Last updated on {article.posted_on}
                          </small>
                        </p>
                        <div className="d-flex align-items-center mb-5">
                          {article.sentiment ? (
                            article.sentiment === "positive" ? (
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                                style={{ backgroundColor: "green" }}
                              >
                                {article.sentiment}
                              </a>
                            ) : article.sentiment === "negative" ? (
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                              >
                                {article.sentiment}
                              </a>
                            ) : article.sentiment === "neutral" ? (
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                              >
                                {article.sentiment}
                              </a>
                            ) : null
                          ) : null}

                       
                        </div>
                        <div style={{ height: "40rem", objectFit: "cover" }}>
                          <img
                            src={article.image}
                            className="card-img-top h-100"
                            alt="..."
                          />
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
          </div>
        )}
              {renderPagination()}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BundleArticle;



/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { MFACode } from "./components/MFACode";
import { useNavigate } from 'react-router-dom';

import Price from "./components/Price";
import { Registration } from "./components/Registration";
import RequestMFA from "./components/mfacode/RequestMFA";
import ResendMFA from "./components/mfacode/ResendMFA";
// import ResetPassword from "./components/Resetpassword";
import Verificationsignup from "./components/mfacode/Verificationsignup";
import  Resetpassword  from "./components/Resetpassword";
import Deactivate from "../../pages/settings/Deactivate";
import Success from "./components/Success";
import Changepassword from "./components/Changepassword";
const AuthLayout = () => {

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div
      className="container1 d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundColor: "#201249"
      }}
    > 
    {/* backgroundImage: `url(${toAbsoluteUrl(
      "/media/illustrations/sketchy-1/14.png"
    )})`, */}
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/newlogo/wiretoday.png")}
            className="theme-dark-show h-85px"
          />
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/newlogo/wiretoday.png")}
            className="theme-light-show h-85px"
          ></img>
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      {/* <div className="d-flex flex-center flex-column-auto p-10">
        <div className="d-flex align-items-center fw-semibold fs-6">
          <a href="#" className="text-muted text-hover-primary px-2">
            About
          </a>

          <a href="#" className="text-muted text-hover-primary px-2">
            Contact
          </a>

          <a href="#" className="text-muted text-hover-primary px-2">
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  );
};

const AuthPage = () => (
  
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration/>} />
  
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="change-password" element={<Changepassword/>} />

      <Route path="resetpassword" element={<Resetpassword/>} />
      <Route path="success" element={<Success />} />

      {/* <Route path="mfa-code" element={<MFACode />} /> */}
      {/* <Route path="request-mfa-code" element={<RequestMFA/>} /> */}
      <Route path="mfa-code" element={<ResendMFA/>} />
     
      <Route path="verify" element={<Verificationsignup/>} />
      <Route path="deactivate-user" element={<Deactivate/>} />

      <Route index element={<Login />} />     
    </Route>
    <Route path="pricing" element={<Price />} />

  </Routes>
);

export { AuthPage };

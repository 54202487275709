import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../../../app/auth-header";
const PopularTopicsWidget = () => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/policy/hot_topic_articles_v1`,
          {
            headers: authHeader(),
          }
        );
        
        // console.log("API Response:", response);
        
        if (response.data.status === 200) {
          setTopics(response.data.result);
        } else if (response.data.status === 400) {
          // console.log("No Hot Topics Found");
          setTopics([]);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching topics:", error);
      }
    };

    fetchTopics();
  }, []);
  const handleMouseEnter = () => {
    // Pause the animation on mouse enter
    document.querySelector(".carousel-container").style.animationPlayState =
      "paused";
  };

  const handleMouseLeave = () => {
    // Resume the animation on mouse leave
    document.querySelector(".carousel-container").style.animationPlayState =
      "running";
  };
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#333",
        color: "#fff",
        padding: "10px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <style>
        {`
          .carousel-container {
            display: flex;
            animation: ticker 20s linear infinite;
          }

          @keyframes ticker {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          .topic {
            width: 100%; /* Make each topic occupy 100% width */
            white-space: nowrap;
            color: #fff;
            paddingt: 25px;
          }
        `}
      </style>

      {loading ? (
        <div>Loading...</div>
      ) : (
         <div
          className="carousel-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {topics.length === 0 ? (
            <div>No latest topics found</div>
          ) : (
            topics.map((topic) => (
              <a
                className="topic"
                key={topic.id}
                href={topic.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={topic.source_logo}
                  alt={topic.source}
                  style={{ paddingLeft: "80px", height: "24px" }}
                />
                 <span style={{ marginLeft: "10px" }}>{topic.title}</span>
              </a>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default PopularTopicsWidget;



import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";

const Displaystats = ({ className }) => {
  const [sources, setSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiLimits, setApiLimits] = useState({});
  const [sourcesStats, setSourcesStats] = useState([]);
  const [bundleStats, setBundleStats] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user_portal/api/display_stats`,
          {
            headers: authHeader(),
          }
        );

        if (response.status === 200) {
          const { sources_stats, bundle_stats } = response.data;

          const newSources = sources_stats.map((sourceData) => ({
            platform: sourceData.platform,
            result: String(sourceData.article_count),
            icon: sourceData.source_logo,
          }));

          setSources(newSources);
          setApiLimits({
            max_views_bundle: bundle_stats.max_views_bundle,
            min_views_bundle: bundle_stats.min_views_bundle,
            current_api_limit: bundle_stats.current_api_limit,
          });
          setSourcesStats(sources_stats);
          setBundleStats(bundle_stats);
        } else if (response.status === 401) {
          window.location.href = "/auth/login";
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const sortedSources = [...sources].sort((a, b) => parseInt(b.result) - parseInt(a.result));

  const constructURL = (platform) => `/user/${platform?.toLowerCase() || ''}`;


  return (
    <div>
    <div className={`card ${className}`} style={{ overflowX: "hidden" }}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Stats Result</span>
          {/* <span className="text-muted mt-1 fw-semibold fs-7">From the below platforms</span> */}
        </h3>
      </div>
      {apiLimits.max_views_bundle ? (
        <div className="card mt-4">
         
          <div className="card-body py-3">
          <table className="table table-striped">
  <thead>
    <tr>
      <th></th>
      <th className="fw-bold">Bundle Title</th>
      <th className="fw-bold">Views Count</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="fw-bold">Most Read Bundle</td>
      <td>{apiLimits.max_views_bundle.bundle_title}</td>
      <td>{apiLimits.max_views_bundle.views_count}</td>
    </tr>
    <tr>
      <td className="fw-bold">Least Read Bundle</td>
      <td>{apiLimits.min_views_bundle.bundle_title}</td>
      <td>{apiLimits.min_views_bundle.views_count}</td>
    </tr>
  </tbody>
</table>

          </div>
        </div>
      ) : null}

    

      <div className="card mt-2">
        <div className="card-header border-0 pt-5">
          <h5 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold  mb-1">Api Limit</span>
          </h5>
        </div>
        <div className="card-body py-3">
          <p>{apiLimits.current_api_limit}</p>
        </div>
      </div>
      <div className="card-body py-3">
        <div>
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="table-responsive" style={{ overflowX: "auto" ,overflowY:"scroll",  maxHeight: "400px"}}>
                {sortedSources.length === 0 ? (
                  <div className="text-center mt-4">No Platforms Found</div>
                ) : (
                  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="border-0">
                        <th className="p-0 w-50px"></th>
                        <th className="p-0 min-w-150px"></th>
                        <th className="p-0 min-w-140px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedSources.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <div className="symbol symbol-25px me-2">
                              <span className="symbol-label">
                                <img
                                  src={toAbsoluteUrl(data.icon)}
                                  className="h-50 align-self-center"
                                  alt=""
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a
                              href={constructURL(data.platform)}
                              className="ms-2 d-flex justify-content-between text-dark fw-bold text-hover-primary mb-1 fs-6"
                            >
                              <span>{data.platform}</span>
                              <span>{data.result}</span>
                            </a>
                          </td>
                          <td className="text-end">
                            <span className="badge badge-light-primary"></span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
   


  </div>
  );
};

export default Displaystats;

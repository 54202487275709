import React, { useState } from "react";
import { FaSearch, FaCircle } from "react-icons/fa";
import { RiLink } from "react-icons/ri";
import { IoIosImages } from "react-icons/io";
import { BsArrowRightSquareFill } from "react-icons/bs";
import authHeader from "../../auth-header";
import { FaArrowCircleUp } from "react-icons/fa";

export default function Searchgpt() {
  const [articles, setArticles] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [showSecondSidebar, setShowSecondSidebar] = useState(false);
  const [sourcesPosts, setSourcesPosts] = useState([]);
  const [showNoArticlesMessage, setShowNoArticlesMessage] = useState(false);
  const [queryTitle, setQueryTitle] = useState("");
  const [queryDescription, setQueryDescription] = useState("");
  const [response, setResponse] = useState(null);
  const fetchArticles = () => {
    if (!query.trim()) {
      return;
    }

    setLoading(true);
    setShowSearch(false);
    setShowNoArticlesMessage(false);

    fetch(
      `${process.env.REACT_APP_API_URL}/article/search_engine/?query=${query}`,
      {
        headers: authHeader(),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("search gpt", data);

        if (data.status === 204) {
          setArticles([]);
          setSourcesPosts([]);
          setLoading(false);
          setShowNoArticlesMessage(true);
          return;
        }

        const results = data.results || {};
        setQueryTitle(results.query_title || "");
        setQueryDescription(results.query_description || "");
        setArticles(results.articles || []);
        setSourcesPosts(results.sources_posts || []);
        setResponse(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        setLoading(false);
      });
  };

  const toggleSecondSidebar = () => {
    setShowSecondSidebar(!showSecondSidebar);
  };

  return (
    <div
      style={{ display: "flex", height: "100%", border: "1px solid #d3d3d3" }}
    >
      {/* Sidebar */}
      <div
        style={{
          width: "70px",
          height: "auto",
          borderRight: "1px solid #d3d3d3",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
          boxSizing: "border-box",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li style={{ marginBottom: "10px" }}>
            <a href="#" style={{ color: "#a9a9a9", fontSize: "23px" }}>
              <FaSearch />
            </a>
          </li>
          <li style={{ marginBottom: "10px" }}>
            <a
              href="#"
              style={{ color: "#a9a9a9", fontSize: "23px" }}
              onClick={toggleSecondSidebar}
            >
              <RiLink />
            </a>
          </li>
          {/* <li style={{ marginBottom: "10px" }}>
            <a href="#" style={{ color: "#a9a9a9", fontSize: "23px" }}>
              <IoIosImages />
            </a>
          </li> */}
        </ul>
      </div>

      {/* Main Content */}
      <div style={{ flexGrow: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "-400px",
          }}
        >
          {showSearch && (
            <div>
              <div className="d-flex justify-content-center align-items-center gap-2 mt-14">
                <h1 className="text-bold">SearchGPT</h1>
                <FaCircle style={{ fontSize: "18px", marginTop: "-5px" }} />
              </div>
              <div
                style={{
                  margin: "20px 0",
                  position: "relative",
                  width: "600px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <textarea
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  style={{
                    width: "100%",
                    height: "130px",
                    padding: "20px 20px 20px 50px",
                    fontSize: "15px",
                    lineHeight: "1.5",
                    resize: "none",
                    borderRadius: "20px",
                    border: "1px solid #ebebeb",
                    boxSizing: "border-box",
                    overflowY: "auto",
                    outline: "none",
                    backgroundColor: "#ebebeb",
                  }}
                />
                <FaSearch
                  style={{
                    position: "absolute",
                    left: "20px",
                    top: "20px",
                    color: "black",
                    fontSize: "20px",
                  }}
                />
                <button
                  style={{
                    position: "absolute",
                    right: "5px",
                    padding: "10px 15px",
                    border: "none",
                    backgroundColor: "transparent",
                    color: "black",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "25px",
                    marginTop: "80px",
                  }}
                  onClick={fetchArticles}
                >
                  <BsArrowRightSquareFill />
                </button>
              </div>
            </div>
          )}
        </div>
        {loading && (
          <div style={{ marginTop: "20px" }}>
            <p>Loading...</p>
          </div>
        )}

        {/* Loading indicator */}
        {loading && (
          <div style={{ marginTop: "20px" }}>
            <p>Loading...</p>
          </div>
        )}

        {/* No articles message */}

        {response && (
          <h1 className="mt-2 p-2" style={{ color: "#ccc" }}>
            {query}
          </h1>
        )}
        {!loading && showNoArticlesMessage && (
          <div>
            <div
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginLeft: "-400px",
              }}
            >
              <p className="text-center">No Searched Articles Found</p>
            </div>
            <div
              style={{
                bottom: "0",
                position: "fixed",
                width: "750px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "250px",
              }}
            >
              <input
                type="text"
                placeholder="Ask a follow-up"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                style={{
                  width: "100%",
                  height: "50px",
                  padding: "10px 50px 10px 50px",
                  borderRadius: "25px",
                  border: "1px solid  #ebebeb",
                  fontSize: "14px",
                  boxSizing: "border-box",
                  outline: "none",
                  backgroundColor: "#ebebeb",
                }}
              />
              <FaSearch
                style={{
                  position: "absolute",
                  left: "20px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#ccc",
                  fontSize: "20px",
                }}
              />
              <button
                style={{
                  position: "absolute",
                  right: "20px",
                  padding: "10px 15px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
                onClick={fetchArticles}
              >
                <FaArrowCircleUp />
              </button>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "550px",
            overflowY: "auto",
          }}
        >
          {showSecondSidebar && (
            <div
              style={{
                width: "25%",
                height: "550px",
                // height: "100vh",
                borderRight: "1px solid #d3d3d3",
                padding: "10px",
                boxSizing: "border-box",
                overflowY: "auto",
              }}
            >
              {!loading && sourcesPosts.length > 0 && (
                <div style={{ marginTop: "20px" }}>
                  {sourcesPosts.map((source, index) => {
                    return (
                      <div key={index}>
                        <div style={{ display: "flex" }}>
                          <div>
                            <img
                              src={source.source_logo}
                              alt="Source Logo"
                              style={{
                                height: "50px",
                                width: "50px",
                                marginRight: "20px",
                              }}
                            />
                          </div>
                          <div>
                            {source.posts.map((post, idx) => (
                              <div
                                key={idx}
                                style={{
                                  marginTop: "10px",
                                  width: "80%",
                                  // borderBottom: "1px solid #ccc",
                                }}
                              >
                                <div>
                                  <a
                                    href={post.post_url}
                                    style={{
                                      color: "#999",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                      textDecoration: "none",
                                    }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {post.platform}
                                  </a>

                                  <h6>
                                    {post.title.length > 70
                                      ? post.title.substring(0, 70) + "..."
                                      : post.title}
                                  </h6>

                                  <p
                                    className="fs-8"
                                    style={{
                                      color: "#999",
                                    }}
                                  >
                                    {post.summary.length > 150
                                      ? post.summary.substring(0, 82) + "..."
                                      : post.summary}
                                  </p>
                                  <p
                                    className="fs-8"
                                    style={{
                                      color: "#999",
                                    }}
                                  >
                                    {post.posted_on}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div
            style={{
              flexGrow: 1,
              width: showSecondSidebar ? "50%" : "100%",

              transition: "width 0.3s ease",
              padding: "20px",
              boxSizing: "border-box",
            }}
          >
            {!loading && articles.length > 0 && (
              <div
              // style={{
              //   display: "flex ",
              //   flex: "col",
              //   justifyContent: "center",
              // }}
              >
                <div style={{ marginTop: "20px", width: "50%" }}>
                  <div>
                    <h2 style={{ color: "#333" }}>{queryTitle}</h2>
                    <p>{queryDescription}:</p>
                  </div>
                  {articles.map((article, index) => (
                    <div key={index}>
                      <div
                        className="flex"
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: "20px",
                          borderBottom: "1px solid #d3d3d3",
                          paddingBottom: "10px",
                        }}
                      >
                        <div style={{ flexShrink: 0, width: "100px" }}>
                          <img
                            style={{
                              height: "150px",
                              width: "100px",
                              borderRadius: "15px",
                              objectFit: "cover",
                              marginRight: "20px",
                            }}
                            src={article.post_image}
                            alt={article.title}
                          />
                        </div>
                        <div className="mt-4 p-2">
                          <h3
                            style={{
                              margin: 0,
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {article.title}
                          </h3>
                          <p style={{ margin: "5px 0", fontSize: "14px" }}>
                            {article.summary.length > 150
                              ? article.summary.substring(0, 250) + "..."
                              : article.summary}
                            <span>
                              <a
                                href={article.post_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: "#007bff",
                                  textDecoration: "none",
                                  fontSize: "14px",
                                }}
                              >
                                ({article.platform})
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    bottom: "0",
                    position: "fixed",
                    width: "750px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Ask a follow-up"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{
                      width: "100%",
                      height: "50px",
                      padding: "10px 50px 10px 50px",
                      borderRadius: "25px",
                      border: "1px solid  #ebebeb",
                      fontSize: "14px",
                      boxSizing: "border-box",
                      outline: "none",
                      backgroundColor: "#ebebeb",
                    }}
                  />
                  <FaSearch
                    style={{
                      position: "absolute",
                      left: "20px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#ccc",
                      fontSize: "20px",
                    }}
                  />
                  <button
                    style={{
                      position: "absolute",
                      right: "20px",
                      padding: "10px 15px",
                      border: "none",
                      backgroundColor: "transparent",
                      color: "#ccc",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={fetchArticles}
                  >
                    <FaArrowCircleUp />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";

const DashboardTable = () => {
  // State to store the API data
  const [subscriptionData, setSubscriptionData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/get_subscription`,
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );
        // console.log("This is response ", response);
        const data = response.data.result;
        setSubscriptionData(data);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      } finally {
        setIsLoading(false); // Set isLoading to false regardless of the success or error
      }
    };
    fetchSubscriptionData();
  }, []);

  return (
    <div className="card">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Subscription Details</span>
          {/* <span className="text-muted mt-1 fw-semibold fs-7">History</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {isLoading ? (
        <div className="text-center" style={{paddingTop:"120px"}}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold text-muted">
                  {/* <th className="w-25px">Status</th> */}
                  <th className="min-w-150px">First Name</th>
                  <th className="min-w-150px">Last Name</th>
                  <th className="min-w-200px">Email</th>
                  <th className="min-w-140px">Package Name</th>
                  <th className="min-w-120px">Payment Amount</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {subscriptionData.map((data: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          {data.user_firstname}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          {data.user_lastname}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          {data.user_email}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark fw-bold text-hover-primary fs-6">
                          {data.package_name}
                        </span>
                      </td>
                      <td>
                        <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                          {data.userpayment}
                        </span>
                      </td>
                      {/* You can modify the rest of the table data according to your needs */}
                    </tr>
                  );
                })}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      )}
      {/* begin::Body */}
    </div>
  );
};

export default DashboardTable;

import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfilGet = () => {
  const [authProvider, setAuthProvider] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const updateLocalStorage = (data) => {
    localStorage.setItem("userProfileData", JSON.stringify(data));
  };

  useEffect(() => {
    const fetchUserProfileData = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/user_portal/api/get_user_profile`, {
          headers: authHeader(),
        })
        .then((response) => {
          // console.log("Api Res", response.data);

          const profileData = response.data.result[0]; 

          setAuthProvider(profileData.auth_provider || ""); 
          setLastLogin(profileData.last_login || ""); 

          updateLocalStorage(profileData);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
          setErrorMessage("An error occurred while fetching the profile data.");
        });
    };

    fetchUserProfileData();
  }, []);

  return (
    <div>
       <form>

<div className="row">
        <div className="col-md-6 mb-3">
          <label htmlFor="authProvider" className="form-label">
            Method of Login
          </label>
          <input
            type="text"
            className="form-control"
            // style={{backgroundColor:"white"}}

            id="authProvider"
            name="authProvider"
            value={authProvider}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="lastLogin" className="form-label">
            Last Logged In
          </label>
          <input
            type="text"
            // style={{backgroundColor:"white"}}

            className="form-control"
            id="lastLogin"
            name="lastLogin"
            value={lastLogin}
            disabled
          />
        </div>
      </div>
    </form>
    </div>
   
   
  );
};

export default ProfilGet;

import React, { useEffect, useState } from "react";
import NewsDropDown from "./NewsDropDown";
import { MdArrowDropDown } from "react-icons/md";
import SocialDropDown from "./SocialDropDown";
import axios from "axios";
import authHeader from "../../../../../auth-header";

const EditBundle = ({ isOpen, onClose, initialBundle, initialSources, bundleSources }) => {
  
  const initialFormState = {
    interval_type: "",
    enable_disable_task:"default",
   
  };
  
  const [formData, setFormData] = useState(initialFormState);
  const [validationError, setValidationError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [intervalTypes, setIntervalTypes] = useState([]);
  const fetchIntervalTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_interval_types`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data && response.data.result) {
          setIntervalTypes(response.data.result);
        } else {
          console.error("Invalid response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching interval types:", error);
      });
  };
  useEffect(() => {
    setFormData({
      ...initialBundle,
      sources: initialBundle?.sources || initialSources || [],
    });

    fetchIntervalTypes();
  }, [initialBundle, initialSources]);
  useEffect(() => {
    if (initialBundle) {
      setFormData({
        interval_type: initialBundle.interval_type || "",
      

       
      });
    }
  }, [initialBundle]);
  
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSourceChange = (selectedSources) => {
    setFormData({
      ...formData,
      sources: selectedSources,
    });
  };
  

  const handleSaveChanges = (event) => {
    event.preventDefault();
   
const dataToSend = {
  id: initialBundle.periodic_task_id,
  interval_type: formData.interval_type,
  enable_disable_task: "",
 
};
    //     console.log("formData.sources:", formData.sources);
    // console.log("dataToSend.id:", dataToSend.id);
    // console.log("dataToSend:", dataToSend);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/policy/edit_bundle_source_periodic_task/${initialBundle.periodic_task_id}`,
        dataToSend,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        // console.log("Intial Bundle", initialBundle.id)
        // console.log("API edit Response:", response.data);
        // console.log("API edit Response:", response);

        if (response.status === 200) {
          window.location.reload();
        } else {
         
        }
        setValidationError("");
        setSaveSuccess(true);

        onClose();
      })
      .catch((error) => {
        console.error("Error updating bundle:", error);
      });
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Schedule Sources</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={handleSaveChanges}>
            <div className="modal-body">
             
             
              
              <div className="mb-3">
                <label htmlFor="interval_type" className="form-label">
                Periodic Task Schedule	
                </label>
                <select
                  className="form-select"
                  id="interval_type"
                  name="interval_type"
                  value={formData.interval_type}
                  onChange={handleInputChange}
                >
                  <option  value="">Select Schedule Type</option>
                  {intervalTypes.map((intervalType) => (
    <option key={intervalType.interval_name} value={intervalType.interval_type}>
      {intervalType.interval_name}
    </option>
  ))}
                </select>
              </div>
             
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditBundle;
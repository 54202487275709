import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

import axios from "axios";
import { toast } from "react-toastify";
import Feedlabel from "./CreateBundle/Feedlabel";
import EditFeeds from "../../../../../pages/feeds/EditFeeds";
import authHeader from "../../../../../auth-header";
import EditFeed from "./CreateBundle/Editfeed";
const EditMyfeed = ({
  isOpen,
  onClose,
  initialBundle,
  initialSources,
  news_sources,
  social_sources,
}) => {
  const [bundleData, setBundleData] = useState({
    feed_id: initialBundle ? initialBundle.feed_id : "",

    feed_title: initialBundle ? initialBundle.feed_title : "",
    feed_keyword: initialBundle ? initialBundle.feed_keyword : "",
    // feed_black_list_keyword: initialBundle
    //   ? initialBundle.feed_black_list_keyword
    //   : "",
    feed_black_list_keyword: initialBundle
      ? initialBundle.feed_black_list_keyword || ""
      : "",

    interval_type: initialBundle ? initialBundle.interval_type : "",
  });
  console.log("EditMyfeedinitialBundle ", initialBundle);
  const [formData, setFormData] = useState({ feedTitles: [] });
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newKeywordPrompt, setNewKeywordPrompt] = useState(false);
  const initialFeedIds =
    initialBundle && initialBundle.feeds
      ? initialBundle.feeds.map((feed) => feed.id)
      : [];
  const [selectedFeeds, setSelectedFeeds] = useState(initialFeedIds);

  const [whitelistedKeywords, setWhitelistedKeywords] = useState(
    initialBundle.keywords
  );
  const [newKeyword, setNewKeyword] = useState("");

  const [newsSources, setNewsSources] = useState(news_sources);
  const [socialSources, setSocialSources] = useState(social_sources);
  // useEffect(() => {
  //   fetchFeedTitles();
  // }, []);

  // const fetchFeedTitles = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/policy/get_feeds_v1`, {
  //       headers: authHeader(),
  //     })
  //     .then((response) => {
  //       if (response.data.status === 200) {
  //         //   console.log("edit feeds", response);

  //         const feedTitles = response.data.result;
  //         setFormData((prevFormData) => ({
  //           ...prevFormData,
  //           feedTitles: feedTitles,
  //         }));

  //         if (
  //           initialBundle &&
  //           initialBundle.feeds &&
  //           initialBundle.feeds.length > 0
  //         ) {
  //           setBundleData((prevBundleData) => ({
  //             ...prevBundleData,
  //             feed_title: initialBundle.feeds[0].feed_title,
  //           }));
  //         }
  //       } else {
  //         console.error("Failed to fetch feed titles:", response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching feed titles:", error);
  //     });
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setBundleData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   useEffect(() => {
  //     if (!bundleData.bundle_title || bundleData.bundle_title.trim() === "") {
  //       setValidationError("Please enter a title.");
  //       setTimeout(() => {
  //         setValidationError("");
  //       }, 5000);
  //     }
  //   }, [bundleData.bundle_title]);

  //   useEffect(() => {
  //     if (!bundleData.feed_title) {
  //       setValidationError("Please enter feed.");
  //       setTimeout(() => {
  //         setValidationError("");
  //       }, 5000);
  //     }
  //   }, [bundleData.feed_title]);

  const handleSubmitForm = (event) => {
    event.preventDefault();

    if (!bundleData.feed_title || bundleData.feed_title.trim() === "") {
      setValidationError("Please enter a title.");
      setTimeout(() => {
        setValidationError("");
      }, 3000);
      return;
    }
    if (!bundleData.feed_keyword || bundleData.feed_keyword.trim() === "") {
      setValidationError("Please enter a keyword.");
      setTimeout(() => {
        setValidationError("");
      }, 3000);
      return;
    }
    console.log("Form submitted");

    const formData = new FormData();
    formData.append("feed_id", initialBundle.feed_id);
    formData.append("feed_title", bundleData.feed_title);
    formData.append("interval_type", bundleData.interval_type);
    formData.append("feed_keyword", bundleData.feed_keyword);
    formData.append(
      "feed_black_list_keyword",
      bundleData.feed_black_list_keyword
    );
    console.log("feed_black_list_keyword", bundleData.feed_black_list_keyword);

    console.log("feed_keyword", bundleData.feed_keyword);

    setIsLoading(true);
    console.log("feed_id", initialBundle.feed_id);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/policy/update_feed_v2/${initialBundle.feed_id}`,
        formData,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("this is response", response);
        if (response.data.status === 200) {
          toast.success(response.data.message, { autoClose: 3000 });
          window.location.reload();
        } else {
          toast.error(response.data.message, { autoClose: 3000 });
          setIsLoading(false);

          // window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error updating bundle:", error);
        setIsLoading(false);
        // Handle error
        toast.error("An error occurred while updating the bundle.", {
          autoClose: 3000,
        });
      });
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Feed</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          {validationError && (
            <div className="alert alert-danger" role="alert">
              {validationError}
            </div>
          )}
          <form onSubmit={handleSubmitForm}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="bundle_title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="feed_title"
                  name="feed_title"
                  value={bundleData.feed_title}
                  onChange={handleInputChange}
                  placeholder="Enter Title"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="feed_keyword" className="form-label">
                  Whitelisted Keyword
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="feed_keyword"
                  name="feed_keyword"
                  value={bundleData.feed_keyword}
                  onChange={handleInputChange}
                  placeholder="Enter Title"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="feed_black_list_keyword" className="form-label">
                  Blacklisted Keyword
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="feed_black_list_keyword"
                  name="feed_black_list_keyword"
                  value={bundleData.feed_black_list_keyword}
                  onChange={handleInputChange}
                  placeholder="Enter Title"
                />
              </div>

              {/* <div className="mb-3">
                <label htmlFor="bundle_title" className="form-label">
                  Interval Type
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="blacklist_keyword
"
                  name="blacklist_keyword
"
                  value={bundleData.blacklist_keyword}
                  onChange={handleInputChange}
                  placeholder="Enter Title"
                />
              </div> */}

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding...Please Wait" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditMyfeed;

import React, { useState, useEffect } from "react";
import { BsFillChatSquareHeartFill, BsFillBookFill } from "react-icons/bs";
import { AiFillRead } from "react-icons/ai";
import { KTIcon } from "../../../../_metronic/helpers";
import { Carousel } from "react-bootstrap";
import { Pagination, Modal, Button } from "react-bootstrap";
import authHeader from "../../../auth-header";
import axios, { AxiosResponse } from "axios";
import { IoMdShare } from "react-icons/io";
import { FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import { CiSaveUp2 } from "react-icons/ci";
import Share from "./Share";
export type NewsItem = {
  id: number;
  title: string;
  link: string;
  posted_on: string;
  image: string;
  source_logo: string;
  source: string;

  liked: boolean;
  summarized_content: string;
  post_id: number;
  read_later: boolean;
  content_image: string;
  sentiment: string;
  source_name: string;
  author: string;
  article_language: string;
  article_country: string;
  bundle: string;
};
type NewsProps = {
  className: string;
  news: NewsItem[];
  handleLike: (
    id: number,
    liked: boolean,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => void;
  handleReadLater: (
    id: number,
    read_later: boolean,
    event: React.MouseEvent<HTMLAnchorElement>
  ) => void;
};

type NewsTemplateDashboardState = {
  view: string;
  showShareModal: boolean;
  shareResult: string | null;
  selectedArticleLink: string | null;
};
const Newstemplatedashboard: React.FC<NewsProps> = ({
  news,
  handleLike,
  handleReadLater,
}) => {
  const [showIframeModal, setShowIframeModal] = React.useState(false);

  // console.log(news, "duhjddj");
  const [linkiframe, setlinkiframe] = useState<any>([]);
  const [idframe, updateiframe] = useState<number>(0);
  // console.log(linkiframe);

  const [state, setState] = React.useState<NewsTemplateDashboardState>({
    view: "1",
    selectedArticleLink: null,
    showShareModal: false,
    shareResult: null,
  });
  const [view, setView] = React.useState("1");
  const [showShareModal, setShowShareModal] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState<NewsItem | null>(
    null
  );
  const [showShareArticleModal, setShowShareArticleModal] =
    React.useState(false);

  const handleIFrameClick = (articleId: number) => {
    // console.log("handleIFrameClick: Opening modal for article ID", articleId);

    updateiframe(articleId);
    setShowIframeModal(true);
  };
  const handleSaveToPocket = async (link: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/article/add_articles_to_get_pocket`,
        { link: link },
        {
          headers: authHeader() as { Authorization: string },
        }
      );

      // Handle success (you may want to show a success message or update UI)
      // console.log("Article saved to Pocket:", response.data);

      // You can also add any additional logic or UI updates here
      if (response.data.status === 200) {
        // Show a success toast
        toast.success(
          response.data.message || "Article successfully added to GetPocket"
        );
      }

      // You can also add any additional logic or UI updates here
    } catch (error) {
      console.error("Error saving to Pocket:", error);
      // Handle errors, show error message, etc.
    }
  };
  const handleShareArticle = (article: NewsItem) => {
    setSelectedArticle(article);
    setShowShareArticleModal(true);
  };
  const handleShare = (id: number) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/article/share_article/${id}`,
      headers: authHeader() as { Authorization: string },
    })
      .then((res: AxiosResponse) => {
        // console.log("Share API Response:", res.data);
        setState({ ...state, shareResult: res.data.result });
        setShowShareModal(true);
      })
      .catch((error) => console.error("Share API Request Error:", error));
    setShowShareModal(true);
  };
  const [copied, setCopied] = useState(false);
  const [copie, setCopie] = useState(false);

  const copyToClipboardShareModal = () => {
    // console.log("copyToClipboardShareModal: Copying to clipboard");
    const textToCopy = state.shareResult || "";

    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);
    setCopie(true);

    setTimeout(() => {
      setCopie(false);
    }, 1000);
  };
  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  console.log("nEWS TYPE", typeof news);
  const copyToClipboard = () => {
    // console.log("copyToClipboard: Copying to clipboard");
    const textToCopy = `<iframe src="${state.selectedArticleLink}" name="myIFrame" scrolling="no" width="50%" height="30%" style="border: 10px solid #000000;"<iframe/>`;

    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  return (
    <div>
      <div className="align-items-center">
        <span className="fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
          Select View:
        </span>
        <select
          className="form-select form-select-sm form-select-solid w-100px w-xxl-125px mb-15"
          data-control="select2"
          data-placeholder="Latest"
          data-hide-search="true"
          value={view}
          onChange={(e) => setView(e.target.value)}
        >
          <option value="1">List</option>
          <option value="2">Grid</option>
          <option value="3">Magazine</option>
          <option value="4">Card</option>
          <option value="5">Carousel</option>
        </select>
      </div>
      <div className="row g-5 g-xl-12">
        {view === "1" &&
          news?.map((data, index: number) => (
            <div className="col-xl-12" key={data.id}>
              <div className="card mb-3" style={{ maxWidth: "1000px" }}>
                <div className="row no-gutters">
                  <div className="col-md-7">
                    <div className="card-body">
                      {" "}
                      <div className="d-flex gap-5">
                        <div
                          className=""
                          style={{ width: "30px", height: "30px" }}
                        >
                          <img
                            src={data.source_logo}
                            alt=""
                            className="w-100"
                          />
                        </div>
                        <div>
                          {" "}
                          <h5 className="">{data.source}</h5>
                        </div>
                      </div>
                      <div className="d-flex">
                        {" "}
                        <a
                          className="card-text   fw-bold mt-5"
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.title}
                        </a>
                      </div>{" "}
                      <h5 className="card-title mt-5">
                        Author : {data.author}
                      </h5>
                      <div className="d-flex mb-3">
                        <p className="card-text">
                          <small>Language : {data.article_language}</small>
                        </p>
                        <p
                          className="card-text"
                          style={{ paddingLeft: "10px" }}
                        >
                          <small>Country : {data.article_country}</small>
                        </p>
                      </div>
                      <h5 className="fw-bold">Bundle Name : {data.bundle}</h5>
                      <p className="card-text">
                        <small>Date Published : {data.posted_on}</small>
                      </p>
                      <p className="card-text">
                        <small>
                          {truncateText(data.summarized_content, 250)}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="symbol symbol-25px me-5">
                      <img
                        src={data.image}
                        className=" h-75 w-100 card-img  "
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
                <div className=" d-flex flex-wrap justify-content-start align-items-center   gap-2">
                  <a
                    href="#"
                    className={`px-4 py-2 me-4 ${data.liked ? "liked" : ""}`}
                    onClick={(event) => handleLike(data.id, data.liked, event)}
                  >
                    {data.liked ? (
                      <BsFillChatSquareHeartFill
                        style={{
                          fontSize: "30px",
                          color: "#E76161",
                        }}
                      />
                    ) : (
                      <BsFillChatSquareHeartFill style={{ fontSize: "30px" }} />
                    )}
                  </a>
                  <a
                    href="#"
                    className={`px-4 py-2 me-4 ${
                      data.read_later ? "read-later" : ""
                    }`}
                    onClick={(event) =>
                      handleReadLater(data.id, data.read_later, event)
                    }
                  >
                    {data.read_later ? (
                      <AiFillRead
                        style={{
                          fontSize: "30px",
                          color: "#E76161",
                        }}
                      />
                    ) : (
                      <AiFillRead
                        style={{
                          fontSize: "30px",
                          color: "#9DB2BF",
                        }}
                      />
                    )}
                  </a>
                  <a
                    className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                    onClick={() => handleIFrameClick(data.id)}
                  >
                    iFrame
                  </a>
                  <a
                    href="#"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                    onClick={() => handleShare(data.id)}
                  >
                    HTML
                  </a>
                  <a
                    href="#"
                    className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                    onClick={() => handleSaveToPocket(data.link)}
                  >
                    <CiSaveUp2 size={24} />
                    Pocket
                  </a>
                  <a
                    href="#"
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                    onClick={() => handleShareArticle(data)}
                  >
                    <IoMdShare size={24} />
                    Share
                  </a>
                  <a
                    href={`/detailnews/${data.id}`}
                    className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                  >
                    <KTIcon iconName="eye" className="fs-2" />
                  </a>
                  {data.sentiment ? (
                    data.sentiment === "positive" ? (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                        style={{ backgroundColor: "green" }}
                      >
                        {data.sentiment}
                      </a>
                    ) : data.sentiment === "negative" ? (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                      >
                        {data.sentiment}
                      </a>
                    ) : data.sentiment === "neutral" ? (
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                      >
                        {data.sentiment}
                      </a>
                    ) : null
                  ) : null}
                </div>
              </div>
              {data.id === idframe && (
                <>
                  <Modal
                    show={showIframeModal}
                    onHide={() => setShowIframeModal(false)}
                    size="lg"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Iframe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div
                      // style={{
                      //   top: "50%",
                      //   display: "flex",
                      //   justifyContent: "center",
                      //   alignItems: "center",
                      // }}
                      >
                        {/* {data.id ===idframe && ( */}
                        <div>
                          <div
                          //  style={{ textAlign: 'center' }}
                          >
                            <iframe
                              srcDoc={`<html><body><p>&lt;iframe src="${data.link}" name="myIFrame" scrolling="auto" width="50%" height="30%" style="border: 10px solid #000000;"&gt;&lt;/iframe&gt;</p></body></html>`}
                              name="myIFrame"
                              scrolling="no"
                              // width="100%"
                              // height="100%"
                              // style={{  width:"300px", height:"auto" , paddingLeft:"12px", paddingRight:"12px",marginTop:"-15px" , overflowX:"scroll"}}
                            ></iframe>
                            <div className="modal-buttons">
                              {/* <button className="btn btn-sm btn-primary px-4 py-2 me-4"
        onClick={() => handleIFrameClose(false)} 
        >
          Close
        </button> */}
                              <button
                                className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                                onClick={copyToClipboard}
                              >
                                <FiCopy /> Copy to Clipboard
                              </button>
                            </div>
                            {copied && (
                              <div className="copied-message">Copied!</div>
                            )}
                          </div>
                        </div>

                        {/* )} */}
                        {/* <div style={{ textAlign: "center" }}>
                        <iframe
                          srcDoc={`<html><body><p>&lt;iframe src="${
                            linkiframe[idframe - 1]?.link
                          }" name="myIFrame" scrolling="no" width="50%" height="30%" style="border: 10px solid #000000;"&gt;&lt;/iframe&gt;</p></body></html>`}
                          name="myIFrame"
                          scrolling="no"
                          width="100%"
                          height="80%"
                          style={{
                            border: "2px solid #000000",
                            padding: "20px",
                            background: "white",
                          }}
                        ></iframe>
                        <div className="modal-buttons">
                           <button className="btn btn-sm btn-primary px-4 py-2 me-4" onClick={() => setShowIframeModal(false)}>
              Close
            </button>
                          <button
                            className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                            onClick={copyToClipboard}
                          >
                            <FiCopy /> Copy to Clipboard
                          </button>
                        </div>
                        {copied && (
                          <div className="copied-message">Copied!</div>
                        )}
                      </div> */}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => setShowIframeModal(false)}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}{" "}
            </div>
          ))}
        {/* Share Article Modal */}
        {selectedArticle && (
          <Share
            onClose={() => {
              setShowShareArticleModal(false);
              setSelectedArticle(null);
            }}
            articleTitle={selectedArticle.title}
            articleImage={selectedArticle.image}
            articleLink={selectedArticle.link}
          />
        )}
        {/* Share Modal */}
        <Modal
          show={showShareModal}
          onHide={() => setShowShareModal(false)}
          size="lg" // Set size to large
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {state.shareResult ? (
              <div
                dangerouslySetInnerHTML={{ __html: state.shareResult }}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Body>
          {/* <Button style={{width:"20%"}} variant="secondary" >
  Copy to Clipboard

    </Button> */}
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "20%" }}
              className="btn btn-sm  btn-secondary  px-4 py-2 "
              onClick={copyToClipboardShareModal}
            >
              <FiCopy /> Copy to Clipboard
            </button>
            {copie && <div className="copied-message">Copied!</div>}
          </div>

          {/* <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowShareModal(false)}>
      Close
    </Button>
    
   
  </Modal.Footer> */}
        </Modal>

        {view === "2"
          ? news?.map((data, index: number) => {
              return (
                <div key={index} className="col-md-6  col-xl-6">
                  <div className="card " style={{ maxWidth: "800px" }}>
                    <div className="row no-gutters">
                      <div className="col-md-6">
                        <div className="card-body">
                          <div
                            className="d-flex gap-5"
                            style={{ width: "30px", height: "30px" }}
                          >
                            {data.source_logo ? (
                              <img
                                src={data.source_logo}
                                alt=""
                                className="w-100"
                              />
                            ) : (
                              <img
                                src="https://via.placeholder.com/150"
                                alt=""
                                className="w-100"
                              />
                            )}
                            <h5 className="mt-5">{data.source}</h5>
                          </div>

                          <br></br>
                          <a
                            className="card-text   fw-bold mt-5"
                            href={data.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <a
                              className="card-text   fw-bold mt-5"
                              href={data.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {truncateText(data.title, 50)}
                            </a>
                          </a>
                          <h5 className="card-title mt-5">
                            Author : {data.author}
                          </h5>

                          <div className="d-flex mb-3">
                            <p className="card-text">
                              <small>Language : {data.article_language}</small>
                            </p>
                            <p
                              className="card-text"
                              style={{ paddingLeft: "10px" }}
                            >
                              <small>Country : {data.article_country}</small>
                            </p>
                          </div>

                          <h5 className="fw-bold">
                            Bundle Name : {data.bundle}
                          </h5>
                          <p className="card-text">
                            <small>Date Published : {data.posted_on}</small>
                          </p>
                          <p className="card-text">
                            <small>
                              {truncateText(data.summarized_content, 250)}
                            </small>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-5" style={{ marginTop: "-150px" }}>
                        <img
                          src={data.image}
                          className="card-img"
                          style={{
                            width: "200px",
                            height: "100px",
                            marginTop: "150px",
                            objectFit: "cover",
                          }}
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className=" d-flex flex-wrap justify-content-between align-items-center mb-5  gap-2">
                      <a
                        href="#"
                        className={`px-4 py-2 me-4 ${
                          data.liked ? "liked" : ""
                        }`}
                        onClick={(event) =>
                          handleLike(data.id, data.liked, event)
                        }
                      >
                        {data.liked ? (
                          <BsFillChatSquareHeartFill
                            style={{
                              fontSize: "30px",
                              color: "#E76161",
                            }}
                          />
                        ) : (
                          <BsFillChatSquareHeartFill
                            style={{ fontSize: "30px" }}
                          />
                        )}
                      </a>
                      <a
                        href="#"
                        className={`px-4 py-2 me-4 ${
                          data.read_later ? "read-later" : ""
                        }`}
                        onClick={(event) =>
                          handleReadLater(data.id, data.read_later, event)
                        }
                      >
                        {data.read_later ? (
                          <AiFillRead
                            style={{
                              fontSize: "30px",
                              color: "#E76161",
                            }}
                          />
                        ) : (
                          <AiFillRead
                            style={{
                              fontSize: "30px",
                              color: "#9DB2BF",
                            }}
                          />
                        )}
                      </a>
                      <a
                        className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                        onClick={() => handleIFrameClick(data.id)}
                      >
                        iFrame
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        onClick={() => handleShare(data.id)}
                      >
                        HTML
                        {/* <IoMdShare size={24}
                          /> */}
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                        onClick={() => handleSaveToPocket(data.link)}
                      >
                        <CiSaveUp2 size={24} />
                        Pocket
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        onClick={() => handleShareArticle(data)}
                      >
                        <IoMdShare size={24} />
                        Share
                      </a>
                      <a
                        href={`/detailnews/${data.id}`}
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                      >
                        <KTIcon iconName="eye" className="fs-2" />
                      </a>
                      {data.sentiment ? (
                        data.sentiment === "positive" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                            style={{ backgroundColor: "green" }}
                          >
                            {data.sentiment}
                          </a>
                        ) : data.sentiment === "negative" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                          >
                            {data.sentiment}
                          </a>
                        ) : data.sentiment === "neutral" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                          >
                            {data.sentiment}
                          </a>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
          : ""}
        {/* Share Modal */}
        <Modal
          show={showShareModal}
          onHide={() => setShowShareModal(false)}
          size="lg" // Set size to large
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {state.shareResult ? (
              <div
                dangerouslySetInnerHTML={{ __html: state.shareResult }}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Body>
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "20%" }}
              className="btn btn-sm  btn-secondary  px-4 py-2 "
              onClick={copyToClipboardShareModal}
            >
              <FiCopy /> Copy to Clipboard
            </button>
            {copie && <div className="copied-message">Copied!</div>}
          </div>

          {/* <Button style={{width:"20%"}} variant="secondary" >
  Copy to Clipboard

    </Button> */}
          {/* <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowShareModal(false)}>
      Close
    </Button>
   
  </Modal.Footer> */}
        </Modal>

        {view === "3" ? (
          <div className="row me-0 align-items-stretch">
            {news?.map((data, index) => (
              <div
                key={index}
                className={index % 2 === 0 ? "col-lg-8" : "col-lg-4"}
              >
                <div className="card mb-4">
                  <div className="row">
                    <div className="col-lg-12 clearfix p-5">
                      <div className="d-flex justify-content-between  ">
                        <div>
                          {" "}
                          <h1 className="card-title">
                            <strong className="text-dark">
                              <a
                                className="text-dark"
                                href={data.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {data.title}
                              </a>
                            </strong>
                          </h1>
                        </div>

                        <div>
                          {" "}
                          {data.sentiment && (
                            <a
                              href="#"
                              className={`btn btn-sm btn-light btn-color-white ${
                                data.sentiment === "positive"
                                  ? "bg-success"
                                  : data.sentiment === "negative"
                                  ? "btn-danger"
                                  : "btn-warning"
                              } px-4 py-2 me-4`}
                            >
                              {data.sentiment}
                            </a>
                          )}{" "}
                        </div>
                      </div>

                      <img src={data.image} alt="" className="w-100" />

                      <h5 className="card-title mt-5">Author: {data.author}</h5>

                      <div className="d-flex mb-3">
                        <p className="card-text">
                          <small>Language: {data.article_language}</small>
                        </p>
                        <p
                          className="card-text"
                          style={{ paddingLeft: "10px" }}
                        >
                          <small>Country: {data.article_country}</small>
                        </p>
                      </div>

                      <h5 className="fw-bold">Bundle Name: {data.bundle}</h5>

                      <div>
                        <div
                          className="d-flex gap-5"
                          style={{ width: "30px", height: "30px" }}
                        >
                          <img
                            src={data.source_logo}
                            alt=""
                            className="w-100 card-img"
                          />{" "}
                          <h5 className="mt-5">{data.source}</h5>
                        </div>
                        <br />

                        <p className="text-dark font-weight-bold">
                          {data.summarized_content &&
                            data.summarized_content.slice(0, 1050)}
                        </p>
                        <p className="card-text">
                          <small>Date Published: {data.posted_on}</small>
                        </p>
                        <div className="d-flex align-items-center mb-5">
                          <div className="d-flex align-items-center mb-5">
                            <a
                              href="#"
                              className={`px-4 py-2 me-4 ${
                                data.liked ? "liked" : ""
                              }`}
                              onClick={(event) =>
                                handleLike(data.id, data.liked, event)
                              }
                            >
                              {data.liked ? (
                                <BsFillChatSquareHeartFill
                                  style={{
                                    fontSize: "30px",
                                    color: "#E76161",
                                  }}
                                />
                              ) : (
                                <BsFillChatSquareHeartFill
                                  style={{ fontSize: "30px" }}
                                />
                              )}
                            </a>
                            <a
                              href="#"
                              className={`px-4 py-2 me-4 ${
                                data.read_later ? "read-later" : ""
                              }`}
                              onClick={(event) =>
                                handleReadLater(data.id, data.read_later, event)
                              }
                            >
                              {data.read_later ? (
                                <AiFillRead
                                  style={{
                                    fontSize: "30px",
                                    color: "#E76161",
                                  }}
                                />
                              ) : (
                                <AiFillRead
                                  style={{
                                    fontSize: "30px",
                                    color: "#9DB2BF",
                                  }}
                                />
                              )}
                            </a>
                            <a
                              className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                              onClick={() => handleIFrameClick(data.id)}
                            >
                              iFrame
                            </a>
                            <a
                              href="#"
                              className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                              onClick={() => handleShare(data.id)}
                            >
                              HTML
                              {/* <IoMdShare size={24}
                          /> */}
                            </a>
                            <a
                              href="#"
                              className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                              onClick={() => handleShareArticle(data)}
                            >
                              <IoMdShare size={24} />
                              Share
                            </a>
                            <a
                              href={`/detailnews/${data.id}`}
                              className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                            >
                              <KTIcon iconName="eye" className="fs-2" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        <Modal
          show={showShareModal}
          onHide={() => setShowShareModal(false)}
          size="lg" // Set size to large
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {state.shareResult ? (
              <div
                dangerouslySetInnerHTML={{ __html: state.shareResult }}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Body>
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "20%" }}
              className="btn btn-sm  btn-secondary  px-4 py-2 "
              onClick={copyToClipboardShareModal}
            >
              <FiCopy /> Copy to Clipboard
            </button>
            {copie && <div className="copied-message">Copied!</div>}
          </div>

          {/* <Button style={{width:"20%"}} variant="secondary" >
  Copy to Clipboard

    </Button> */}
          {/* <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowShareModal(false)}>
      Close

    </Button>
 
  </Modal.Footer> */}
        </Modal>
        {view === "4"
          ? news?.map((data) => {
              return (
                <div
                  className="card mb-5 "
                  style={{
                    width: "70rem",
                    // height: "55rem",
                  }}
                >
                  <div className="card-body">
                    <img
                      style={{
                        height: "400px",
                        width: "700px",
                        objectFit: "cover",
                        borderRadius: "20px",
                      }}
                      src={data.image}
                      // className="card-img-top h-100"
                      alt="..."
                    />

                    <br></br>
                    <div
                      style={{ width: "30px", height: "30px" }}
                      className=" mt-5 gap-5 d-flex"
                    >
                      <img src={data.source_logo} alt="" className="w-100" />
                      <h5 className="mt-5">{data.source}</h5>
                    </div>
                    <br></br>
                    <a
                      className="card-text  fw-bold mt-5"
                      href={data.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.title}
                    </a>
                    <h5 className="card-title mt-5">Author : {data.author}</h5>

                    {/* <div className="text-gray-400 fw-semibold mt-5">  

                   
  <a href={data.link} target="_blank" rel="noopener noreferrer">
    {data.link}
  </a>
</div> */}
                    <div className="d-flex mb-3">
                      <p className="card-text">
                        <small>Language : {data.article_language}</small>
                      </p>
                      <p className="card-text" style={{ paddingLeft: "10px" }}>
                        <small>Country : {data.article_country}</small>
                      </p>
                    </div>

                    <h5 className="fw-bold">Bundle Name : {data.bundle}</h5>
                    {/* <p className="card-text">{data.title}</p> */}
                    <p className="card-text">
                      <small>Date Published : {data.posted_on}</small>
                    </p>
                    <p className="card-text">
                      <small>
                        {truncateText(data.summarized_content, 250)}
                      </small>
                    </p>
                    <div className="d-flex align-items-center mb-2 mt-3">
                      {/* <a
                      href="#"
                      className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                    >
                      Negative
                    </a> */}
                      <div className="d-flex align-items-center mb-5">
                        <a
                          href="#"
                          className={`px-4 py-2 me-4 ${
                            data.liked ? "liked" : ""
                          }`}
                          onClick={(event) =>
                            handleLike(data.id, data.liked, event)
                          }
                        >
                          {data.liked ? (
                            <BsFillChatSquareHeartFill
                              style={{ fontSize: "30px", color: "#E76161" }}
                            />
                          ) : (
                            <BsFillChatSquareHeartFill
                              style={{ fontSize: "30px" }}
                            />
                          )}
                        </a>
                        <a
                          href="#"
                          className={`px-4 py-2 me-4 ${
                            data.read_later ? "read-later" : ""
                          }`}
                          onClick={(event) =>
                            handleReadLater(data.id, data.read_later, event)
                          }
                        >
                          {data.read_later ? (
                            <AiFillRead
                              style={{ fontSize: "30px", color: "#E76161" }}
                            />
                          ) : (
                            <AiFillRead
                              style={{ fontSize: "30px", color: "#9DB2BF" }}
                            />
                          )}
                        </a>
                        <a
                          className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                          onClick={() => handleIFrameClick(data.id)}
                        >
                          iFrame
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          onClick={() => handleShare(data.id)}
                        >
                          HTML
                          {/* <IoMdShare size={24} /> */}
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                          onClick={() => handleSaveToPocket(data.link)}
                        >
                          <CiSaveUp2 size={24} />
                          Pocket
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          onClick={() => handleShareArticle(data)}
                        >
                          <IoMdShare size={24} />
                          Share
                        </a>
                        <a
                          href={`/detailnews/${data.id}`}
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        >
                          <KTIcon iconName="eye" className="fs-2" />
                        </a>
                        {data.sentiment ? (
                          data.sentiment === "positive" ? (
                            <a
                              href="#"
                              className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                              style={{ backgroundColor: "green" }}
                            >
                              {data.sentiment}
                            </a>
                          ) : data.sentiment === "negative" ? (
                            <a
                              href="#"
                              className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                            >
                              {data.sentiment}
                            </a>
                          ) : data.sentiment === "neutral" ? (
                            <a
                              href="#"
                              className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                            >
                              {data.sentiment}
                            </a>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {data.id === idframe && (
                    <>
                      <Modal
                        show={showIframeModal}
                        onHide={() => setShowIframeModal(false)}
                        size="sm"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Iframe</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div
                            style={{
                              top: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <div style={{ textAlign: "center" }}>
                                <iframe
                                  srcDoc={`<html><body><p>&lt;iframe src="${data.link}" name="myIFrame" scrolling="no" width="50%" height="30%" style="border: 10px solid #000000;"&gt;&lt;/iframe&gt;</p></body></html>`}
                                  name="myIFrame"
                                  scrolling="no"
                                  // width="100%"
                                  // height="100%"
                                  style={{
                                    width: "300px",
                                    height: "auto",
                                    padding: "12px",
                                  }}
                                ></iframe>
                                <div className="modal-buttons">
                                  {/* <button className="btn btn-sm btn-primary px-4 py-2 me-4"
        onClick={() => handleIFrameClose(false)} 
        >
          Close
        </button> */}
                                  <button
                                    className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                                    onClick={copyToClipboard}
                                  >
                                    <FiCopy /> Copy to Clipboard
                                  </button>
                                </div>
                                {copied && (
                                  <div className="copied-message">Copied!</div>
                                )}
                              </div>
                            </div>

                            {/* )} */}
                            {/* <div style={{ textAlign: "center" }}>
                        <iframe
                          srcDoc={`<html><body><p>&lt;iframe src="${
                            linkiframe[idframe - 1]?.link
                          }" name="myIFrame" scrolling="no" width="50%" height="30%" style="border: 10px solid #000000;"&gt;&lt;/iframe&gt;</p></body></html>`}
                          name="myIFrame"
                          scrolling="no"
                          width="100%"
                          height="80%"
                          style={{
                            border: "2px solid #000000",
                            padding: "20px",
                            background: "white",
                          }}
                        ></iframe>
                        <div className="modal-buttons">
                           <button className="btn btn-sm btn-primary px-4 py-2 me-4" onClick={() => setShowIframeModal(false)}>
              Close
            </button>
                          <button
                            className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                            onClick={copyToClipboard}
                          >
                            <FiCopy /> Copy to Clipboard
                          </button>
                        </div>
                        {copied && (
                          <div className="copied-message">Copied!</div>
                        )}
                      </div> */}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setShowIframeModal(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}{" "}
                </div>
              );
            })
          : ""}
        <Modal
          show={showShareModal}
          onHide={() => setShowShareModal(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {state.shareResult ? (
              <div
                dangerouslySetInnerHTML={{ __html: state.shareResult }}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Body>
          {/* <Button style={{width:"20%"}} variant="secondary" >
      Copy to Clipboard
    </Button> */}
          {/* <button className="btn btn-sm  btn-secondary  px-4 py-2 me-4" >
          <FiCopy /> Copy to Clipboard
        </button> */}
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "20%" }}
              className="btn btn-sm  btn-secondary  px-4 py-2 "
              onClick={copyToClipboardShareModal}
            >
              <FiCopy /> Copy to Clipboard
            </button>
            {copie && <div className="copied-message">Copied!</div>}
          </div>
          {/* <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowShareModal(false)}>
      Close
    </Button>
    
  </Modal.Footer> */}
        </Modal>
        {view === "5" ? (
          <div
            className="card mb-3 "
            style={{ width: "60rem", margin: "auto" }}
          >
            <Carousel>
              {news?.map((data) => (
                <Carousel.Item key={Math.random()}>
                  <div className="card-body">
                    <div
                      className="d-flex gap-5"
                      style={{ width: "30px", height: "30px" }}
                    >
                      <img src={data.source_logo} alt="" className="w-100" />{" "}
                      <h5 className="mt-5">{data.source}</h5>
                    </div>
                    <br></br>
                    <a
                      className="card-text  fw-bold mt-5"
                      href={data.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.title}
                    </a>
                    <h5 className="card-title mt-5">Author : {data.author}</h5>
                    {/* <div className="text-gray-400 fw-semibold mt-5">  

                   
  <a href={data.link} target="_blank" rel="noopener noreferrer">
    {data.link}
  </a>
</div> */}
                    <div className="d-flex mb-3">
                      <p className="card-text">
                        <small>Language : {data.article_language}</small>
                      </p>
                      <p className="card-text" style={{ paddingLeft: "10px" }}>
                        <small>Country : {data.article_country}</small>
                      </p>
                    </div>
                    <h5 className="fw-bold">Bundle Name : {data.bundle}</h5>
                    {/* <p className="card-text">{data.title}</p> */}
                    <p className="card-text">
                      <small>Date Published : {data.posted_on}</small>
                    </p>
                    <p className="card-text">
                      <small>
                        {truncateText(data.summarized_content, 250)}
                      </small>
                    </p>
                    <div style={{ height: "40rem", objectFit: "cover" }}>
                      <img
                        src={data.image}
                        className="card-img-top h-100"
                        alt="..."
                      />
                    </div>{" "}
                    <div className="d-flex align-items-center mb-5">
                      {data.sentiment ? (
                        data.sentiment === "positive" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                            style={{ backgroundColor: "green" }}
                          >
                            {data.sentiment}
                          </a>
                        ) : data.sentiment === "negative" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                          >
                            {data.sentiment}
                          </a>
                        ) : data.sentiment === "neutral" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                          >
                            {data.sentiment}
                          </a>
                        ) : null
                      ) : null}
                      <a
                        href="#"
                        className={`px-4 py-2 me-4 ${
                          data.liked ? "liked" : ""
                        }`}
                        onClick={(event) =>
                          handleLike(data.id, data.liked, event)
                        }
                      >
                        {data.liked ? (
                          <BsFillChatSquareHeartFill
                            style={{
                              fontSize: "30px",
                              color: "#E76161",
                            }}
                          />
                        ) : (
                          <BsFillChatSquareHeartFill
                            style={{ fontSize: "30px" }}
                          />
                        )}
                      </a>
                      <a
                        href="#"
                        className={`px-4 py-2 me-4 ${
                          data.read_later ? "read-later" : ""
                        }`}
                        onClick={(event) =>
                          handleReadLater(data.id, data.read_later, event)
                        }
                      >
                        {data.read_later ? (
                          <AiFillRead
                            style={{
                              fontSize: "30px",
                              color: "#E76161",
                            }}
                          />
                        ) : (
                          <AiFillRead
                            style={{
                              fontSize: "30px",
                              color: "#9DB2BF",
                            }}
                          />
                        )}
                      </a>
                      <a
                        className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                        onClick={() => handleIFrameClick(data.id)}
                      >
                        iFrame
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        onClick={() => handleShare(data.id)}
                      >
                        HTML
                        {/* <IoMdShare size={24} /> */}
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                        onClick={() => handleSaveToPocket(data.link)}
                      >
                        <CiSaveUp2 size={24} />
                        Pocket
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        onClick={() => handleShareArticle(data)}
                      >
                        <IoMdShare size={24} />
                        Share
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                      >
                        <KTIcon iconName="eye" className="fs-2" />
                      </a>
                    </div>
                    {data.id === idframe && (
                      <>
                        <Modal
                          show={showIframeModal}
                          onHide={() => setShowIframeModal(false)}
                          size="sm"
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Iframe</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div
                              style={{
                                top: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <iframe
                                    srcDoc={`<html><body><p>&lt;iframe src="${data.link}" name="myIFrame" scrolling="no" width="50%" height="30%" style="border: 10px solid #000000;"&gt;&lt;/iframe&gt;</p></body></html>`}
                                    name="myIFrame"
                                    scrolling="no"
                                    style={{
                                      width: "300px",
                                      height: "auto",
                                      padding: "12px",
                                    }}
                                  ></iframe>
                                  <div className="modal-buttons">
                                    <button
                                      className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                                      onClick={copyToClipboard}
                                    >
                                      <FiCopy /> Copy to Clipboard
                                    </button>
                                  </div>
                                  {copied && (
                                    <div className="copied-message">
                                      Copied!
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => setShowIframeModal(false)}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        ) : (
          ""
        )}
        <Modal
          show={showShareModal}
          onHide={() => setShowShareModal(false)}
          size="lg" // Set size to large
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Share Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {state.shareResult ? (
              <div
                dangerouslySetInnerHTML={{ __html: state.shareResult }}
                style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
              />
            ) : (
              <p>Loading...</p>
            )}
          </Modal.Body>
          {/* <Button style={{width:"20%"}} variant="secondary" >
  Copy to Clipboard

    </Button> */}{" "}
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "20%" }}
              className="btn btn-sm  btn-secondary  px-4 py-2 "
              onClick={copyToClipboardShareModal}
            >
              <FiCopy /> Copy to Clipboard
            </button>
            {copie && <div className="copied-message">Copied!</div>}
          </div>
          {/* <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowShareModal(false)}>
      Close
    </Button>
   
  </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
};

export default Newstemplatedashboard;

import { useAuth } from "../../../../app/modules/auth";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu, Search } from "../../../partials";
import axios from "axios";
import { useState, useEffect } from "react";
import authHeader from "../../../../app/auth-header";
import { useZoom } from "../../../partials/layout/zoom/ZoomContext";
const AsideToolbar = () => {
  const { currentUser } = useAuth();
  const [imageLoaded, setImageLoaded] = useState(false);
  const { zoomLevel } = useZoom();

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const [userData, setUserData] = useState({
    full_name: "",
    profile_image: "",
    email: "",
  });
  const [isProfileCreated, setIsProfileCreated] = useState(false);

  useEffect(() => {
    fetchUserProfileData();
  }, []);

  const fetchUserProfileData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user_portal/api/get_user_profile`,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("API Response:", response);

        if (response.data.result && response.data.result.length > 0) {
          const profileData = response.data.result[0];
          // console.log("Fetched Profile Data:", profileData);

          // Ensure profile_image is an absolute URL
          const profileImageUrl = toAbsoluteUrl(
            profileData.profile_image || "/media/avatars/300-1.jpg"
          );

          setUserData({
            full_name: profileData.full_name || "",
            email: profileData.email || "",

            profile_image: profileImageUrl,
          });
          // console.log("Full Name:", profileData.full_name);

          setIsProfileCreated(true);

          updateLocalStorage(profileData);

          const storedProfileImage = localStorage.getItem("profileImage");
          if (storedProfileImage) {
            setPreviewImage(storedProfileImage);
          }
        } else {
          setIsProfileCreated(false);
          console.error("Profile data not found.");
        }
      })
      .catch((error) => {
        setIsProfileCreated(false);
        console.error("Error fetching profile data:", error);
      });
  };

  const updateLocalStorage = (profileData) => {};

  const setPreviewImage = (imageData) => {};

  return (
    <>
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
        <div className="aside-user-info flex-row-fluid flex-wrap ms-5 justify-content-center align-items-center">
          <div className="d-flex">
            <div className="flex-grow-1 me-2">
              <a
                href="#"
                className="text-white text-hover-primary fs-6 fw-bold"
              >
                {userData.full_name}
              </a>

              <span className="text-gray-600 fw-bold d-block fs-8 mb-1">
                {userData.full_name}
              </span>
            </div>

            <div className="me-n2">
              <a
                href="#"
                className="btn btn-icon btn-sm btn-active-color-primary mt-n2"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-overflow="false"
              >
                <KTIcon iconName="setting-2" className="text-muted fs-1" />
              </a>

              <HeaderUserMenu />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex  bg-gray flex-end">
        <a
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_add_feed"
          href="/myfeed"
          className="btn btn-sm btn-dark-success  px-4 py-2 px-4 py-2 me-4 "
        >            
        <KTIcon iconName="plus" className=" fs-1" />
          <span className="text-gray-600 " style={{ fontSize: "24px", marginRight:"50px" }}>
            Add Feed
          </span>
        </a>
      </div> */}
      {/*end::User*/}

      {/*begin::Aside search*/}
      <div className="aside-search py-5">
        {/* <?php Theme::getView('partials/search/_inline', array(
        'class' => 'w-100',
        'menu-placement' => 'bottom-start',
        'responsive' => 'false'
    ))?> */}
        {/* <Search /> */}
      </div>

      {/* </div> */}
      {/*end::Aside search*/}
    </>
  );
};

export { AsideToolbar };

import React from 'react';

const Error404 = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <h1>Oops! Page Not Found.</h1>
        <p>We couldn't find the page you're looking for, but we're here to help!</p>
      </div>
    </div>
  );
};

export default Error404;

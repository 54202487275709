import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import authHeader from "../../../auth-header";
import { Modal, Button } from "react-bootstrap";
const DetailNews = () => {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [view, setView] = useState("1");
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);
  const splitIntoParagraphs = (text, linesPerParagraph) => {
    const lines = text.split("\n");
    const paragraphs = [];

    while (lines.length > 0) {
      const currentParagraph = lines.splice(0, linesPerParagraph);
      paragraphs.push(currentParagraph.join("\n"));
    }

    return paragraphs;
  };

  // const paragraphs = splitIntoParagraphs(news.summarized_content, [10, 8, 5, 2]);
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/article/newsbyid/${id}`,
          {
            headers: authHeader(),
          }
        );
        console.log("API Response news:", response.data);

        setNews(response.data.result);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error("Error fetching news:", error);
        setError("Failed to fetch the post. Please try again later.");
        setLoading(false);
      }
    };

    fetchNews();
  }, [id]);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ paddingTop: "120px" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!news) {
    return <p>Post not found.</p>;
  }
  const getTruncatedContent = (content, maxLength) => {
    if (content.length <= maxLength) {
      return content;
    } else {
      return content.substring(0, maxLength) + "...";
    }
  };
  return (
    <div>
      <div className="d-flex " style={{ gap: "150px" }}>
        {/* <div>
          <span className="fs-7 fw-bolder text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
            Select View:
          </span>
          <select
            className="form-select form-select-sm form-select-solid w-100px w-xxl-125px"
            data-control="select2"
            data-placeholder="Latest"
            data-hide-search="true"
            value={view}
            onChange={(e) => setView(e.target.value)}
          >
           <option value="1">List</option>
          <option value="2">Grid</option>
          <option value="3">Magazine</option>
          <option value="4">Card</option>
          <option value="5">Carousel</option>
          </select>
        </div> */}
        {/* <div className="mt-2">
<h5>Share/Embed Options</h5>
        </div> */}
      </div>

      <div className="card mb-5 " style={{ marginTop: "100px" }}>
        <div className="row">
          <div className="col-lg-10">
            <div className="clearfix p-5">
              <div className="d-flex">
                {" "}
                <div className="symbol mt-3">
                  <img
                    src={news.source_img}
                    alt=""
                    style={{ width: "40px", height: "35px" }}
                  />
                </div>
                <h1 className="card-title p-5">
                  <strong className="text-dark">{news.title}</strong>
                </h1>
              </div>

              <div className="">
                <img
                  src={news.post_image}
                  className="card-img h-40 w-50 "
                  alt="..."
                />
              </div>
              <p className="text-dark mt-5 font-bold">
                Posted on :{news.posted_on}
              </p>
              {/* <p className="text-dark mt-5 font-weight-bold">
                Date Created:
{news.posted_on}
                </p> */}
              <div className="mb-5 gap-4  d-flex">
                {" "}
                <p className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                  Sentiment:{news.sentiment}
                </p>
                <p className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                  Topic :{news.article_category}
                </p>
                <p className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                  Article Country :{news.article_country}
                </p>
                <p className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                  Article Language :{news.article_language}
                </p>
              </div>

              <div className="mb-2 pl-5">
                <span>
                  <a href={news.post_url} target="_blank">
                    {news.post_url}
                  </a>
                </span>
                {/* <p
                  className="btn btn-sm  btn-primary   px-4 py-2 me-4 "
                  onClick={toggleModal}
                >
                  View Original Content
                </p> */}
                <div className="mt-5">
                  <p
                    className="btn btn-sm  btn-primary   px-4 py-2 me-4 "
                    onClick={toggleModal}
                  >
                    View Original Content
                  </p>
                </div>
                <p className="fw-bold mt-5">Hot Topics:</p>
                <div className="d-flex flex-wrap mt-2">
                  {news.article_hot_topics.map((topic, index) => (
                    <span
                      key={index}
                      className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-2 mb-2"
                    >
                      {topic}
                    </span>
                  ))}
                </div>

                {news.ai_curated_content ? (
                  <div>
                    <p className="fw-bold mt-3">AI Curated Content:</p>
                    <p className="text-dark mt-2 font-weight-bold">
                      {news.ai_curated_content}
                    </p>
                  </div>
                ) : null}

                <p className="fw-bold">Summary:</p>
                <p className="text-dark mt-2 font-weight-bold">
                  {news.summary}
                </p>
                <div>
                  {/* {paragraphs.map((paragraph, index) => (
          <p key={index} className="text-dark mt-2 font-weight-bold">
            {paragraph}
          </p>
        ))} */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-2">
           
            <div
              className="card text-white mb-5"
              style={{
                maxWidth: "400px",
                backgroundColor: "#FDFEFE",
              }}
            >
              
            </div>
            <div className="symbol symbol-100px mt-5 p-5">
              <img src={news.source_img} alt="" className="w-100" />
            </div>
            <div
              className="d-flex justify-content-end mb-5 "
              style={{
                position: "absolute",
                bottom: 0,
                marginLeft: "1rem",
              }}
            >
              <div className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4">
                Date Created:
                <span>&nbsp;{news.posted_on}</span>
               
              </div>
            </div>
          
          </div> */}
        </div>
      </div>
      {/* Modal component */}
      <Modal show={showModal} onHide={toggleModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Summarized Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display summarized content */}
          <p>{news.summarized_content}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DetailNews;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaArrowRight } from 'react-icons/fa';

const Main = () => {
  const [articleData, setArticleData] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/policy/home_page_articles_for_public_users?section=hero_section`)
      .then(response => {
        console.log("main hero ",response)
        const data = response.data.results.result[0];
        setArticleData(data);
      })
      .catch(error => {
        console.error('Error fetching article data:', error);
      });
  }, []); 
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getTruncatedContent = (content, maxLength) => {
    if (content.length <= maxLength) {
      return content;
    } else {
      return content.substring(0, maxLength) + '...';
    }
  };
  
  
  // const getFirst100Words = (content) => {
  //   const words = content.split(' ');
  //   const slicedContent = words.slice(0, 70).join(' ');
  //   return `${slicedContent}...`;
  // };
  return (
    <div className="container main-container mt-10">
    {articleData && (
      <>
        <div className="image-main">
          <img className='hero-img' src={articleData.image} alt={articleData.title} />
        </div>
        <div className="text-main">
          <h2>{articleData.title}</h2>
          <h5 className='mt-2' style={{color:"#FF6E00"}}>{capitalizeFirstLetter(articleData.article_category)}</h5>   
          <div className='d-flex mb-10 mt-2 gap-2'>
            <h5 className='text-bold fs-6'>{capitalizeFirstLetter(articleData.source)}</h5>
            <h5 className='text-bold fs-6'> | </h5>
            <h5 className='text-bold fs-6'>{articleData.author}</h5>
          </div>    
          <p>{getTruncatedContent(articleData.summarized_content, 250)}</p>
          <a style={{color:"#FF6E00"}} className="fs-7" href={articleData.link} target="_blank" rel="noopener noreferrer">
            Learn More <FaArrowRight />
          </a>
        </div>
      </>
    )}
  </div>
  
  );
};

export default Main;

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, Fragment, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { AdminPrivateRoutes } from './AdminPrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import {App} from "../App"
import config from './config';
import Construction from '../pages/construction/Construction'
import { Login } from '../modules/auth/components/Login'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MFACode } from '../modules/auth/components/MFACode'
import RequestMFA from '../modules/auth/components/mfacode/RequestMFA'
import ResendMFA from '../modules/auth/components/mfacode/ResendMFA'
import PopularTopicsWidget from '../../_metronic/partials/widgets/lists/Populartopicswidget'
import { Registration } from '../modules/auth/components/Registration'
import Verificationsignup from '../modules/auth/components/mfacode/Verificationsignup'
import Price from '../modules/auth/components/Price'
import Success from '../modules/auth/components/Success'
import PricingOne from '../pages/billing/Pricing'
import Publiclandingpage from '../modules/apps/home/components/landingpage/Publiclandingpage'
import About from '../modules/apps/home/components/landingpage/AboutOne'
import Documentation from '../modules/apps/home/components/landingpage/Documentation'
import Privatelanding from '../modules/apps/home/components/privatelandingpage/Privatelanding'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
const is_admin = true
const AppRoutes: FC = () => {
  const { currentUser } = useAuth()

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!currentUser && token) {

      // console.log('Sucessfully Login');
    }
  }, [currentUser]);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!currentUser && token) {
      // console.log('Successfully Login');
    }
  }, [currentUser]);
  return (

    <BrowserRouter basename={config.basename}>
      <Routes>
        <Route element={<App />}>
        <Route path='/pricing' element={<PricingOne/>} />

        <Route path='/about' element={<About/>} />
        <Route path='/documentation' element={<Documentation/>} />

          <Route path='logout' element={<Logout />} />
          {localStorage.getItem('token') ? (
            <Route path='/' element={<Privatelanding />} />
          ) : (
            <Route path='/' element={<Publiclandingpage />} />
          )}

          <Route path='admin/*' element={<AdminPrivateRoutes />} />
          <Route path='/*' element={<PrivateRoutes />} />
          {/* <Route   path='success' element={<Success/>} /> */}


          {currentUser ? (
            <>

              <Route path='dashboard' element={<DashboardWrapper />} />
         
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path="mfa-code" element={<ResendMFA/>} />
              <Route path="verify" element={<Verificationsignup/>} />
              <Route path='*' element={<Navigate to='dashboard' />} />

              <Route path="success" element={<Success />} />

            </>
          )}

        </Route>


      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
// @flow
import * as React from "react";
// import Profile from "./Profile";

// import ExportData from "./ExportData";
import PaymentType from "./PaymentType";
import CardInfo from "./CardInfo";
import BillingHistory from "./BillingHistroy";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import PricingOne from "./Pricing";
import Subscriptionplans from "./Subscriptionplans";

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "Billing Page",
    path: "/billing",
    isSeparator: false,
    isActive: false,
  },
];
export default function Billing() {
  return (
    <div>
      <PageTitle breadcrumbs={wizardsBreadCrumbs}>Billing </PageTitle>
      <div className="row g-5 g-xl-12">
        {/* <div className="col-xl-6  mb-3">
          <div>
            <div className="card pt-9 pb-0">
              <PaymentType />
            </div>
          </div>
        </div>
        <div className="col-xl-6  mb-3">
          <div>
            <div className="card pt-9 pb-0">
              {" "}
              <CardInfo />
            </div>
          </div>
        </div> */}
        <div className="col-xl-12  mb-3">
          <div>
            <div className="card pt-9 pb-0">
              {" "}
              <BillingHistory />
            </div>
          </div>
        </div>
        <div className="col-xl-12 mb-3">
          <div>
            <div className="card pt-9 pb-0">
              {/* <PricingOne /> */}
              <Subscriptionplans/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type Props = {
  className: string;
};

const ListsWidget1: React.FC<Props> = ({ className }) => {
  const List = [
    {
      path: "/user/favourite",
      page: "Favourites",
      icon: "heart",
    },
    {
      path: "/apps/home/read-later",
      page: "Read Later",
      icon: "message-text-2",
    },
   
    {
      path: "/setting",
      page: "Setting",
      icon: "setting",
    },
    {
      path: "/apps/home/bundle",
      page: "Bundles",
      icon: "abstract-26",
    },
  ];

  return (
    <>
    {/* <div className={`card ${className}`}> */}
      {/* begin::Header */}
      {/* <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">Overview</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
           dashboard overview
          </span>
        </h3> */}

        {/* <div className="card-toolbar"> */}
          {/* begin::Menu */}
          {/* <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 /> */}
          {/* end::Menu */}
        {/* </div>
      </div> */}
      {/* end::Header */}

      {/* begin::Body */}
      {/* <div className="card-body pt-5"> */}
        {/* begin::Item */}

        {/* {List.map((data) => {
          return (
            <div className="d-flex align-items-center mb-7"> */}
              {/* begin::Symbol */}
              {/* <div className="symbol symbol-50px me-5">
                <span className="symbol-label bg-light-primary">
                  <KTIcon iconName={data.icon} className="fs-2x text-danger" />
                </span>
              </div> */}
              {/* end::Symbol */}
              {/* begin::Text */}
              {/* <div className="d-flex flex-column">
                <a
                  href={data.path}
                  className="text-dark text-hover-primary fs-6 fw-bold"
                >
                  {data.page}
                </a>
              </div> */}
              {/* end::Text
            </div>
          );
          { */}
          {/* }
        })}
      </div> */}
      {/* end::Body */}
    {/* </div> */}
    </>
  );
};

export { ListsWidget1 };

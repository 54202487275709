import React, { useEffect, useState } from "react";
import axios from "axios";
import { KTIcon } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";
import NewsTemplate, { NewsItem } from "../../../../app/modules/News/components/Newstemplatedashboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BsFillChatSquareHeartFill, BsFillBookFill } from 'react-icons/bs';
import {AiFillRead } from 'react-icons/ai';
import Newstemplatedashboard from "../../../../app/modules/News/components/Newstemplatedashboard";
type Props = {
  className: string;
};

const ListsWidget3: React.FC<Props> = ({ className }) => {
  const [newsData, setNewsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [news, setNews] = useState<NewsItem[]>([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true); 

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/article/latest_news_v1`,
        {
          headers: authHeader() as AxiosRequestHeaders,
        }
      );
      // console.log("latest_news", response.data)
      if (response.data && response.data.result) {
        setNews(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  const handleLike = async (id: number, liked: boolean, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_URL}/article/add_liked_post/${id}/${liked ? 'remove' : 'add'}`;
      const response = await axios.post(url, null, {
        headers: authHeader() as AxiosRequestHeaders,
      });
      // console.log("favrt Later", response.data)

      if (response.status === 200) {
        setNews((prevNews) =>
          prevNews.map((item) => (item.id === id ? { ...item, liked: !liked } : item))
        );

        if (liked) {
          toast.success(response.data.message);
        } else {
          toast.success(response.data.message);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred');
    }
  };
  const handleReadLater = async (id: number, read_later
    : boolean, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    try {
      const url = `${process.env.REACT_APP_API_URL}/article/add_read_later_post/${id}/${read_later
        ? 'remove' : 'add'}`;
      const response = await axios.post(url, null, {
        headers: authHeader() as AxiosRequestHeaders,
      });
      // console.log("favrt Later", response.data)

      if (response.status === 200) {
        setNews((prevNews) =>
          prevNews.map((item) => (item.id === id ? { ...item, read_later
            : !read_later
          } : item))
        );

        if (read_later
          ) {
          // toast.success('Post unliked');
          toast.error(response.data.message);

        } else {
          // toast.success('Post liked');
          toast.success(response.data.message);

        }
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred');
    }
  };
 

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">Trending News Topics </h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
               <Newstemplatedashboard
            className="mb-6"
            news={news}
            handleLike={handleLike}
            handleReadLater={handleReadLater}
          />


          
        </>
      )}
    </div>
  );
};

export { ListsWidget3 };

import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import countryList from "country-list";
import isoLangs from "iso-639-1";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ReactTimezoneSelect from "react-timezone-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfilGet from "./ProfileGet";
import time_zone from "./time_zones"
import { useColorBlind } from '../../../_metronic/partials/layout/colorblind/ColorBlindContext';

const Profile = () => {

  const countryOptions = countryList.getData().map((country) => (
    <option key={country.code} value={country.name}>
      {country.name}
    </option>
  ));

  const languageOptions = [
    "Select Language",
    "English",
    "Spanish",
    "French",
    "German",
    "Japanese",
    "Chinese",
    "Arabic",
    "Russian",
    "Italian",
    "Portuguese",
    "Dutch",
    "Korean",
    "Turkish",
    "Swedish",
    "Greek",
  ];

  const initialFormData = {
    full_name: "",
    profile_image: null,
    company: "",
    contact_phone: "",
    country: "",
    language: "",
    time_zone: "",
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "red" : "blue",
      padding: 20,
      backgroundColor: "black"
    }),
  };
  const [formData, setFormData] = useState(initialFormData);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isProfileCreated, setIsProfileCreated] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  // const [isProfileCreating, setIsProfileCreating] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isUserNameValid, setIsUserNameValid] = useState(true);

  const updateLocalStorage = (data) => {
    localStorage.setItem("userProfileData", JSON.stringify(data));
  };
//  const { simulatedType, setSimulatedType, applyColorBlindness } = useColorBlind();
//   React.useEffect(() => {
//     applyColorBlindness(simulatedType);
//   }, [simulatedType, applyColorBlindness]);
  useEffect(() => {
    const fetchUserProfileData = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/admin/get_user_profile`, {
          headers: authHeader(),
        })
        .then((response) => {
          const profileData = response.data.result[0];
          // console.log("Fetched Profile Data:", profileData);

          setFormData({
            full_name: profileData.full_name || "",
            company: profileData.company || "",
            contact_phone: profileData.contact_phone || "",
            country: profileData.country || "",
            language: profileData.language || "",
            time_zone: profileData.time_zone || "",
            profile_image: profileData.profile_image || "",
          });
          setIsProfileCreated(true);

          updateLocalStorage(profileData);

          const storedProfileImage = localStorage.getItem("profileImage");
          if (storedProfileImage) {
            setPreviewImage(storedProfileImage);
          }
        })
        .catch((error) => {
          setIsProfileCreated(false);
          console.error("Error fetching profile data:", error);
        });
    };

    const storedProfileData = JSON.parse(localStorage.getItem("userProfileData"));

    if (storedProfileData) {
      setFormData(storedProfileData);
      setIsProfileCreated(true);

      const storedProfileImage = localStorage.getItem("profileImage");
      if (storedProfileImage) {
        setPreviewImage(storedProfileImage);
      }
    } else {
      fetchUserProfileData();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setHasChanges(true);
  };

  const showNoChangesToast = () => {
    toast.warn('No changes made.', {
      position: 'top-right',
      autoClose: 3000,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isProfileCreated) {
      if (!formData.full_name) {
        setIsUserNameValid(false);
        return;
      } else {
        setIsUserNameValid(true);
      }
    } else {
      setIsUserNameValid(true);
      setIsProfileCreated(false);
    }

    if (!hasChanges) {
      showNoChangesToast();
      return;
    }

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/user_portal/api/create_or_update_profile`, formDataToSubmit, {
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log("API Response after submission:", response.data);

        if (response.status === 200) {
          setSuccessMessage(response.data.message);
          setErrorMessage("");
          setIsProfileCreated(true);

          updateLocalStorage(formData);

          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
          window.location.reload()
        } else {
          setErrorMessage("Failed to update profile. Please try again.");
          setSuccessMessage("");
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        setErrorMessage("An error occurred while updating the profile.");
        setSuccessMessage("");
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        profile_image: file,
      }));

      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewImage(e.target.result);
        localStorage.setItem("profileImage", e.target.result);
      };
      reader.readAsDataURL(file);
    }
    setHasChanges(true);
  };

  return (
    <div className="container">
      <div className="d-flex mb-3 text-center justify-content-center align-items-center">
        <div className="me-7 mb-4">
          <h2>{formData.full_name}</h2>
        </div>
      </div>
      <h5>{isProfileCreated ? "Edit Profile" : "Create Profile"}</h5>
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3 ">
            <label htmlFor="full_name" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="full_name"
              name="full_name"
              value={formData.full_name}
              onChange={handleInputChange}
              // style={{backgroundColor:"white"}}
            />
            {!isUserNameValid && (
              <div className="alert alert-danger">Please provide a valid username.</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="company" className="form-label">
              Company
            </label>
            <input
              type="text"
              className="form-control"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleInputChange}
              // style={{backgroundColor:"white"}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="contact_phone" className="form-label">
              Contact Phone
            </label>
            <input
              // type="number"
              className="form-control"
              id="contact_phone"
              name="contact_phone"
              value={formData.contact_phone}
              onChange={handleInputChange}
           
              // style={{backgroundColor:"white"}}
            />
            {/* <PhoneInput
              className="form-control"
              id="contact_phone"
              name="contact_phone"
              placeholder="Enter phone number"
              value={formData.contact_phone}
              onChange={(value) => setFormData({ ...formData, contact_phone: value })}
            /> */}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="country" className="form-label">
              Country
            </label>
            <select
              className="form-control"
              // style={{backgroundColor:"white"}}
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            >
              <option value="">Select Country</option>
              {countryOptions}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="language" className="form-label">
              Language
            </label>
            <select
              className="form-control"
              id="language"
              name="language"
              value={formData.language}
              onChange={handleInputChange}
              // style={{backgroundColor:"white"}}
            >
              {languageOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="language" className="form-label">
            Time Zone
            </label>
            <select
              className="form-control"
              id="time_zone"
              name="time_zone"
              value={formData.time_zone}
              onChange={handleInputChange}
              // style={{backgroundColor:"white"}}
            >
              {time_zone.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-md-6 mb-3">
  <label htmlFor="time_zone" className="form-label">
    Time Zone
  </label>
  <ReactTimezoneSelect     
    className="text-black   flex "
    id="time_zone"
    name="time_zone"
    style={{ backgroundColor:"black"}}
    value={formData.time_zone || ''}
    selectStyles={customStyles}
    onChange={(selectedTimezone) => {
      setFormData({
        ...formData,
        time_zone: selectedTimezone.value,
      });
    }}
  />
</div> */}
<ProfilGet/>
        </div>
        <div className="mb-3 d-flex justify-content-center mt-5">
          {isProfileCreated ? (
            <button type="submit" className="btn btn-primary">
               Update
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">
              Create
            </button>
          )}
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Profile;

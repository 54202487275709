import { useIntl } from "react-intl";
import { PageTitle } from "../core";
import FeedResultWidget from "../../partials/widgets/tables/FeedResultWidget";
import {
  ListsWidget1,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
} from "../../partials/widgets";
import DashboardTable from "./Tables/DashboardTable";
import DashboardResults from "./Tables/DashboardResults";


const DashboardPage = () => (
  <>
    {/* begin::Row */}
    <div className="row g-5 g-xl-8 mt-2">
    </div>

    <div className="row row-flex">

      {/* <div className="col-xl-3 p-3">
        <ListsWidget1 className="card-xl-stretch mb-xl-8 mb-5" />
        <ListsWidget5 className="card-xl-stretch" />
      </div> */}
      <DashboardTable/>
 
      <div className="col-xl-6 p-3">
        {/* <ListsWidget4 className="card-xl-stretch" /> */}
      </div>
   
      <div className="col-xl-12 ">
{/* 
<DashboardResults className="card-xl-stretch  mb-xl-8"/> */}
        {/* <FeedResultWidget className="card-xl-stretch  mb-xl-8" /> */}
      </div>
      
      
      {/* <ListsWidget3 className="card-xl-stretch" /> */}
    </div>

    <div className="row gy-5 g-xl-8">
     
      

    </div>

  </>
);

const AdminDashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {/* {intl.formatMessage({ id: "MENU.DASHBOARD" })} */}
        Admin Dashboard
      </PageTitle>
      <DashboardPage />
    </>
  );
}; 

export { AdminDashboardWrapper };

import React, { useState, useEffect } from "react";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import PricingData from "../billing/PricingData.json";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authHeader from "../../auth-header";
import { AxiosRequestHeaders } from "axios";
import premium from "../billing/pricing/business.webp";
import free from "./free.webp";
import pro from "../billing/pricing/pro.webp";
import basic from "../billing/pricing/basic.webp";
import HeaderOne from "../../modules/apps/home/components/landingpage/Header/HeaderOne";
import FooterOne from "../../modules/apps/home/components/landingpage/FooterOne";
const getPriceingData = PricingData;

const billed = [
  {
    id: 1,
    label: "Monthly Plan",
  },
  {
    id: 2,
    label: "Yearly Plan",
  },
  {
    id: 3,
    label: "Bi-Annual Plan",
  },
];
const planImages = {
  "Free  Plan": free,
  Basic: basic,
  Premium: premium,
  Pro: pro,
};

const PricingOne = () => {
  const navigate = useNavigate();
  const [isActive, setActive] = useState(billed[2].label.toLowerCase());
  const [loading, setLoading] = useState(true);
  const [planData, setPlanData] = useState([]);
  useEffect(() => {
    setActive(billed[0].label.toLowerCase());

    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_subscription_plans`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          setPlanData(res.data.result);
          setLoading(false);
        }
      });
  }, []);

  const handleChange = (label) => {
    setActive(label.toLowerCase());
  };

  const handleSubmit = async (id, title) => {
    console.log("Title", title);
    console.log("id", id);

    console.log("Handle submit clicked");

    const token = window.localStorage.getItem("token");
    // if (title && title.includes('Free')) {

    //   if (token) {
    //     navigate('/user/dashboard');
    //   } else {
    //     navigate('/auth/login');
    //   }
    if (title && title.includes("Free")) {
      if (token) {
        try {
          const formData = new FormData();
          formData.append("id", id);
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/policy/create_checkout_session`,
            formData,
            {
              headers: authHeader(),
            }
          );
          console.log("Response:", res); // Log the response to console
          if (res.data.status === 201) {
            console.log("pricing", res.data);
            toast.success(res.data.message);
            navigate("/auth/success");
            // window.location.href = res.data.url;
          } else if (res.data.status === 400) {
            toast.error(res.data.message);

            console.error("CANNOT PROCEED PAYMENT");
          }
        } catch (error) {
          console.error("Error fetching plans:", error);
        }
      } else {
        navigate("/auth/login");
      }
    } else {
      if (token) {
        try {
          const formData = new FormData();
          formData.append("id", id);
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/policy/create_checkout_session`,
            formData,
            {
              headers: authHeader(),
            }
          );
          if (res.data.status === 200) {
            console.log("pricing", res.data);
            toast.success(res.data.message);
            window.location.href = res.data.url;
          } else if (res.data.status === 400) {
            toast.error(res.data.message);

            console.error("CANNOT PROCEED PAYMENT");
          }
        } catch (error) {
          console.error("Error fetching plans:", error);
        }
      } else {
        navigate("/auth/login");
      }
    }
  };

  return (
    <>
      <HeaderOne />
      <div
        className="pricing-billing-duration text-center "
        style={{ marginTop: "100px" }}
      >
        <ul className="list-inline">
          {billed.map((data) => (
            <li className="list-inline-item" key={data.id}>
              <button
                onClick={() => handleChange(data.label)}
                style={{ fontSize: "18px" }}
                className={`btn btn-outline-info ${
                  data.label.toLowerCase() === isActive ? "active" : ""
                }`}
              >
                {data.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="pricing-container container">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 justify-content-center">
          {/* Adding the Free Plan card */}
          {loading ? (
            <></>
          ) : (
            <>
              {planData
                ?.filter((data) => data.plan_type.toLowerCase() === "free")
                .map((data, index) => (
                  <div className="col" key={index}>
                    <div className="pricing-table p-5">
                      <div className="pricing-header">
                        <div className="d-flex gap-2">
                          {" "}
                          <img
                            src={planImages[data.title]}
                            style={{ width: "30px", height: "30px" }}
                            alt={data.title}
                            className="plan-image mt-2"
                          />
                          <h5 className="title">{data.title}</h5>
                        </div>
                        {/* <span className="subtitle">{data.description}</span> */}
                        <div className="price-wrap">
                          <div
                            className="yearly-pricing"
                            style={{ marginTop: "-30px" }}
                          >
                            <span
                              className="amount"
                              style={{ fontSize: "42px" }}
                            >
                              $
                            </span>

                            <span className="amount">{data.price}</span>
                          </div>
                        </div>
                        <div>
                          <button
                            className="pricing-btn axil-btn"
                            onClick={() => handleSubmit(data.id, data.title)}
                          >
                            Get Started
                          </button>
                        </div>
                      </div>
                      <div className="pricing-body">
                        <ul className="list-unstyled">
                          <li>
                            <FaCheck /> Number of user generated RSS Feeds:{" "}
                            {data.number_of_user_generated_feeds}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of feeds: {data.number_of_feeds}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of Bundles:{" "}
                            {data.number_of_bundles}{" "}
                          </li>
                          <li>
                            <FaCheck /> Filter by whitelisted words:{" "}
                            {data.filter_by_whitelisted_keywords}{" "}
                          </li>
                          <li>
                            <FaCheck /> Filter by blacklisted words:{" "}
                            {data.filter_by_blacklisted_keywords}{" "}
                          </li>
                          <li>
                            <FaCheck /> Manual bundle update:{" "}
                            {data.manual_bundle_update}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of sources per bundle:{" "}
                            {data.number_of_sources_per_bundle}{" "}
                          </li>
                          <li>
                            <FaCheck /> Feed result limit per feed:{" "}
                            {data.feed_result_limit_per_feed}
                          </li>
                          <li>
                            <FaCheck /> Total searches per month:{" "}
                            {data.total_searches_per_month}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of sources per bundle:{" "}
                            {data.cost_per_search}{" "}
                          </li>
                          <li>
                            <FaCheck /> Cost per search: {data.share_via_link}{" "}
                          </li>
                          <li>
                            <FaCheck /> Language selection:{" "}
                            {data.language_selection}{" "}
                          </li>
                          <li>
                            <FaCheck /> Country selection:{" "}
                            {data.country_selection}{" "}
                          </li>
                          <li>
                            <FaCheck /> Timeframe selection:{" "}
                            {data.timeframe_selection}{" "}
                          </li>
                          <li>
                            <FaCheck /> Public newsfeed dashboard:{" "}
                            {data.public_newsfeed_dashboard}{" "}
                          </li>
                          <li>
                            <FaCheck /> Hide sponsored_ads:{" "}
                            {data.hide_sponsored_ads}{" "}
                          </li>
                          <li>
                            <FaCheck /> Curated ai news: {data.curated_ai_news}{" "}
                          </li>
                          <li>
                            <FaCheck /> Analytics addon: {data.analytics_addon}{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              {planData
                ?.filter((data) => data.plan_type.toLowerCase() === isActive)
                .map((data, index) => (
                  <div className="col" key={index}>
                    <div className="pricing-table p-5 ">
                      <div className="pricing-header">
                        <div className="d-flex gap-2">
                          {" "}
                          <img
                            src={planImages[data.title]}
                            style={{ width: "27px", height: "27px" }}
                            alt={data.title}
                            className="plan-image mt-2"
                          />
                          <h5 className="title">{data.title}</h5>
                        </div>

                        {/* <span className="subtitle">{data.description}</span> */}
                        <div className="price-wrap">
                          <div
                            className="yearly-pricing"
                            style={{ marginTop: "-30px" }}
                          >
                            <span
                              className="amount"
                              style={{ fontSize: "42px" }}
                            >
                              $
                            </span>

                            <span className="amount">{data.price}</span>
                          </div>
                        </div>
                        <div>
                          <button
                            className="pricing-btn axil-btn"
                            onClick={() => handleSubmit(data.id)}
                          >
                            Get Started
                          </button>
                        </div>
                      </div>
                      <div className="pricing-body">
                        <ul className="list-unstyled">
                          <li>
                            <FaCheck /> Number of user generated RSS Feeds:{" "}
                            {data.number_of_user_generated_feeds}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of feeds: {data.number_of_feeds}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of Bundles:{" "}
                            {data.number_of_bundles}{" "}
                          </li>
                          <li>
                            <FaCheck /> Filter by whitelisted words:{" "}
                            {data.filter_by_whitelisted_keywords}{" "}
                          </li>
                          <li>
                            <FaCheck /> Filter by blacklisted words:{" "}
                            {data.filter_by_blacklisted_keywords}{" "}
                          </li>
                          <li>
                            <FaCheck /> Manual bundle update:{" "}
                            {data.manual_bundle_update}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of sources per bundle:{" "}
                            {data.number_of_sources_per_bundle}{" "}
                          </li>
                          <li>
                            <FaCheck /> Feed result limit per feed:{" "}
                            {data.feed_result_limit_per_feed}
                          </li>
                          <li>
                            <FaCheck /> Total searches per month:{" "}
                            {data.total_searches_per_month}{" "}
                          </li>
                          <li>
                            <FaCheck /> Number of sources per bundle:{" "}
                            {data.cost_per_search}{" "}
                          </li>
                          <li>
                            <FaCheck /> Cost per search: {data.share_via_link}{" "}
                          </li>
                          <li>
                            <FaCheck /> Language selection:{" "}
                            {data.language_selection}{" "}
                          </li>
                          <li>
                            <FaCheck /> Country selection:{" "}
                            {data.country_selection}{" "}
                          </li>
                          <li>
                            <FaCheck /> Timeframe selection:{" "}
                            {data.timeframe_selection}{" "}
                          </li>
                          <li>
                            <FaCheck /> Public newsfeed dashboard:{" "}
                            {data.public_newsfeed_dashboard}{" "}
                          </li>
                          <li>
                            <FaCheck /> Hide sponsored_ads:{" "}
                            {data.hide_sponsored_ads}{" "}
                          </li>
                          <li>
                            <FaCheck /> Curated ai news: {data.curated_ai_news}{" "}
                          </li>
                          <li>
                            <FaCheck /> Analytics addon: {data.analytics_addon}{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default PricingOne;

import React, { useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import CreateBundle from "./CreateBundle";
import axios from "axios";
import authHeader from "../../../../../../auth-header";
import EditBundle from "../EditBundle";
import KeywordBundle from "./KeywordBundle";
import BlacklistBundle from "./BlacklistBundle";
import { RxCross1 } from "react-icons/rx";
import Editsources from "../Editsources";
import { toast } from 'react-toastify';
import { useZoom } from "../../../../../../../_metronic/partials/layout/zoom/ZoomContext";

const Sourceschedule = () => {
  const [bundles, setBundles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bundleSources, setBundleSources] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(null);

  const [reloadComponent, setReloadComponent] = useState(false); // New state variable
  const { zoomLevel } = useZoom();

  const toggleTask = async (bundle) => {
    if (bundle) {
      try {
        const updatedBundle = { ...bundle };
        updatedBundle.enable_disable_task = bundle.enable_disable_task ? 'disable' : 'enable';

        const dataToSend = new FormData();
        dataToSend.append("enable_disable_task", updatedBundle.enable_disable_task);
        dataToSend.append("interval_type", updatedBundle.interval_type);

        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/policy/edit_bundle_source_periodic_task/${updatedBundle.periodic_task_id}`,
          dataToSend,
          {
            headers: {
              ...authHeader(),
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          if (data.status === 200) {
            const updatedBundles = bundles.map((b) =>
              b.periodic_task_id === bundle.periodic_task_id ? updatedBundle : b
            );
            setBundles(updatedBundles);

            if (data.message === 'Periodic Task For Source Disabled') {
              setIsEditModalOpen(false);
            }

            toast.success(data.message);

            // Trigger a reload of the component
            setReloadComponent((prev) => !prev);
          } else {
            console.error("API request successful, but data.status is not 200:", data.status);
          }
        } else {
          console.error("API request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error toggling task:", error);
      }
    }
  };

  const fetchBundles = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/policy/get_bundle_source_periodic_task`,
        {
          headers: authHeader(),
        }
      );

      if (response.status === 200) {
        const data = response.data;
        if (data.status === 200) {
          const bundlesData = data.result;
          setBundles(bundlesData);
        } else {
          console.error("API request successful, but data.status is not 200:", data.status);
        }
      } else if (response.status === 204) {
        // Handle 204 status if needed
      } else {
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching bundles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBundles();
  }, [reloadComponent]); 

  const openEditModal = (bundle) => {
    setSelectedBundle(bundle);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedBundle(null);
    setIsEditModalOpen(false);
  };


 


 


  return (
    <div 
    className="card" 
    // style={{ transform: `scale(${zoomLevel})` }}
    >
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Sources Schedule</span>
          {/* <span className="text-muted mt-1 fw-semibold fs-7">
            Bundle You Have Added
          </span> */}
        </h3>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a keyword"
        ></div>
      </div>
      <div className="card-body py-3">
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="table-responsive">
            <div className="d-flex justify-content-end gap-4">
            
            
            </div>
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted">
                  <th className="w-25px"></th>
                  <th className="min-w-150px text-dark fw-bold fs-6">
                  Bundle
                  </th>
                  <th className="min-w-150px text-dark fw-bold fs-6">
                  Keyword
                  </th>
                  <th className="min-w-150px text-dark fw-bold fs-6">
                  Periodic Task Schedule
                  </th>
                  {/* <th className="min-w-150px text-dark fw-bold fs-6">
                  Crontab Schedule
                  </th> */}

                  {/* <th className="min-w-150px text-dark fw-bold fs-6">
                    Sources Name
                  </th>
                   */}
                  <th className="min-w-150px text-end text-dark fw-bold fs-6">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {bundles.map((bundle, index) => (
                  <tr key={index}>
                    <td></td>
                    <td>{bundle.bundle_title}</td>
                    <td>{bundle.keyword}</td>
                    <td>{bundle.interval_type}</td>
                    {/* <td>{bundle.source_name}</td> */}
                 
               
           
                    <td>
                      <div className="d-flex justify-content-end flex-shrink-0 mb-4">
                      <button
          type="button"
          className="btn mt-3"
          style={{
            width: "50px", 
            height: "20px", 
            backgroundColor: bundle.enable_disable_task ? "#4caf50" : "#ccc", 
            borderRadius: "15px",
            position: "relative",
            cursor: "pointer",
          }}
          onClick={() => toggleTask(bundle)}
        >
          <span
            style={{
              display: "block",
              width: "17px", 
              height: "15px", 
              backgroundColor: "#fff", 
              borderRadius: "50%",
              position: "absolute",
             marginTop:"-7px",
              left: bundle.enable_disable_task ? "30px" : "0", 
              transition: "left 0.3s ease", 
            }}
          ></span>
        </button>
                        <a
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          href="#"
                          onClick={() => openEditModal(bundle)}
                        >
                          <KTIcon iconName="pencil" className="fs-3" />
                        </a>
                       
                      
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          
          </div>
        )}
      </div>
      
      {isEditModalOpen && (
        <Editsources
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          initialBundle={selectedBundle}
          initialSources={selectedBundle ? selectedBundle.sources : []}
          bundleSources={bundleSources}
        />
      )}

    
    </div>
  );
};

export default Sourceschedule;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

function Verifyemail() {
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    const getTokenFromURL = () => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('token');
    };

    const token = getTokenFromURL();

    if (token) {
      fetch(`${process.env.REACT_APP_API_URL}/user_portal/api/verify_email?token=${token}`)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            setVerificationStatus('Email Verification Failed');
            throw new Error('Email Verification Failed');
          }
        })
        .then((data) => {
          // Show a toast with the success message
          toast.success(data.message);

          setVerificationStatus('Email Verified');
        })
        .catch((error) => {
          // Show a toast with the error message
          toast.error(error.message);
          console.error(error);
        });
    }
  }, []);

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        {verificationStatus ? (
          <div style={centerContent}>
            {verificationStatus === 'Email Verified' ? (
              <div className='mb-4'>
                <FaCheckCircle style={iconStyle} />
                <h1>Email Verification Successful</h1>
                <p>Your email has been successfully verified, and your email address has been updated.</p>
              </div>
            ) : (
              <div>
                <FaTimesCircle style={iconStyle} />
                <h1>Email Verification Failed</h1>
                <p>Sorry, we couldn't verify your email.</p>
              </div>
            )}
          </div>
        ) : (
          <div style={centerContent}>
            <p>Verifying email...</p>
          </div>
        )}
      </div>
    </div>
  );
}

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const boxStyle = {
  border: '2px solid #ccc',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  maxWidth: '400px',
  backgroundColor: '#f9f9f9',
};

const centerContent = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const iconStyle = {
  fontSize: '3em',
  color: '#34a853',
};

export default Verifyemail;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { FaTimes } from "react-icons/fa";
import authHeader from "../../../../../../auth-header";
interface Country {
  feed_id: string;
  id: string;
  feed_title: string;
}

interface InitialBundle {
  bundle_title: string;
  bundle_is_enabled: boolean;
  feeds: Country[];
}

interface selectedFeeds {}
interface EditFeedProps {
  onCountryChange: (selectedFeeds: string[]) => void;
  initialBundle: InitialBundle;
  selectedFeeds: selectedFeeds;
}

const EditFeed = (props: any) => {
  // console.log(initialBundle);
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<Country[]>([]);
  console.log(countries);
  const [countrystate, updatecountrystate] = useState<any>("");
  const [editfeeds, updateeditfeeds] = useState<any>([]);
  console.log(editfeeds);
  const [feedTitlesshow, updatefeedtitle] = useState<any>([]);
  console.log(feedTitlesshow);
  console.log(props.selectedFeeds);
  const initialSelectedCountries = props.initialBundle
    ? props.initialBundle.feeds.map((feed: any) => feed.id)
    : [];
  // const [selectedFeedsdata, setSelectedFeeds] = useState<any[]>([]);

  // console.log(selectedFeedsdata);
  const [feedTitles, setFeedTitles] = useState<string[]>([]);

  const [isSelectionManual, setIsSelectionManual] = useState<boolean>(false);
  //   console.log("Selected Countries:", selectedCountries);
  //   console.log("initialBundle type:", typeof initialBundle);
  //   console.log("Initial Bundle in EditBundleV1:", initialBundle);
  console.log("Initial Selected Countries:", initialSelectedCountries);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/policy/get_enabled_feeds`,
          {
            headers: authHeader() as { Authorization: string },
          }
        );
        console.log("feedsget ", response.data);

        if (response.data && Array.isArray(response.data.result)) {
          const fetchedCountries = response.data.result.map((bundle: any) => ({
            feed_id: bundle.feed_id,
            feed_title: bundle.feed_title,
          }));

          setCountries(fetchedCountries);
        } else {
          console.error("Invalid response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, []);
  useEffect(() => {
    if (props.initialBundle && props.initialBundle.feeds) {
      console.log(props.initialBundle);
      updatefeedtitle([
        ...props.initialBundle.feeds.map((feed: any) => feed.feed_title),
      ]);
      // setSelectedFeeds(initialBundle.feeds.map((feed) => feed.id));
      updateeditfeeds([...props.initialBundle.feeds]);
    }
  }, [props.initialBundle]);

  useEffect(() => {
    if (props.initialBundle && props.initialBundle.feeds) {
      const feedTitles = props.initialBundle.feeds.map(
        (feed: any) => feed.feed_title
      );
      setFeedTitles(feedTitles);
    }
  }, [props.initialBundle]);

  // useEffect(() => {
  //   if (initialBundle && initialBundle.feeds) {
  //     setSelectedFeeds(initialBundle.feeds.map((feed) => feed.id));
  //   }
  // }, [initialBundle]);

  const debouncedHandleCountryChange = debounce(
    (event: React.ChangeEvent<HTMLSelectElement>) => handleCountryChange(event),
    300
  );

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const dropdown = document.querySelector("#country option:checked");
    // const selectedOption = dropdown;
    console.log(dropdown);
    const value = event.target.options[event.target.selectedIndex];
    updatecountrystate(event.target.value);
    console.log(value.text);
    // alert(value.text);
    const trimvalue = value.text.trim();
    // alert(trimvalue);
    // if (value === "") {
    //   return;
    // }
    if (event.target.value === "") {
    } else {
      const isSelected = feedTitlesshow.includes(trimvalue);
      // alert(isSelected);
      if (isSelected) {
      } else {
        const Eventvalue = parseInt(event.target.value);
        console.log(Eventvalue);
        props.selectedFeeds.push(Eventvalue);

        props.setSelectedFeeds([...props.selectedFeeds]);
        updatefeedtitle([...feedTitlesshow, trimvalue]);
        updateeditfeeds([
          ...editfeeds,
          { id: Eventvalue, feed_title: trimvalue },
        ]);
      }
    }

    // let updatedSelection;
    // if (value === "all") {
    //   updatedSelection = isSelectionManual
    //     ? []
    //     : countries.map((country) => country.feed_id);
    // } else {
    //   let data = parseInt(value);
    //   console.log(typeof value);
    //   const isSelected = selectedFeeds.includes(value);
    //   alert(isSelected);
    //   if (isSelected) {
    //     updatedSelection = selectedFeeds.filter((code) => code !== value);
    //   } else {
    //     updatedSelection = [...selectedFeeds, value];
    //   }
    // }

    // setSelectedFeeds(updatedSelection);
    // setIsSelectionManual(true);
    // onCountryChange(updatedSelection);

    // const updatedFeedTitles = updatedSelection.map(
    //   (id) =>
    //     countries.find((country) => country.feed_id === id)?.feed_title || ""
    // );
    // setFeedTitles(updatedFeedTitles);
  };

  const handleRemoveCountry = (countryCode: string) => {
    const updatedSelection = editfeeds.filter(
      (code: any) => code.feed_title !== countryCode
    );
    // alert(countryCode);
    console.log(updatedSelection);
    updatefeedtitle([...updatedSelection.map((data: any) => data.feed_title)]);
    // setSelectedFeeds(updatedSelection);
    updateeditfeeds(updatedSelection);
    props.setSelectedFeeds([...updatedSelection.map((data: any) => data.id)]);
    // onCountryChange(updatedSelection);
  };

  return (
    <div className="mb-5">
      <label className="form-label">Feeds</label>
      <select
        className="form-select"
        id="country"
        value={countrystate}
        onChange={handleCountryChange}
      >
        <option key="choose-option" value="">
          Choose an option
        </option>
        {countries.map((country, index) => (
          <option key={`${country.feed_id}-${index}`} value={country.feed_id}>
            {country.feed_title}
          </option>
        ))}
      </select>

      {editfeeds.map((code: any, index: any) => (
        <div
          key={`${code}-${index}`}
          className="badge badge-info"
          style={{ marginRight: "10px", marginBottom: "5px" }}
        >
          <span
            className="badge badge-info"
            style={{ fontSize: "12px", cursor: "pointer" }}
          >
            {code.feed_title}
            <a
              style={{ color: "black", marginLeft: "5px" }}
              onClick={() => handleRemoveCountry(code.feed_title)}
            >
              <FaTimes />
            </a>
          </span>
        </div>
      ))}
    </div>
  );
};

export default EditFeed;

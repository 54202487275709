import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios'; 
import authHeader from '../../../auth-header';
import { toast } from 'react-toastify'; 

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setHasErrors(false);
    
      const data = {
        email: values.email,
      };
    
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user_portal/api/change_password`,
          data
        );
      
        console.log('Response data:', response.data);
      
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: 'top-right',
            autoClose: 5000,
          });
          setHasErrors(true);
        } else if (response.data.status === 204) {
          toast.error(response.data.message, {
            position: 'top-right',
            autoClose: 5000,
          });
          setHasErrors(true);
        } else {
          toast.success("Success: Password reset email sent.", {
            position: 'top-right',
            autoClose: 5000,
          });
          setHasErrors(false);
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.", {
          position: 'top-right',
          autoClose: 5000,
        });
        setHasErrors(true);
      } finally {
        setLoading(false);
      }
      
    },
    
  });


  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
      </div>

     

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            { 'is-valid': formik.touched.email && !formik.errors.email }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={loading}
          >
            Cancel
          </button>
        </Link>
      </div>
    </form>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark custom-navbar" style={{ height: "70px" }}>
        <div className="container-fluid ">
     
            <Link  to="/">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/wire_logo.png")}
                style={{ height: "70px" ,width:"150px" }}
                className='navbar-brand'
              />
            </Link>
        
          <button
            className="navbar-toggler bg-dark"
            type="button"
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
            // style={{marginTop:"-40px"}}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        
          <div className={`bg-dark p-2 collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto gap-5 d-flex">
              <li className="nav-item">
                <Link className="nav-link text-white fw-bold" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white fw-bold" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white fw-bold" to="/documentation">
                  Documentation
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white fw-bold" to="/auth/login">
                  Login
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link text-white fw-bold" to="/auth/login">
                  Login
                </Link>
              </li> */}
              <button className='nav-item'  style={{backgroundColor:"#FF6E00" , border:"none" ,borderRadius:"5px"}}>
              {/* <li className="nav-item"> */}
                <Link className="nav-link text-white fw-bold" to="/pricing">
                Subscribe
                </Link>
              {/* </li> */}
              </button>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Nav;



//     <nav className="mainmenu-nav">
//       <ul className="mainmenu d-flex justify-content-end" style={{marginTop:"-15px"}}>
       
        
// <li>
//           <Link className="nav-link text-sm text-white" to="/"> 
//             Home
//           </Link>
//         </li>
//         <li>
//           <Link className="nav-link text-sm text-white" to="/about"> 
//             About
//           </Link>
//         </li>
//         <li>
//           <Link className="nav-link text-sm text-white" to="/documentation"> 
//            Documentation
//           </Link>
//         </li>
//         <li>
//           <Link className="nav-link text-sm text-white" to="/auth/login"> 
//           Login
//           </Link>
//         </li>
//         <button className='p-2'  style={{backgroundColor:"#FF6E00" , border:"none" ,borderRadius:"5px"}}> <li>
//           <Link className="nav-link text-sm text-white" to="/pricing"> 
//            Subscription
//           </Link>
//         </li></button>
       
       
     
       
//       </ul>
//     </nav>
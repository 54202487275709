import React, { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import authHeader from "../../auth-header";
import { AxiosRequestHeaders } from "axios";
import { KTIcon } from "../../../_metronic/helpers";
import { AiOutlineSend } from "react-icons/ai";
import CountryDropdown from "./Country";
import LangugaeDropdown from "./Language";
import Keywords from "./Keywords";
import Sources from "./Sources";
import Categories from "./Categories";
import Sentiment from "./Sentiment";
import Blacklist from "./Blacklist";
import { FcFilledFilter } from "react-icons/fc";
import { Carousel } from "react-bootstrap";
import Countryexclude from "./Countryexclude";
import Languageexclude from "./Languageexclude";
import { Pagination } from "react-bootstrap";
import { useZoom } from "../../../_metronic/partials/layout/zoom/ZoomContext";
interface ApiResponse {
  status: number;
  message: string;
  // result: ResultItem[];
  results: {
    result: ResultItem[];
    count: number;
    next: string | null;
    previous: string | null;
  };
}

interface ResultItem {
  id: number;
  title: string;
  post_url: string;
  source: string;
  posted_on: string;
  post_image: string;
  summarized_content: string;
  post_id: number;
  sentiment: string;
  author_name: string;
  article_language: string;
  article_country: string;
  rank: string;
bundle__bundle_title: string;

article_category: string;
platform: string;
}

const Search: React.FC = () => {
  const [view, setView] = React.useState("1");
  const [pageSize, setPageSize] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<ResultItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(true);
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryexclude, setSelectedCountryexclude] = useState("");
  const [selectedLanguageexclude, setSelectedLanguageexclude] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [startDate, setStartDate] = useState("");
  const [Articleperpage, setArticleperpage] = useState("");
  const [Articlereturn, setArticlereturn] = useState("");
  const [fromrank, setfromrank] = useState("");
  const [torank, settorank] = useState("");
  const [searchIn, setSearchIn] = useState("");
  const [endDate, setEndDate] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [searchForAll, setSearchForAll] = useState(false);
  const [startDateModified, setStartDateModified] = useState(false);
  const [endDateModified, setEndDateModified] = useState(false);
  const [ArticleperpageModified, setArticleperpageModified] = useState(false);
  const [ArticlereturnModified, setArticlereturnModified] = useState(false);
  const [fromrankModified, setfromrankModified] = useState(false);
  const [torankModified, settorankModified] = useState(false);
  const [selectedViewCount, setSelectedViewCount] = useState("10");
  const [articlesPerPage, setArticlesPerPage] = useState<number | null>(10);
  const [articlesPerPageModified, setArticlesPerPageModified] = useState(false);
  const { zoomLevel } = useZoom();

  const isSearchOrFilterEmpty = () => {
    return (
      !query &&
      !selectedCountry &&
      !selectedCountryexclude &&
      !selectedLanguage &&
      !selectedLanguageexclude &&
      !selectedKeyword &&
      !endDate &&
      !startDate &&
      !Articleperpage &&
      !Articlereturn &&
      !fromrank &&
      !torank &&
      !searchIn &&
      !sortBy &&
      !sortOrder
    );
  };
  const [state, setState] = useState<{
    activePage: number;
    data: ResultItem[];
    count: number;
    next: string | null;
    previous: string | null;
    view: string;
  }>({
    activePage: 1,
    data: [],
    count: 0,
    next: null,
    previous: null,
    view: "1",
  });
  const buildApiUrl = () => {
    let apiUrl = `${process.env.REACT_APP_API_URL}/article/search_v3_with_multiple_filters/?query=${query}`;
    // console.log("Selected Country:", selectedCountry);

    if (true || query !== "") {
      if (selectedCountry !== "") {
        apiUrl += `&country=${encodeURIComponent(selectedCountry)}`;
      }
      if (selectedCountryexclude !== "") {
        apiUrl += `&country_to_exclude=${encodeURIComponent(
          selectedCountryexclude
        )}`;
      }
      if (selectedLanguage) {
        apiUrl += `&language=${encodeURIComponent(selectedLanguage)}`;
      }
      if (selectedLanguageexclude) {
        apiUrl += `&language_to_exclude=${encodeURIComponent(
          selectedLanguageexclude
        )}`;
      }
      if (selectedKeyword) {
        apiUrl += `&keywords=${encodeURIComponent(selectedKeyword)}`;
      }
      if (startDate) {
        apiUrl += `&start_date=${encodeURIComponent(startDate)}`;
      }
      if (endDate) {
        apiUrl += `&end_date=${encodeURIComponent(endDate)}`;
      }
      if (Articleperpage) {
        apiUrl += `&articles_per_page=${encodeURIComponent(Articleperpage)}`;
      }
      if (Articlereturn) {
        apiUrl += `&number_of_articles_to_return=${encodeURIComponent(
          Articlereturn
        )}`;
      }
      if (fromrank) {
        apiUrl += `&start_rank=${encodeURIComponent(fromrank)}`;
      }
      if (torank) {
        apiUrl += `&end_rank=${encodeURIComponent(torank)}`;
      }
      if (searchIn) {
        apiUrl += `&search_in=${encodeURIComponent(searchIn)}`;
      }
      if (sortBy) {
        apiUrl += `&sort_by=${encodeURIComponent(sortBy)}`;
      }
      if (sortOrder) {
        apiUrl += `&order_by=${encodeURIComponent(sortOrder)}`;
      }
    }

    if (searchForAll) {
      apiUrl += "&search_for_all=True";
    }

    return apiUrl;
  };
  const fetchData = () => {
    setIsLoading(true);
    const apiUrl = buildApiUrl();
    console.log("API URL:", apiUrl);

    axios
      .get(apiUrl, {
        headers: authHeader() as AxiosRequestHeaders,
        params: {
          // page_size: 5,
          ...(articlesPerPageModified && {
            articles_per_page: articlesPerPage,
          }),
          page: state.activePage,
        },
      })
      .then((response) => {
        console.log("API response:", response.data);

        if (response.data.status === 204) {
          setResults([]);
          setNoResults(true);
          setApiMessage(response.data.message);
          setState((prevState) => ({
            ...prevState,
            count: 0,
            next: null,
            previous: null,
          }));
        } else if (
          response.status === 200 &&
          response.data.results.result !== null
        ) {
          setResults(response.data.results.result as ResultItem[]);
          setNoResults(false);
          setApiMessage(response.data.message);
          setState((prevState) => ({
            ...prevState,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          }));
        } else {
          setResults([]);
          setNoResults(true);
          setApiMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSearch = async () => {
    if (query.trim() === "") {
      setResults([]);
      setNoResults(true);
      setApiMessage("");
    } else {
      fetchData();
    }
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    setShowPrompt(inputValue.trim().length > 0);
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const toggleFilter = () => {
    setFilterVisible(!filterVisible);
  };

  const debounce = (func: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchData = debounce(() => fetchData(), 300);

  const handleCountryChange = (selectedCountries: string[]) => {
    setSelectedCountry(selectedCountries.join(','));
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));
    
    // debouncedFetchData(); 
  };
  
  
  const handleCountryexcludeChange = (selectedCountryexclude: string[]) => {
    setSelectedCountryexclude(selectedCountryexclude.join(','));
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));
    
    // debouncedFetchData(); 
  };
  

  const handleLanguageChange = (selectedLanguage: string[]) => {
    setSelectedLanguage(selectedLanguage.join(','));
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));
    
    // debouncedFetchData(); 

    
  };
 
  const handleLanguageexcludeChange = (selectedLanguageexclude: string[]) => {
    setSelectedLanguageexclude(selectedLanguageexclude.join(','));
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));
    
    // debouncedFetchData(); 
  };
  useEffect(() => {
    if (!isSearchOrFilterEmpty()) {
      debouncedFetchData();
    }
    // debouncedFetchData();

  }, [
    query ,
    selectedCountry,
    selectedCountryexclude,
    selectedLanguageexclude,
    selectedLanguage,
    selectedKeyword,
    endDate,
    startDate,
    Articleperpage,
    Articlereturn,
    fromrank,
    torank,
    searchIn,
    sortBy,
    sortOrder,
  ]);
  

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = e.target.value;
    setStartDate(inputDate);
    setStartDateModified(true);
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));

    // fetchData();
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputDate = e.target.value;
    setEndDate(inputDate);
    setEndDateModified(true);
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));

    // fetchData();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    if (
      inputValue === "" ||
      (parseInt(inputValue, 10) >= 1 && !isNaN(parseInt(inputValue, 10)))
    ) {
      setArticleperpage(inputValue);
      setArticleperpageModified(true);
    }
  };
  const handleArticlereturn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    if (
      inputValue === "" ||
      (parseInt(inputValue, 10) >= 1 && !isNaN(parseInt(inputValue, 10)))
    ) {
      setArticlereturn(inputValue);
      setArticlereturnModified(true);
      // setState((prevState) => ({
      //   ...prevState,
      //   activePage: 1,
      // }));
  
      // fetchData();
    }
  };
  const handlefromrank = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    if (
      inputValue === "" ||
      (parseInt(inputValue, 10) >= 1 && !isNaN(parseInt(inputValue, 10)))
    ) {
      setfromrank(inputValue);
      setfromrankModified(true);
      // setState((prevState) => ({
      //   ...prevState,
      //   activePage: 1,
      // }));
  
      // fetchData();
    }
  };
  const handletorank = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();
    if (
      inputValue === "" ||
      (parseInt(inputValue, 10) >= 1 && !isNaN(parseInt(inputValue, 10)))
    ) {
      settorank(inputValue);
      settorankModified(true);
      // setState((prevState) => ({
      //   ...prevState,
      //   activePage: 1,
      // }));
  
      // fetchData();
    }
  };

  const handleSearchInChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchIn(e.target.value);
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));

    // fetchData();
  };

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSortBy(e.target.value);
    // setState((prevState) => ({
    //   ...prevState,
    //   activePage: 1,
    // }));

    // fetchData();
  };
  const handleSortOrderToggle = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };
  const handleArticlesPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedArticlesPerPage = parseInt(e.target.value, 10);
    setArticlesPerPage(selectedArticlesPerPage);
    setIsLoading(true);

    if (state.activePage !== 1) {
      handlePageChange(1, selectedArticlesPerPage);
    } else {
      setArticlesPerPageModified(true);
    }
  };

  const handlePageChange = (pageNumber: number, articlesPerPage: number) => {
    if (
      state &&
      pageNumber > 0 &&
      pageNumber <= Math.ceil(state.count / articlesPerPage)
    ) {
      setState((prevState) => ({
        ...prevState,
        activePage: pageNumber,
      }));
      setArticlesPerPageModified(true);
    } else {
      console.error("Invalid page number:", pageNumber);
    }
  };

  useEffect(() => {
    if (articlesPerPageModified) {
      fetchData();
      setArticlesPerPageModified(false);
    }
  }, [articlesPerPage, state.activePage, articlesPerPageModified]);
  useEffect(() => {
    if (articlesPerPageModified && state) {
      fetchData();
      setArticlesPerPageModified(false);
    }
  }, [
    articlesPerPage,
    state && state.activePage,
    articlesPerPageModified,
    fetchData,
  ]);

  const renderPagination = () => {
    if (!state || state.count <= 1) {
      return null;
    }
  
    const totalPages = Math.ceil((state.count || 0) / (articlesPerPage || 1));
    const pageNumbers = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    );
  
    const displayPageNumbers = pageNumbers.filter((pageNumber) => {
      return (
        pageNumber <= totalPages &&
        pageNumber >= state.activePage - 2 &&
        pageNumber <= state.activePage + 2
      );
    });
  
    return (
      <Pagination className="px-4" size="lg">
        <Pagination.Prev
          onClick={() =>
            state.previous &&
            handlePageChange(state.activePage - 1, articlesPerPage || 1)
          }
          disabled={!state.previous}
        />
        {displayPageNumbers.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber, articlesPerPage || 1)}
            active={pageNumber === state.activePage}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            state.next &&
            handlePageChange(state.activePage + 1, articlesPerPage || 1)
          }
          disabled={!state.next}
        />
      </Pagination>
    );
  };
  

  return (
    <div style={{ transform: `scale(${zoomLevel})` }}>
      <div>
        <div className="d-flex justify-content-between  mt-5">
          <div className="d-flex gap-4 ">
            {" "}
            <div className="align-items-center">
              <span className="fw-bolder text-gray-700">Select View:</span>
              <select
                className="form-select form-select-sm form-select-solid w-100px w-xxl-125px mb-15"
                data-control="select2"
                data-placeholder="Latest"
                data-hide-search="true"
                value={view}
                onChange={(e) => setView(e.target.value)}
              >
                <option value="1">List</option>
                <option value="2">Grid</option>
                <option value="3">Magazine</option>
                <option value="4">Card</option>
                <option value="5">Carousel</option>
              </select>
            </div>
            <div>
              {" "}
              <span className="fw-bolder text-gray-700">View Count</span>
              <select
                className="form-select form-select-sm form-select-solid w-125px w-xxl-125px mb-15"
                data-control="select2"
                data-placeholder="10"
                data-hide-search="true"
                value={selectedViewCount}
                onChange={(e) => {
                  setSelectedViewCount(e.target.value);
                  handleArticlesPerPageChange(e);
                }}
            
              >
                <option value="10">10 articles</option>
                <option value="25">25 articles</option>
                <option value="50">50 articles</option>
                <option value="100">100 articles</option>
              </select>
            </div>
          </div>

        
        </div>
        <h2 className="d-flex justify-content-center mb-6">Search Articles</h2>
        <form
          data-kt-search-element="form"
          className="position-relative"
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <KTIcon
            iconName="magnifier"
            className="fs-2 search-icon position-absolute top-50 translate-middle-y ms-4"
          />
          <input
            type="text"
            className="search-input form-control ps-13 fs-7 h-40px"
            name="search"
            value={query}
            onChange={handleSearchInputChange}
            onKeyPress={handleEnterKeyPress}
            placeholder={
              showPrompt ? "Press 'Enter' to search" : "Quick Search"
            }
            data-kt-search-element="input"
          />
          {showPrompt && (
            <div className="d-flex search-prompt justify-content-end">
              Press 'Enter' to apply
            </div>
          )}
        </form>
        <div className="d-flex justify-content-end gap-2 text-gray-600">
            <button
              className="btn btn-link"
              onClick={toggleFilter}
              style={{ textDecoration: "none" }}
            >
              <FcFilledFilter style={{ fontSize: "30px" }} />
              Filters
            </button>
          </div>
        {filterVisible && (
          <div className="filter-options ">
            <div className="d-flex flex-wrap justify-content-center gap-6">
              <div style={{width:"200px"}}> 
              < CountryDropdown onCountryChange={handleCountryChange} />

                 </div>
                 <div style={{width:"200px"}}>
              <Countryexclude
                onCountryexcludeChange={handleCountryexcludeChange}
              />
               </div>
               <div style={{width:"200px"}}> 
              <LangugaeDropdown onLanguageChange={handleLanguageChange} />
              </div>
              <div style={{width:"200px"}}> 

              <Languageexclude
                onLanguageexcludeChange={handleLanguageexcludeChange}
              />
              </div>

              {/* <Keywords onKeywordChange={handleKeywordChange} /> */}

              <label htmlFor="datePicker" className="form-check-label mt-5">
                <p>
                  {" "}
                  <small className="text-muted">From Date</small>
                </p>

                <input
                  type="date"
                  className="form-control"
                  name="start_date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  placeholder="`YYYY/mm/dd`or phrases like one month ago"
                  style={{ height: "38px", borderColor: "black" }}
                />
              </label>
              <label htmlFor="datePicker" className="form-check-label mt-5">
                <p>
                  {" "}
                  <small className="text-muted"> To Date</small>
                </p>

                <input
                  type="date"
                  className="form-control"
                  name="end_date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  placeholder="End Date"
                  style={{ height: "38px", borderColor: "black" }}
                />
              </label>

              <div className="mt-3">
                <label htmlFor="pageSize" className="form-label">
                  <p>
                    <small className="text-muted">
                      Total articles to return
                    </small>
                  </p>
                </label>

                <div className="input-group">
                  <input
                    type="number"
                    id="number_of_articles_to_return"
                    name="number_of_articles_to_return"
                    className="form-control"
                    style={{
                      height: "38px",
                      borderColor: "black",
                      fontSize: "13px",
                      width: "120px",
                    }}
                    value={Articlereturn}
                    onChange={handleArticlereturn}
                  />
                </div>
              </div>
              <div className="mt-3">
                <label htmlFor="pageSize" className="form-label">
                  <p>
                    <small className="text-muted">From rank</small>
                  </p>
                </label>

                <div className="input-group">
                  <input
                    type="number"
                    id="start_rank"
                    name="start_rank"
                    className="form-control"
                    style={{
                      height: "38px",
                      borderColor: "black",
                      fontSize: "13px",
                      width: "120px",
                    }}
                    value={fromrank}
                    onChange={handlefromrank}
                  />
                </div>
              </div>
              <div className="mt-3">
                <label htmlFor="pageSize" className="form-label">
                  <p>
                    <small className="text-muted">To rank</small>
                  </p>
                </label>

                <div className="input-group">
                  <input
                    type="number"
                    id="end_rank"
                    name="end_rank"
                    className="form-control"
                    style={{
                      height: "38px",
                      borderColor: "black",
                      fontSize: "13px",
                      width: "120px",
                    }}
                    value={torank}
                    onChange={handletorank}
                  />
                </div>
              </div>

              <div className="mt-3">
                <label className="form-label d-flex justify-content-between">
                  <p>
                    <small className="text-muted">Sort By</small>
                  </p>
                  <button
                    className="btn btn-link "
                    onClick={handleSortOrderToggle}
                    style={{ marginTop: "-10px" }}
                  >
                    {sortOrder === "asc" ? "asc↑" : "desc↓"}
                  </button>
                </label>
                <select
                  className="form-select"
                  value={sortBy}
                  onChange={handleSortByChange}
                  style={{
                    height: "38px",
                    borderColor: "black",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  <option value="">Choose an Option</option>
                  <option value="date">Date</option>
                  <option value="relevancy">Relevancy</option>
                  <option value="rank">Rank</option>
                </select>
              </div>
              <div className="mt-3">
                <label className="form-label">
                  <p>
                    <small className="text-muted">Search In</small>
                  </p>
                </label>

                <select
                  className="form-select"
                  value={searchIn}
                  onChange={(e) => handleSearchInChange(e)}
                  style={{
                    height: "38px",
                    borderColor: "black",
                    fontSize: "13px",
                  }}
                >
                  <option value="">Choose an Option</option>

                  {/* <option value="title">Title</option> */}
                  {/* <option value="body">Article Body</option> */}
                  <option value="titlebody">Title + Article Body</option>
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row g-5 g-xl-12 mt-8">
        {noResults || isLoading ? (
          <div
            className="d-flex justify-content-center "
            style={{ marginTop: "120px" }}
          >
            <div
              className="d-flex justify-content-center"
              style={{ fontSize: "15px", marginTop: "10px" }}
            >
              {
                apiMessage
                 ||
                  "Please search for articles or apply filters to see results."
              }
            </div>
          </div>
        ) : isLoading ? (
          <div
            className="d-flex justify-content-center "
            style={{ marginTop: "120px" }}
          >
            <div
              className="spinner-border text-primary "
              role="status"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <span className="visually-hidden">Searching...</span>
            </div>
          </div>
        ) : (
          view === "1" &&
          results &&
          results.map((result, index) => (
            <div className="col-xl-12" key={result.id}>
              <div className=" card mb-3" style={{ maxWidth: "1000px" }}>
                <div className="row no-gutters">
                  <div className="col-md-7">
                    <div className="card-body">
                      <a
                        className="card-text  fw-bold mt-5"
                        href={result.post_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.title}
                      </a>
                      <h5 className="card-title mt-5">
                        Author : {result.author_name}
                      </h5>
                      <div className="d-flex mb-3">
                        <p className="card-text">
                          {/* <small>
                            {result.article_language.charAt(0).toUpperCase() +
                              result.article_language.slice(1)}
                          </small> */}
                          <small>
  {result.article_language ? (
    result.article_language.charAt(0).toUpperCase() +
    result.article_language.slice(1)
  ) : (
    'Language Not Available'
  )}
</small>

                        </p>
                        <p
                          className="card-text"
                          style={{ paddingLeft: "10px" }}
                        >
                          <small>
                            {result.article_country.charAt(0).toUpperCase() +
                              result.article_country.slice(1)}
                          </small>
                        </p>
                        <p
                          className="card-text"
                          style={{ paddingLeft: "10px" }}
                        >
                          <small
                          // className="text-muted"
                          >
                            Rank : {result.rank}
                          </small>
                        </p>
                      </div>
                      <h5 className="fw-bold">
                        Bundle Name : {result.bundle__bundle_title}
                      </h5>
                      {/* <p className="card-text">{data.title}</p> */}
                      <p className="card-text">
                        <small
                        // className="text-muted"
                        >
                          Date Published : {result.posted_on}
                        </small>
                      </p>
                      <p className="card-text">
                        {/* {result.summarized_content && result.summarized_content.slice(0, 250)} */}
                      </p>{" "}
                      <p className="card-text">
                        <small className="text-muted">
                          Last updated on {result.posted_on}
                        </small>
                      </p>
                      <div className="d-flex align-items-center mb-5">
                        {/* <a
                            href={`/detailnews/${result.id}`}
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          >
                            <KTIcon iconName="eye" className="fs-2" />
                          </a> */}
                        {result.sentiment ? (
                          result.sentiment === "positive" ? (
                            <a
                              className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                              style={{
                                backgroundColor: "green",
                                cursor: "default",
                              }}
                            >
                              {result.sentiment}
                            </a>
                          ) : result.sentiment === "negative" ? (
                            <a
                              className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                              style={{ cursor: "default" }}
                            >
                              {result.sentiment}
                            </a>
                          ) : result.sentiment === "neutral" ? (
                            <a
                              className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                              style={{ cursor: "default" }}
                            >
                              {result.sentiment}
                            </a>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <img
                      src={result.post_image}
                      className="card-img h-75 w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
        {view === "2" &&
          results &&
          results.slice(0, 10).map((result, index) => (
            <div className="col-xl-6">
              <div className="card mb-3" style={{ maxWidth: "800px" }}>
                <div className="row no-gutters">
                  <div className="col-md-7">
                    <div className="card-body">
                      <a
                        className="card-text  fw-bold mt-5"
                        href={result.post_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {result.title}
                      </a>

                      <h5 className="card-title mt-5">
                        Author : {result.author_name}
                      </h5>
                      <div className="d-flex mb-3">
                        <p className="card-text">
                          <small>Language : {result.article_language}</small>
                        </p>
                        <p
                          className="card-text"
                          style={{ paddingLeft: "10px" }}
                        >
                          <small>{result.article_country}</small>
                        </p>
                      </div>

                      <h5 className="fw-bold">
                        Bundle Name : {result.bundle__bundle_title}
                      </h5>
                      {/* <p className="card-text">{data.title}</p> */}
                      <p className="card-text">
                        <small>Date Published : {result.posted_on}</small>
                      </p>

                      <p className="card">
                        {/* {result.summarized_content && result.summarized_content.slice(0, 250)} */}
                      </p>

                      <p className="card-text">
                        <small className="text-muted">
                          Last updated on {result.posted_on}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <img
                      src={result.post_image}
                      className="card-img"
                      style={{
                        width: "200px",
                        height: "100px",
                        marginTop: "150px",
                        objectFit: "cover",
                      }}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {view === "3" && (
         <div>
         <div className="row align-items-stretch">
           {results &&
             results.slice(0, 10).map((result, index) => (
               <div className={index % 2 === 0 ? "col-lg-8" : "col-lg-4"}>
                 <div className="card mb-4">
                   <div className="row">
                     <div className="col-lg-12 clearfix p-5">
                       <div>
                         <div className="d-flex justify-content-between align-items-center">
                         <h1 className="card-title ">
                                {" "}
                                <strong className="text-dark ">
                                  <a
                                    className="text-dark "
                                    href={result.post_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {result.title}
                                  </a>
                                </strong>
                              </h1>
                           {result.sentiment ? (
                             result.sentiment === "positive" ? (
                               <a
                                 href="#"
                                 className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                                 style={{ backgroundColor: "green" }}
                               >
                                 {result.sentiment}
                               </a>
                             ) : result.sentiment === "negative" ? (
                               <a
                                 href="#"
                                 className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                               >
                                 {result.sentiment}
                               </a>
                             ) : result.sentiment === "neutral" ? (
                               <a
                                 href="#"
                                 className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                               >
                                 {result.sentiment}
                               </a>
                             ) : null
                           ) : null}
                         </div>
                         <img src={result.post_image} alt="" className="w-100" />
                         <h5 className="card-title mt-5">
                              Author : {result.author_name}
                            </h5>
                            <div className="d-flex mb-3">
                              <p className="card-text">
                                <small>
                                  Language : {result.article_language}
                                </small>
                              </p>
                              <p
                                className="card-text"
                                style={{ paddingLeft: "10px" }}
                              >
                                <small>Country : {result.article_country}</small>
                              </p>
                            </div>
                            <h5 className="fw-bold">
                              Bundle Name : {result.bundle__bundle_title}
                            </h5>
                            <br></br>

<p className="text-dark  font-weight-bold">
  {result.summarized_content &&
    result.summarized_content.slice(0, 1050)}
</p>
<p className="card-text">
  <small>Date Published : {result.posted_on}</small>
</p>
                       </div>
                     </div>
                    <div className="col-lg-4"> <div
                  className="card text-white mb-5"
                  style={{ maxWidth: "400px", backgroundColor: "#FDFEFE" }}
                ></div>
                 <div
                  className="d-flex justify-content-end mb-5 "
                  style={{
                    position: "absolute",
                    bottom: 0,
                    marginLeft: "1rem",
                  }}
                >
                  <div className="d-flex align-items-center mb-5"></div>
                </div>
              
                </div>
                   </div>
                 </div>
               </div>
             ))}
         </div>
       </div>
       
        )}

        {view === "4" &&
          results &&
          results.slice(0, 10).map((result, index) => (
            <div>
              <div
                className="card mb-5 "
                style={{
                  width: "70rem",
                  // height: "55rem",
                }}
              >
                <div className="card-body">
                  <img
                    style={{
                      height: "400px",
                      width: "700px",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    src={result.post_image}
                    // className="card-img-top h-100"
                    alt="..."
                  />

                  <br></br>
                  {/* <div className="symbol symbol-25px me-5 mt-5 mb-2">
                      <img src={data.source_logo} alt="" className="w-100" />
                    </div> */}
                  <div className="text-gray-400  fw-semibold mt-2">
                    <a
                      href={result.post_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {result.post_url}
                    </a>
                  </div>
                  <h5 className="card-title">{result.title}</h5>
                  <p className="text-dark mt-2 font-weight-bold"></p>

                  <p className="card-text">
                    {/* {result.summarized_content && result.summarized_content.slice(0, 150)} */}
                  </p>
                  <div className="text-muted">
                    Last Updated {result.posted_on}
                  </div>
                  <div className="d-flex align-items-center mb-2 mt-3">
                    {/* <a
                      href="#"
                      className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                    >
                      Negative
                    </a> */}
                    <div className="d-flex align-items-center mb-5">
                      {/* <a
                          href={`/detailnews/${result.id}`}
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        >
                          <KTIcon iconName="eye" className="fs-2" />
                        </a> */}
                      {result.sentiment ? (
                        result.sentiment === "positive" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                            style={{ backgroundColor: "green" }}
                          >
                            {result.sentiment}
                          </a>
                        ) : result.sentiment === "negative" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                          >
                            {result.sentiment}
                          </a>
                        ) : result.sentiment === "neutral" ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                          >
                            {result.sentiment}
                          </a>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className=" card mb-3" style={{ maxWidth: "1000px" }}>
                  <div className="row no-gutters">
                    <div className="col-md-7">
                      <div className="card-body">
                        <br />
                        <div className="text-gray-400 fw-semibold mt-5">
                          <a
                            href={result.post_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {result.post_url}
                          </a>
                        </div>
                        <h5 className="card-title mt-5">{result.title}</h5>
                        <p className="card-text">{result.title}</p>
                        <p className="card-text">
                          <small className="text-muted">
                            Last updated on {result.posted_on}
                          </small>
                        </p>
                        <div className="d-flex align-items-center mb-5">
                          <a
                            href={`/detailnews/${result.id}`}
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          >
                            <KTIcon iconName="eye" className="fs-2" />
                          </a>
                          {result.sentiment ? (
                            result.sentiment === "positive" ? (
                              <a
                                className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                                style={{ backgroundColor: "green", cursor: "default" }}
                              >
                                {result.sentiment}
                              </a>
                            ) : result.sentiment === "negative" ? (
                              <a
                                className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                                style={{ cursor: "default" }}
                              >
                                {result.sentiment}
                              </a>
                            ) : result.sentiment === "neutral" ? (
                              <a
                                className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                                style={{ cursor: "default" }}
                              >
                                {result.sentiment}
                              </a>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <img
                        src={result.post_image}
                        className="card-img h-75 w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                </div> */}
            </div>
          ))}
        {view === "5" && (
          <div>
            <div
              className="card mb-3 "
              style={{ width: "60rem", margin: "auto" }}
            >
              <Carousel>
                {results &&
                  results.slice(0, 10).map((result, index) => (
                    <Carousel.Item key={result.id}>
                      <div className="card-body">
                        {/* <div className="symbol symbol-25px me-5">
                      <img src={data.source_logo} alt="" className="w-100" />
                    </div> */}
                        <br></br>

                        <div className="text-gray-400 fw-semibold mt-5">
                          <a
                            href={result.post_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {result.post_url}
                          </a>
                        </div>
                        <h5 className="">{result.title}</h5>

                        <p className="text-dark mt-2 font-weight-bold">
                          {/* {result.summarized_content && result.summarized_content.slice(0, 550)} */}
                        </p>
                        <p className="">
                          <small className="text-muted">
                            Last updated on {result.posted_on}
                          </small>
                        </p>
                        <div className="d-flex align-items-center mb-5">
                          {result.sentiment ? (
                            result.sentiment === "positive" ? (
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-white  px-4 py-2 me-4"
                                style={{ backgroundColor: "green" }}
                              >
                                {result.sentiment}
                              </a>
                            ) : result.sentiment === "negative" ? (
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                              >
                                {result.sentiment}
                              </a>
                            ) : result.sentiment === "neutral" ? (
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-white btn-warning px-4 py-2 me-4"
                              >
                                {result.sentiment}
                              </a>
                            ) : null
                          ) : null}

                          {/* <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                      >
                        <KTIcon iconName="eye" className="fs-2" />
                      </a> */}
                        </div>
                        <div style={{ height: "40rem", objectFit: "cover" }}>
                          <img
                            src={result.post_image}
                            className="card-img-top h-100"
                            alt="..."
                          />
                        </div>
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
          </div>
        )}
      </div>
      <div className="mt-10">{renderPagination()}</div>
    </div>
  );
};

export default Search;

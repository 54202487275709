import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import authHeader from "../../../auth-header";

const Editkeyword = ({ isOpen, onClose, initialBundle, initialSources, bundleSources }) => {
  const initialFormState = {
  keyword: initialBundle.keyword || "",
  platform: initialBundle.platform || "",
  source_id: initialBundle.source_id || "", // Include source_id
};


  const [formData, setFormData] = useState(initialBundle || initialFormState);
  const [validationError, setValidationError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [platforms, setPlatforms] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setFormData(initialBundle || initialFormState);
    fetchPlatforms();
  }, [initialBundle]);

  const fetchPlatforms = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_bundles_sources`, {
        headers: authHeader(),
      });
      const data = response.data;

      if (data.status === 200) {
        setPlatforms(data.result);
      } else {
        // Handle error cases if needed
      }
    } catch (error) {
      console.error("Error fetching platforms:", error);
    }
  };

  const handleSaveChanges = async (values) => {
    try {
      if (!values.keyword || !values.platform) {
        setValidationError("Keyword Title and Platform are required.");
        setTimeout(() => {
          setValidationError("");
        }, 5000);
        return;
      }

      const dataToSend = {
        id: initialBundle.id,
        keyword: values.keyword,
        platform: values.platform,
        source_id: values.source_id,
      };

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/policy/update_new_keyword/${initialBundle.id}`,
        dataToSend,
        {
          headers: authHeader(),
        }
      );

      // console.log("API Response..:", response.data);

      if (response.status === 200) {
        setSaveSuccess(true);
        onClose();
        // Add an alert for successful save
        alert("Keyword Updated Successfully.");
        window.location.reload();

      } else if (response.status === 400) {
        // Handle the 400 status code here
        if (response.data && response.data.message) {
          // Show the error message as an alert
          alert(response.data.message);
        } else {
          alert("An error occurred while updating the keyword.");
        }
      } else {
        // Handle other cases as needed
        // You can display an error message if the status code is not 200
      }
    } catch (error) {
      console.error("Error updating keyword:", error);
      alert("An error occurred while updating the keyword. Please try again later.");
    }
  };

  
  // console.log("initialBundle:", initialBundle);

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-color-primary"
              onClick={onClose}
            >
              <i className="fs-1">X</i>
            </button>
          </div>
          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Edit Keyword</h1>
            </div>
            <Formik
              initialValues={formData}
              onSubmit={handleSaveChanges}
              enableReinitialize={true}
            >
              <Form>
                <div className="d-flex flex-row mt-5 justify-content-between flex-sm-row flex-column">
                  <div>
                    <label htmlFor="keyword" className="required form-label fs-6 fw-bolder text-dark">
                      Keyword Title:
                    </label>
                    <Field
                      type="text"
                      id="keyword"
                      name="keyword"
                      className="form-control bg-transparent"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="platform" className="required form-label fs-6 fw-bolder text-dark">
                      Select Platform:
                    </label>
                    <select
                      id="platform"
                      name="platform"
                      className="form-select form-select-sm form-select-solid w-100px w-xxl-125px"
                      data-control="select2"
                      data-placeholder="Latest"
                      data-hide-search="true"
                      onChange={handleInputChange}
                      value={formData.platform}
                    >
                      {platforms.map((platform) => (
                        <option key={platform.id} value={platform.platform}>
                          {platform.platform}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-center mt-13">
                <button
              type="submit"
              className="btn btn-sm btn-primary me-3"
              disabled={saveSuccess}
            >
              {saveSuccess ? "Saved" : "Save Changes"}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editkeyword;

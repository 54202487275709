import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import authHeader from '../../../../app/auth-header';
import { AxiosRequestHeaders } from 'axios';
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string;
  items?: number;
};

const ListsWidget4: React.FC<Props> = ({ className, items = 6 }) => {
  const [insightData, setInsightData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/article/insight_v1`, {
        headers: authHeader() as AxiosRequestHeaders,
      });

      if (response.data && response.data.result) {
        setInsightData(response.data.result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div  className={`card card-xl-stretch mb-xl-8 ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Latest Feeds</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>

      <div className='card-body pt-5'>
        {isLoading ? (
          <div className='d-flex justify-content-center mt-4'>
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          insightData && (
            <div className='col-xl-12'>
              <div className='card mb-3' style={{ maxWidth: '1000px' }}>
                <div className='row no-gutters'>
                  <div className='col-md-12'>
                    <div className='card-body'>
                      <div className='d-flex align-items-center'>
                        {/* <div style={{height:"50px"}} className='symbol symbol-25px me-5'>
                          <img
                            src={toAbsoluteUrl(insightData.source_logo)}
                            alt=''
                            className='w-100'
                          />
                        </div> */}
                        <div style={{ height: "50px" }} className='symbol symbol-25px me-5'>
  {insightData.source_logo ? (
    <img
      src={toAbsoluteUrl(insightData.source_logo)}
      alt=''
      className='w-100'
    />
  ) : (
    <img
    // src={toAbsoluteUrl("/media/logos/wire_logo.png")}
    src={toAbsoluteUrl("/media/logos/noimage.png")}

      alt=''
      className='w-100'
    />
  )}
</div>

                        <div className='text-gray-400 fw-semibold mt-5'>
                        <h1>
    {insightData.source && insightData.source.charAt(0).toUpperCase() + insightData.source.slice(1)}
  </h1>
                        </div>
                      </div>

                      <img
                        src={insightData.image}
                        className='card-img w-100'
                        alt='...'
                      />
                      <h5 className='card-title mt-5'>{insightData.title}</h5>

                      <p className='card-text'>
                        {/* Display additional content or description if available */}
                      </p>
                      <p className='card-text'>
                        <small >
                          Last updated on {insightData.posted_on}
                        </small>
                      </p>
                      <div className='d-flex align-items-center mb-5'>
                      <a
    // href='#'
    className=' btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4'
    style={{
      backgroundColor: '#DC3545',     
      borderColor: '#DC3545', 
      borderWidth: '2px',   
      borderRadius:"5Px"   ,
      color:"white"
    }}
  >
    Negative
  </a>
                        <a
                          href={`/detailnews/${insightData.id}`}
                          className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export  {ListsWidget4};

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RiCheckboxCircleFill } from "react-icons/ri"; // Importing React Icons
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';

function Verificationsignup() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
  
    if (token) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/user_portal/api/user_email_verification?token=${token}`;
      // console.log("API URL:", apiUrl);
  
      axios.get(apiUrl)
        .then((response) => {
          // console.log(response.data);
  
          // Check if the response status is 200
          if (response.status === 200) {
            // Show success toast message
            toast.success(response.data.message, { autoClose: 5000 }); // You can customize the duration
          }
        })
        .catch((error) => {
          console.error("Error verifying email:", error);
  
          // Show error toast message
          toast.error("Error verifying email. Please try again.");
        });
    }
  }, [location.search]);
  
  return (
    <>
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="mt-5 text-center">
        <RiCheckboxCircleFill className="text-success" size={50} />
        <h2 className="mt-3">Your Account has been Verified Successfully!</h2>
        <div className="mb-5 mt-3">
          <p>Please Sign In Now</p>
        </div>
      </div>
      <div className=" row text-center">
        <Link to="/auth/login">
          <Button
            variant="primary"
            className="btn-lg w-100 mb-2"
            onClick={() => navigate("/auth/login")}
          >
            Sign In
          </Button>
        </Link>
      </div>
    </div>
    <ToastContainer />

    </>
  );
}

export default Verificationsignup;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { getUserByToken, register } from "../core/_requests";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuth } from "../core/Auth";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KTIcon } from "../../../../_metronic/helpers";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  lastname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),
  username: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  changepassword: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
});

export function Registration() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const [validationError, setValidationError] = useState("");

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const credential = response?.credential;

      if (credential) {
        const token = credential;

        // console.log('Access Token:', token);

        localStorage.setItem("auth_token", token);

        const apiResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/user_portal/api/google_login`,
          {
            auth_token: token,
          }
          // { headers: authHeader() }
        );

        if (apiResponse.data.status === 200) {
          // console.log(apiResponse)

          const token = apiResponse.data.result.token.access;
          localStorage.setItem("token", token);
          localStorage.setItem(
            "get_pocket_token",
            apiResponse.data.result.get_pocket_token
          );
          localStorage.setItem("provider", apiResponse.data.result.provider);

          toast.success("Login Successful: " + apiResponse.data.message);
          navigate("/user/dashboard");
          // console.log('Token:', token);
        } else if (apiResponse.data.status === 400) {
          toast.error("Login Failed: " + apiResponse.data.message);
          // console.log('Login with Google failed on the server side');
        }
      } else {
        toast.error("Token not found in the response");

        // console.log('Token not found in the response');
      }
    } catch (error) {
      console.error("Error handling Google login:", error);
      toast.error("An error occurred");
    }
  };

  const handleGoogleLoginFailure = (error) => {
    // console.log('Google registration failed');
    console.error("Google Registration Error:", error);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.username,
          values.password,
          values.changepassword
        );
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.api_token);
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The registration details is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const [showPassword, setShowPassword] = useState(false);
  const [Password, setPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const PasswordVisibility = () => {
    setPassword(!Password);
  };
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { password, confirmPassword } = formData;
    if (!formData.firstname || formData.firstname.trim() === "") {
      // console.log("Title validation error");
      setValidationError("Please enter First Name.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Please enter First Name.", {
        autoClose: 5000,
      });
      return;
    }
    if (!formData.lastname || formData.lastname.trim() === "") {
      //  console.log("Title validation error");
      setValidationError("Please enter Last Name.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Please enter Last Name.", {
        autoClose: 5000,
      });
      return;
    }
    if (!formData.username || formData.username.trim() === "") {
      //  console.log("username validation error");
      setValidationError("Please enter User Name.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Please enter User Name.", {
        autoClose: 5000,
      });
      return;
    }
    if (!formData.email || formData.email.trim() === "") {
      //  console.log("email validation error");
      setValidationError("Please enter Email.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Please enter Email.", {
        autoClose: 5000,
      });
      return;
    }
    if (!formData.password || formData.password.trim().length === "") {
      //  console.log("password validation error");
      setValidationError("Please enter password.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Please enter password", {
        autoClose: 5000,
      });
      return;
    }
    if (password.length < 8) {
      setValidationError("Password must be at least 8 characters");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Password must be at least 8 characters", {
        autoClose: 5000,
      });
      return;
    }
    if (!formData.confirmPassword || formData.confirmPassword.trim() === "") {
      //  console.log("confirmPassword validation error");
      setValidationError("Please enter Confirm password.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      toast.error("Please enter Confirm password.", {
        autoClose: 5000,
      });
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match", {
        autoClose: 5000,
      });
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_portal/api/create_user`,
        {
          first_name: formData.firstname,
          last_name: formData.lastname,
          username: formData.username,
          email: formData.email,
          password: formData.password,
          confirm_password: formData.confirmPassword,
        }
      );
      // console.log(response);

      if (response.data.status === 201) {
        // const token = response.data.result.token;
        // localStorage.setItem("token", token);

        // console.log(response.data);
        toast.success(response.data.message, {
          autoClose: 5000,
        });
        navigate("/auth/login");
      } else {
        console.error(response);
        // console.log(response.data);

        toast.error(response.data.message, {
          autoClose: 5000,
        });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message.join(", "), {
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>

          <div className="text-gray-500 fw-semibold fs-6">
            Your Social Campaigns
          </div>
        </div>

        <div className="row g-3 mb-9">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-6 ">
              <GoogleOAuthProvider clientId="418076685952-brome20fnhgsdjl6ddqvee8ne63ibdn5.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={(credentialResponse) =>
                    handleGoogleLoginSuccess(credentialResponse)
                  }
                  onError={() => {}}
                />
              </GoogleOAuthProvider>
            </div>
          </div>
        </div>

        <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Or with email
          </span>
        </div>

        {/* {validationError && (
                <div className="alert alert-danger" role="alert">
                  {validationError}
                </div>
              )} */}

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            First name
          </label>
          <input
            placeholder="First name"
            type="text"
            name="firstname"
            autoComplete="off"
            className="form-control bg-transparent"
            value={formData.firstname}
            onChange={handleChange}
          />
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            Last name
          </label>
          <input
            placeholder="Last name"
            type="text"
            name="lastname"
            autoComplete="off"
            className="form-control bg-transparent"
            value={formData.lastname}
            onChange={handleChange}
          />
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            User Name
          </label>
          <input
            placeholder="User name"
            type="text"
            name="username"
            autoComplete="off"
            className="form-control bg-transparent"
            value={formData.username}
            onChange={handleChange}
          />
        </div>

        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">Email</label>
          <input
            placeholder="Email"
            type="email"
            name="email"
            autoComplete="off"
            className="form-control bg-transparent"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="fv-row mb-8" data-kt-password-meter="true">
          <div className="mb-1">
            <label className="form-label fw-bolder text-dark fs-6">
              Password
            </label>
            <div className="input-group mb-3">
              <input
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                className="form-control bg-transparent"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />

              <button
                type="button"
                className="d-flex justify-content-center align-items-center btn btn-primary btn-outline-secondary"
                onClick={togglePasswordVisibility}
                style={{ width: "25px", height: "44px" }}
              >
                <KTIcon iconName={showPassword ? "eye-slash" : "eye"} />
              </button>
            </div>

            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
          </div>
          <div className="text-muted">
            Use 8 or more characters with a mix of letters, numbers, a capital
            letter, and symbols
          </div>
        </div>

        <div className="fv-row mb-5">
          <label className="form-label fw-bolder text-dark fs-6">
            Confirm Password
          </label>
          <div className="input-group mb-3">
            <input
              type={Password ? "text" : "password"}
              autoComplete="off"
              className="form-control bg-transparent"
              name="confirmPassword"
              placeholder="Password confirmation"
              value={formData.confirmPassword}
              onChange={handleChange}
            />

            <button
              type="button"
              className="d-flex justify-content-center align-items-center btn btn-primary btn-outline-secondary"
              onClick={PasswordVisibility}
              style={{ width: "25px", height: "44px" }}
            >
              <KTIcon iconName={Password ? "eye-slash" : "eye"} />
            </button>
          </div>
        </div>

        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
          >
            Submit
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              Sign In
            </button>
          </Link>
        </div>
      </form>
      <ToastContainer />
    </>
  );
}

import React, { FC, useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { Dropdown1 } from "../../../../../_metronic/partials/content/dropdown/Dropdown1";
import axios, { AxiosResponse } from "axios";
import { BsFillChatSquareHeartFill, BsFillBookFill } from "react-icons/bs";
import authHeader from "../../../../auth-header";
import { AxiosRequestHeaders } from "axios";
import { AiFillRead } from "react-icons/ai";
import { toast } from "react-toastify";
import { NewsItem } from "../../../News/components/NewsTemplate";
import { Carousel, Pagination, Modal, Button } from "react-bootstrap";
import { useZoom } from "../../../../../_metronic/partials/layout/zoom/ZoomContext";
import { FiCopy } from "react-icons/fi";
import { CiSaveUp2 } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import Share from "../../../News/components/Share";
type ReadLaterItem = {
  id: number;
  post_image: string;
  title: string;
  link: string;
  source: string;
  source_logo: string;
  posted_on: string;
  image: string;
  read_later: boolean;
  readLater: number;
  sentiment: string;
  author: string;
  article_country: string;
  article_language: string;
  bundle: string;
  summarized_content: string;
  liked: boolean;
};
type ReadLaterState = {
  view: string;
  showShareModal: boolean;
  shareResult: string | null;
  selectedArticleLink: string | null;
};
const ReadLater: FC = () => {
  const [view, setView] = useState("1");

  const [readlater, setReadLater] = useState<ReadLaterItem[]>([]);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [viewCountModified, setViewCountModified] = useState(false);
  const [articlesPerPage, setArticlesPerPage] = useState<number>(10);
  const [selectedViewCount, setSelectedViewCount] = useState("10");
  const [idframe, updateiframe] = useState<number>(0);
  const [showShareModal, setShowShareModal] = React.useState(false);

  const [selectedArticle, setSelectedArticle] =
    React.useState<ReadLaterItem | null>(null);
  const [showShareArticleModal, setShowShareArticleModal] =
    React.useState(false);
  const [showIframeModal, setShowIframeModal] = React.useState(false);
  const [copied, setCopied] = useState(false);
  const [copie, setCopie] = useState(false);
  const [state, setState] = React.useState<ReadLaterState>({
    view: "1",
    selectedArticleLink: null,
    showShareModal: false,
    shareResult: null,
  });
  const { zoomLevel } = useZoom();

  const fetchReadLaterPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/article/get_read_later_posts_with_pagination_v2`,
        {
          headers: authHeader() as AxiosRequestHeaders,
          params: {
            page: currentPage,
            articles_per_page: articlesPerPage,
          },
        }
      );

      if (response.data && response.data.results) {
        const { result, total_pages } = response.data.results;
        setReadLater(result);
        setTotalPages(total_pages);
        setIsDataAvailable(true);
      } else {
        setIsDataAvailable(false);
      }
      // console.log("readlater",response)
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch Read Later posts.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReadLaterPosts();
  }, [currentPage, articlesPerPage, totalPages]);

  const handleArticlesPerPageChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedArticlesPerPage = parseInt(e.target.value, 10);
    setArticlesPerPage(selectedArticlesPerPage);
    setIsLoading(true);

    if (currentPage !== 1) {
      handlePageChange(1);
    }
  };
  const copyToClipboard = () => {
    // console.log("copyToClipboard: Copying to clipboard");
    const textToCopy = `<iframe src="${state.selectedArticleLink}" name="myIFrame" scrolling="no" width="50%" height="30%" style="border: 10px solid #000000;"<iframe/>`;

    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const copyToClipboardShareModal = () => {
    // console.log("copyToClipboardShareModal: Copying to clipboard");
    const textToCopy = state.shareResult || "";

    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);
    setCopie(true);

    setTimeout(() => {
      setCopie(false);
    }, 1000);
  };
  const renderPagination = () => {
    if (totalPages <= 1) {
      return null;
    }

    const pageNumbers = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    );

    return (
      <Pagination className="px-4" size="lg">
        <Pagination.Prev
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {pageNumbers.map((pageNumber) => (
          <Pagination.Item
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            active={pageNumber === currentPage}
          >
            {pageNumber}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() =>
            currentPage < totalPages && handlePageChange(currentPage + 1)
          }
          disabled={currentPage === totalPages}
        />
      </Pagination>
    );
  };

  useEffect(() => {
    // ... fetchLikedPosts function
  }, [currentPage]);

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (!text) {
      return "";
    }

    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };
  const removeReadLaterPost = async (id: number) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/article/add_read_later_post/${id}/remove`,
        {},
        {
          headers: authHeader() as AxiosRequestHeaders,
        }
      );
      toast.success(response.data.message);
      // console.log("Read Later post removed successfully:", response.data);

      // window.location.reload();
      setReadLater(readlater.filter((post) => post.id !== id));
    } catch (error) {
      console.error("Delete Post Error:", error);
      toast.error("Failed to remove the post from Read Later.");
    }
  };
  const handleIFrameClick = (articleId: number) => {
    updateiframe(articleId);
    setShowIframeModal(true);
  };
  const handleSaveToPocket = async (link: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/article/add_articles_to_get_pocket`,
        { link: link },
        {
          headers: authHeader() as { Authorization: string },
        }
      );

      if (response.data.status === 200) {
        toast.success(
          response.data.message || "Article successfully added to GetPocket"
        );
      }
    } catch (error) {
      console.error("Error saving to Pocket:", error);
    }
  };
  const handleShareArticle = (article: ReadLaterItem) => {
    setSelectedArticle(article);
    setShowShareArticleModal(true);
  };
  const handleShare = (id: number) => {
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/article/share_article/${id}`,
      headers: authHeader() as { Authorization: string },
    })
      .then((res: AxiosResponse) => {
        // console.log("Share API Response:", res.data);
        setState({ ...state, shareResult: res.data.result });
        setShowShareModal(true);
      })
      .catch((error) => console.error("Share API Request Error:", error));
    setShowShareModal(true);
  };
  const toggleFavoritePost = async (id: number, isLiked: boolean) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/article/add_liked_post/${id}/${
          isLiked ? "remove" : "add"
        }`,
        {},
        {
          headers: authHeader() as AxiosRequestHeaders,
        }
      );

      setReadLater((prevReadLater) =>
        prevReadLater.map((post) =>
          post.id === id ? { ...post, liked: !isLiked } : post
        )
      );

      const toastMessage = isLiked
        ? "Post removed from Favorites."
        : "Post added to Favorites.";
      toast.success(toastMessage);
      // console.log("Toggle Favorite Post response:", response.data);
      // console.log("Updated Read Later state:", readlater);
    } catch (error) {
      console.error("Toggle Favorite Post Error:", error);
      toast.error("Failed to toggle the post in Favorites.");
    }
  };

  return (
    <div style={{ transform: `scale(${zoomLevel})` }}>
      <div className="d-flex gap-4 ">
        {" "}
        <div className="align-items-center">
          <span className="fw-bolder text-gray-700">Select View:</span>
          <select
            className="form-select form-select-sm form-select-solid w-100px w-xxl-125px mb-15"
            data-control="select2"
            data-placeholder="Latest"
            data-hide-search="true"
            value={view}
            onChange={(e) => setView(e.target.value)}
          >
            <option value="1">List</option>
            <option value="2">Grid</option>
            <option value="3">Magazine</option>
            <option value="4">Card</option>
            <option value="5">Carousel</option>
          </select>
        </div>
        <div>
          {" "}
          <span className="fw-bolder text-gray-700">View Count</span>
          <select
            className="form-select form-select-sm form-select-solid w-125px w-xxl-125px mb-15"
            data-control="select2"
            data-placeholder="10"
            data-hide-search="true"
            onChange={(e) => {
              setSelectedViewCount(e.target.value);
              handleArticlesPerPageChange(e);
            }}
          >
            <option value="10">10 articles</option>
            <option value="25">25 articles</option>
            <option value="50">50 articles</option>
            <option value="100">100 articles</option>
          </select>
        </div>
      </div>
      {isLoading ? ( // Check if the data is still loading
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: "120px" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : isDataAvailable ? (
        <div className="row g-5 g-xl-12">
          <div className="col-xl-12">
            {view === "1" &&
              readlater.map((data: ReadLaterItem) => (
                <div
                  key={data.id}
                  className="card mt-4 mb-3"
                  style={{ maxWidth: "1000px" }}
                >
                  <div className="row no-gutters">
                    <div className="col-md-7">
                      <div className="card-body">
                        <div
                          // className="symbol symbol-25px me-5"
                          style={{ width: "30px", height: "30px" }}
                        >
                          <img
                            src={data.source_logo}
                            alt=""
                            className="w-100"
                          />
                        </div>
                        <br></br>
                        <a
                          className="card-text  fw-bold mt-5"
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.title}
                        </a>
                        <h5 className="card-title mt-5">
                          Author : {data.author}
                        </h5>
                        <div className="d-flex mb-3">
                          <p className="card-text">
                            <small>{data.article_language}</small>
                          </p>
                          <p
                            className="card-text"
                            style={{ paddingLeft: "10px" }}
                          >
                            <small>{data.article_country}</small>
                          </p>
                        </div>
                        <h5 className="fw-bold">Bundle Name : {data.bundle}</h5>
                        <p className="card-text">
                          <small
                          // className="text-muted"
                          >
                            Date Published : {data.posted_on}
                          </small>
                        </p>
                        <p className="card-text">
                          <small>
                            {truncateText(data.summarized_content, 250)}
                          </small>
                        </p>

                        <div className="d-flex align-items-center mb-5">
                          <a
                            href="#"
                            className="px-4 py-2 me-4"
                            onClick={() =>
                              toggleFavoritePost(data.id, data.liked)
                            }
                          >
                            {data.liked ? (
                              <BsFillChatSquareHeartFill
                                style={{ fontSize: "30px", color: "#E76161" }}
                              />
                            ) : (
                              <BsFillChatSquareHeartFill
                                style={{ fontSize: "30px" }}
                              />
                            )}
                          </a>
                          <a
                            href="#"
                            className="px-4 py-2 me-4 "
                            onClick={() => removeReadLaterPost(data.id)}
                          >
                            <AiFillRead
                              style={{ fontSize: "30px", color: "#E76161" }}
                            />
                          </a>
                          <a
                            className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                            onClick={() => handleIFrameClick(data.id)}
                          >
                            iFrame
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                            onClick={() => handleShare(data.id)}
                          >
                            HTML
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                            onClick={() => handleSaveToPocket(data.link)}
                          >
                            <CiSaveUp2 size={24} />
                            Pocket
                          </a>
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                            onClick={() => handleShareArticle(data)}
                          >
                            <IoMdShare size={24} />
                            Share
                          </a>
                          <a
                            href={`/detailnews/${data.id}`}
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          >
                            <KTIcon iconName="eye" className="fs-2" />
                          </a>
                          {data.sentiment ? (
                            <a
                              href="#"
                              className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                            >
                              {data.sentiment}
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <img
                        src={data.post_image}
                        className="card-img h-75 w-100"
                        alt="..."
                      />
                    </div>
                  </div>
                  {data.id === idframe && (
                    <>
                      <Modal
                        show={showIframeModal}
                        onHide={() => setShowIframeModal(false)}
                        size="lg"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Iframe</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div>
                            <div>
                              <div>
                                <iframe
                                  srcDoc={`<html><body><p>&lt;iframe src="${data.link}" name="myIFrame" scrolling="auto" width="50%" height="30%" style="border: 10px solid #000000;"&gt;&lt;/iframe&gt;</p></body></html>`}
                                  name="myIFrame"
                                  scrolling="no"
                                ></iframe>
                                <div className="modal-buttons">
                                  <button
                                    className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                                    onClick={copyToClipboard}
                                  >
                                    <FiCopy /> Copy to Clipboard
                                  </button>
                                </div>
                                {copied && (
                                  <div className="copied-message">Copied!</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setShowIframeModal(false)}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                </div>
              ))}
            {/* Share Article Modal */}
            {selectedArticle && (
              <Share
                onClose={() => {
                  setShowShareArticleModal(false);
                  setSelectedArticle(null);
                }}
                articleTitle={selectedArticle.title}
                articleImage={selectedArticle.image}
                articleLink={selectedArticle.link}
              />
            )}
            {/* Share Modal */}
            <Modal
              show={showShareModal}
              onHide={() => setShowShareModal(false)}
              size="lg" // Set size to large
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Share Result</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {state.shareResult ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: state.shareResult }}
                    style={{
                      maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  />
                ) : (
                  <p>Loading...</p>
                )}
              </Modal.Body>
              {/* <Button style={{width:"20%"}} variant="secondary" >
  Copy to Clipboard

    </Button> */}
              <div className="d-flex justify-content-center">
                <button
                  style={{ width: "20%" }}
                  className="btn btn-sm  btn-secondary  px-4 py-2 "
                  onClick={copyToClipboardShareModal}
                >
                  <FiCopy /> Copy to Clipboard
                </button>
                {copie && <div className="copied-message">Copied!</div>}
              </div>

              {/* <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowShareModal(false)}>
      Close
    </Button>
    
   
  </Modal.Footer> */}
            </Modal>
          </div>
          {/* view2 */}

          <div className="col-xl-12">
            {view === "2" && (
              <div className="row">
                {readlater?.map((item: ReadLaterItem, index: number) => (
                  <div
                    key={item.id}
                    className={`col-md-6 mb-4 ${
                      index % 2 === 1 ? "order-md-first" : ""
                    }`}
                  >
                    <div className="card" style={{ maxWidth: "800px" }}>
                      <div className="row no-gutters">
                        <div className="col-md-7">
                          <div className="card-body">
                            <div style={{ width: "30px", height: "30px" }}>
                              <img
                                src={item.source_logo}
                                alt=""
                                className="w-100"
                              />
                            </div>
                            <br />
                            <a
                              className="card-text fw-bold mt-5"
                              href={item.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.title}
                            </a>
                            <h5 className="card-title mt-5">
                              Author : {item.author}
                            </h5>
                            <div className="d-flex mb-3">
                              <p className="card-text">
                                <small>{item.article_language}</small>
                              </p>
                              <p
                                className="card-text"
                                style={{ paddingLeft: "10px" }}
                              >
                                <small>{item.article_country}</small>
                              </p>
                            </div>
                            <h5 className="fw-bold">
                              Bundle Name : {item.bundle}
                            </h5>
                            <p className="card-text">
                              <small>Date Published : {item.posted_on}</small>
                            </p>
                            <p className="card-text">
                              <small>
                                {truncateText(item.summarized_content, 250)}
                              </small>
                            </p>

                            <div className="d-flex align-items-center mb-5">
                              <a
                                href="#"
                                className="px-4 py-2 me-4"
                                onClick={() =>
                                  toggleFavoritePost(item.id, item.liked)
                                }
                              >
                                {item.liked ? (
                                  <BsFillChatSquareHeartFill
                                    style={{
                                      fontSize: "30px",
                                      color: "#E76161",
                                    }}
                                  />
                                ) : (
                                  <BsFillChatSquareHeartFill
                                    style={{ fontSize: "30px" }}
                                  />
                                )}
                              </a>
                              <a
                                href="#"
                                className="px-4 py-2 me-4 "
                                onClick={() => removeReadLaterPost(item.id)}
                              >
                                <AiFillRead
                                  style={{ fontSize: "30px", color: "#E76161" }}
                                />
                              </a>

                              <a
                                className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                                onClick={() => handleIFrameClick(item.id)}
                              >
                                iFrame
                              </a>
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                                onClick={() => handleShare(item.id)}
                              >
                                HTML
                              </a>
                              <a
                                href="#"
                                className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                                onClick={() => handleSaveToPocket(item.link)}
                              >
                                <CiSaveUp2 size={24} />
                                Pocket
                              </a>
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                                onClick={() => handleShareArticle(item)}
                              >
                                <IoMdShare size={24} />
                                Share
                              </a>
                              <a
                                href={`/detailnews/${item.id}`}
                                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                              >
                                <KTIcon iconName="eye" className="fs-2" />
                              </a>
                              {item.sentiment ? ( // Add a condition to check if sentiment exists
                                <a
                                  href="#"
                                  className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                                >
                                  {item.sentiment}
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <img
                            src={item.post_image}
                            className="card-img"
                            alt="..."
                            style={{
                              width: "200px",
                              height: "100px",
                              marginTop: "150px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* view3 */}

          <div>
            {view === "3" && (
              <div className="row align-items-stretch">
                {readlater?.map((data: ReadLaterItem, index: number) => (
                  <div
                    key={index}
                    className={index % 2 === 0 ? "col-lg-8" : "col-lg-4"}
                  >
                    <div className="card mb-4">
                      <div className="row">
                        <div className="col-lg-12 clearfix p-5">
                          <div className="d-flex justify-content-between align-items-center">
                            <h1 className="card-title">
                              <strong className="text-dark">
                                <a
                                  className="text-dark"
                                  href={data.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {data.title}
                                </a>
                              </strong>
                            </h1>
                            {data.sentiment && (
                              <a
                                href="#"
                                className={`btn btn-sm btn-light btn-color-white ${
                                  data.sentiment === "positive"
                                    ? "bg-success"
                                    : data.sentiment === "negative"
                                    ? "btn-danger"
                                    : "btn-warning"
                                } px-4 py-2 me-4`}
                              >
                                {data.sentiment}
                              </a>
                            )}
                          </div>

                          <img src={data.post_image} alt="" className="w-100" />

                          <h5 className="card-title mt-5">
                            Author: {data.author}
                          </h5>

                          <div className="d-flex mb-3">
                            <p className="card-text">
                              <small>Language: {data.article_language}</small>
                            </p>
                            <p
                              className="card-text"
                              style={{ paddingLeft: "10px" }}
                            >
                              <small>Country: {data.article_country}</small>
                            </p>
                          </div>

                          <h5 className="fw-bold">
                            Bundle Name: {data.bundle}
                          </h5>

                          <div>
                            <div style={{ width: "30px", height: "30px" }}>
                              <img
                                src={data.source_logo}
                                alt=""
                                className="w-100 card-img"
                              />
                            </div>
                            <br />

                            <p className="text-dark font-weight-bold">
                              {data.summarized_content &&
                                data.summarized_content.slice(0, 1050)}
                            </p>
                            <p className="card-text">
                              <small>Date Published: {data.posted_on}</small>
                            </p>
                            <div className="d-flex align-items-center mb-5">
                              <a
                                href="#"
                                className="px-4 py-2 me-4"
                                onClick={() =>
                                  toggleFavoritePost(data.id, data.liked)
                                }
                              >
                                {data.liked ? (
                                  <BsFillChatSquareHeartFill
                                    style={{
                                      fontSize: "30px",
                                      color: "#E76161",
                                    }}
                                  />
                                ) : (
                                  <BsFillChatSquareHeartFill
                                    style={{ fontSize: "30px" }}
                                  />
                                )}
                              </a>
                              <a
                                href="#"
                                className="px-4 py-2 me-4 "
                                onClick={() => removeReadLaterPost(data.id)}
                              >
                                <AiFillRead
                                  style={{ fontSize: "30px", color: "#E76161" }}
                                />
                              </a>

                              <a
                                className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                                onClick={() => handleIFrameClick(data.id)}
                              >
                                iFrame
                              </a>
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                                onClick={() => handleShare(data.id)}
                              >
                                HTML
                              </a>
                              <a
                                href="#"
                                className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                                onClick={() => handleSaveToPocket(data.link)}
                              >
                                <CiSaveUp2 size={24} />
                                Pocket
                              </a>
                              <a
                                href="#"
                                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                                onClick={() => handleShareArticle(data)}
                              >
                                <IoMdShare size={24} />
                                Share
                              </a>
                              <a
                                href={`/detailnews/${data.id}`}
                                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                              >
                                <KTIcon iconName="eye" className="fs-2" />
                              </a>
                              {data.sentiment ? ( // Add a condition to check if sentiment exists
                                <a
                                  href="#"
                                  className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                                >
                                  {data.sentiment}
                                </a>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* VIEW 4 */}
          <div
            className="card mb-5 "
            style={{
              width: "70rem",
            }}
          >
            {view === "4" &&
              readlater.map((data: ReadLaterItem) => (
                <div
                  className="card mb-5 "
                  style={{
                    width: "70rem",
                  }}
                >
                  <div className="card-body ">
                    <div>
                      <img
                        src={data.post_image}
                        alt=""
                        style={{
                          height: "400px",
                          width: "700px",
                          objectFit: "cover",
                          borderRadius: "20px",
                        }}
                      />
                    </div>
                    <br></br>
                    <div
                      style={{ width: "30px", height: "30px" }}
                      className=" mt-5 mb-2"
                    >
                      <img src={data.source_logo} alt="" className="w-100" />
                    </div>
                    <br></br>
                    <a
                      className="card-text  fw-bold mt-5"
                      href={data.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data.title}
                    </a>
                    <h5 className="card-title mt-5">Author : {data.author}</h5>
                    <div className="d-flex mb-3">
                      <p className="card-text">
                        <small>Language : {data.article_language}</small>
                      </p>
                      <p className="card-text" style={{ paddingLeft: "10px" }}>
                        <small>Country : {data.article_country}</small>
                      </p>
                    </div>

                    <h5 className="fw-bold">Bundle Name : {data.bundle}</h5>
                    {/* <p className="card-text">{data.title}</p> */}
                    <p className="card-text">
                      <small>Date Published : {data.posted_on}</small>
                    </p>
                    <p className="card-text">
                      <small>
                        {truncateText(data.summarized_content, 250)}
                      </small>
                    </p>

                    <div className="d-flex align-items-center mb-5">
                      <a
                        href="#"
                        className="px-4 py-2 me-4"
                        onClick={() => toggleFavoritePost(data.id, data.liked)}
                      >
                        {data.liked ? (
                          <BsFillChatSquareHeartFill
                            style={{ fontSize: "30px", color: "#E76161" }}
                          />
                        ) : (
                          <BsFillChatSquareHeartFill
                            style={{ fontSize: "30px" }}
                          />
                        )}
                      </a>
                      <a
                        href="#"
                        className="px-4 py-2 me-4 "
                        onClick={() => removeReadLaterPost(data.id)}
                      >
                        <AiFillRead
                          style={{ fontSize: "30px", color: "#E76161" }}
                        />
                      </a>
                      <a
                        className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                        onClick={() => handleIFrameClick(data.id)}
                      >
                        iFrame
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        onClick={() => handleShare(data.id)}
                      >
                        HTML
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                        onClick={() => handleSaveToPocket(data.link)}
                      >
                        <CiSaveUp2 size={24} />
                        Pocket
                      </a>
                      <a
                        href="#"
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        onClick={() => handleShareArticle(data)}
                      >
                        <IoMdShare size={24} />
                        Share
                      </a>
                      <a
                        href={`/detailnews/${data.id}`}
                        className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                      >
                        <KTIcon iconName="eye" className="fs-2" />
                      </a>
                      {data.sentiment ? ( // Add a condition to check if sentiment exists
                        <a
                          href="#"
                          className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                        >
                          {data.sentiment}
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* VIEW 5 */}
          {view === "5" && readlater.length > 0 ? (
            <div
              className="card mb-3"
              style={{ width: "60rem", margin: "auto" }}
            >
              <Carousel>
                {readlater.map((data: ReadLaterItem, index: number) => (
                  <Carousel.Item key={index}>
                    <div className="card-body">
                      <div style={{ width: "30px", height: "30px" }}>
                        <img src={data.source_logo} alt="" className="w-100" />
                      </div>
                      <br />
                      <a
                        className="card-text fw-bold mt-5"
                        href={data.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.title}
                      </a>
                      <h5 className="card-title mt-5">
                        Author : {data.author}
                      </h5>
                      <div className="d-flex mb-3">
                        <p className="card-text">
                          <small>Language : {data.article_language}</small>
                        </p>
                        <p
                          className="card-text"
                          style={{ paddingLeft: "10px" }}
                        >
                          <small>Country : {data.article_country}</small>
                        </p>
                      </div>
                      <h5 className="fw-bold">Bundle Name : {data.bundle}</h5>
                      <p className="card-text">
                        <small>Date Published : {data.posted_on}</small>
                      </p>
                      <p className="card-text">
                        <small>
                          {truncateText(data.summarized_content, 250)}
                        </small>
                      </p>
                      <div style={{ height: "40rem", objectFit: "cover" }}>
                        <img
                          src={data.post_image}
                          className="card-img-top h-100"
                          alt="..."
                        />
                      </div>{" "}
                      <div className="d-flex align-items-center mb-5">
                        <a
                          href="#"
                          className="px-4 py-2 me-4"
                          onClick={() =>
                            toggleFavoritePost(data.id, data.liked)
                          }
                        >
                          {data.liked ? (
                            <BsFillChatSquareHeartFill
                              style={{ fontSize: "30px", color: "#E76161" }}
                            />
                          ) : (
                            <BsFillChatSquareHeartFill
                              style={{ fontSize: "30px" }}
                            />
                          )}
                        </a>
                        <a
                          href="#"
                          className="px-4 py-2 me-4 "
                          onClick={() => removeReadLaterPost(data.id)}
                        >
                          <AiFillRead
                            style={{ fontSize: "30px", color: "#E76161" }}
                          />
                        </a>
                        <a
                          className="btn btn-sm  btn-primary  px-4 py-2 me-4"
                          onClick={() => handleIFrameClick(data.id)}
                        >
                          iFrame
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          onClick={() => handleShare(data.id)}
                        >
                          HTML
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm d-flex justify-content-center align-items-center btn-primary  px-4 py-2 me-4"
                          onClick={() => handleSaveToPocket(data.link)}
                        >
                          <CiSaveUp2 size={24} />
                          Pocket
                        </a>
                        <a
                          href="#"
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                          onClick={() => handleShareArticle(data)}
                        >
                          <IoMdShare size={24} />
                          Share
                        </a>
                        <a
                          href={`/detailnews/${data.id}`}
                          className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
                        >
                          <KTIcon iconName="eye" className="fs-2" />
                        </a>
                        {data.sentiment ? (
                          <a
                            href="#"
                            className="btn btn-sm btn-light btn-color-white btn-danger px-4 py-2 me-4"
                          >
                            {data.sentiment}
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          ) : null}

          <div></div>
        </div>
      ) : (
        <p className="d-flex justify-content-center">
          Read-Later Posts Not Added
        </p>
      )}
      {renderPagination()}
    </div>
  );
};

export default ReadLater;

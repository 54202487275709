import React, { useEffect, useState } from "react";
import axios from "axios";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";

type Props = {
  className: string;
};

type SourceData = {
  source_name: string;
  source_count: string;
  source_logo: string;
};

const DashboardResults: React.FC<Props> = ({ className }) => {
  const [sources, setSources] = useState<SourceData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/get_feeds`,
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );
        // console.log("This is response for feed ", response);
        const { data } = response;

        if (data.status === 200 && Array.isArray(data.result)) {
          const newSources: SourceData[] = data.result.map(
            (sourceData: any) => ({
              source_name: sourceData.source_name,
              source_count: Number(sourceData.source_count), // Convert to number
              source_logo: sourceData.source_logo,
            })
          );

          setSources(newSources);
        } else {
          console.error("Error: Invalid API response data", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set isLoading to false, whether the request was successful or not
      }
    };

    fetchData();
  }, []);

  const constructURL = (source: string) => `/news/pages/${source.toLowerCase()}`;

  return (
    <div style={{ height: "850px", overflowY: "auto", marginBottom: "10" }}>
      <div className={`card ${className}`}>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">User Feed Counts</span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              From the below sources
            </span>
          </h3>
        </div>
        <div className="card-body py-3">
          {isLoading ? ( // Show loader if isLoading is true
            <div className="text-center" style={{marginTop:"120px"}}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                    <thead>
                      <tr className="border-0">
                        <th className="p-0 w-50px"></th>
                        <th className="p-0 min-w-150px"></th>
                        <th className="p-0 min-w-140px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {sources.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <div className="symbol symbol-25px me-2">
                              <span className="symbol-label">
                                <img
                                  src={toAbsoluteUrl(data.source_logo)}
                                  className="h-50 align-self-center"
                                  alt=""
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a
                              href={constructURL(data.source_name)}
                              className="ms-2 d-flex justify-content-between text-dark fw-bold text-hover-primary mb-1 fs-6"
                            >
                              <span>{data.source_name}</span>
                              {/* <span>-</span> */}
                              <span>{data.source_count}</span>
                            </a>
                          </td>
                          <td className="text-end">
                            <span className="badge badge-light-primary"></span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardResults;

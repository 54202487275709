/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTIcon } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useState } from "react";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";
import axios from "axios";
import { useEffect } from "react";
import { useZoom } from "../../../partials/layout/zoom/ZoomContext";
export function AsideMenuMain(Props: any) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const temp = JSON.parse(localStorage.getItem("Enable") || "[]");
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const { zoomLevel } = useZoom();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/article/newsbycount_v1`,
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );
        const data = response.data;
        // console.log('API news Response:', response.data);

        if (response.data.status === 204) {
          setError("Create Bundle to Get count");
          setMenuItems([]);
        } else {
          if (response.data.message === "Counts not Found") {
            setError("Create Bundle to Get count");
            setMenuItems([]);
          } else {
            const newMenuItems = data.result.map((item: any) => {
              return (
                <AsideMenuItem
                  key={item.source_name}
                  to={`/user/${item.source_name.toLowerCase()}`}
                  icon="shield-tick"
                  title={item.source_name}
                  enable={temp.includes(item.source_name) ? true : false}
                />
              );
            });
            setMenuItems(newMenuItems);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Create Bundle to Get count");
      } finally {
        setIsLoading(false);
      }
    };

    if (!dataFetched && menuItems.length === 0 && !error) {
      fetchData();
      setDataFetched(true);
    }
  }, [menuItems, temp, error, dataFetched]);

  return (
    <>
      {/* <div  style={{ transform: `scale(${zoomLevel})` }} > */}
      {/* ----------Dashboard--------- */}
      <AsideMenuItem
        to="user/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
      />
      {/* ----------My Feeds--------- */}
      {/* <AsideMenuItem to="/user/search" icon="search-list" title="Search" />{" "} */}
      <AsideMenuItem to="/user/feeds" icon="paper-clip" title="My Feeds" />{" "}
      {/* <AsideMenuItem to="/user/getpocket" icon="shield-tick" title="Get Pocket" />{" "} */}
      <AsideMenuItem
        to="user/article/fetcharticle"
        icon="shield-tick"
        title="Get Pocket"
      />{" "}
      <AsideMenuItem to="/user/searchgpt" icon="switch" title="Search Gpt" />{" "}
      {/* <AsideMenuItem to="user/article/fetcharticle" icon="shield-cross" title="Get Pocket Article" />{" "} */}
      {/* <AsideMenuItem to="/user/keyword" icon="shield-tick" title="Keyword" />{" "}
      // <AsideMenuItem to="/user/blacklisted" icon="shield-cross" title="Blacklisted" />{" "} */}
      {/* ----------Home--------- */}
      {/* <AsideMenuItem to="/builder" icon="switch" title="Layout Builder" /> */}
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-white text-uppercase fs-8 ls-1">
            Home
          </span>
        </div>
      </div>
      <AsideMenuItem to="/user/favourite" icon="element-4" title="Favourites" />
      <AsideMenuItem
        to="/user/read-later"
        icon="element-4"
        title="Read Later"
      />{" "}
      <AsideMenuItem to="/user/bundle" icon="element-4" title="Bundles" />{" "}
      <AsideMenuItem
        to="/user/sourceschedule"
        icon="element-4"
        title="Sources Schedule"
      />{" "}
      <AsideMenuItem to="/user/upgrade" icon="element-4" title="Upgrade" />{" "}
      {/* ----------News--------- */}
      <div className="menu-item">
        {/* <div className="menu-content pt-8 pb-2">
          <span className="menu-section  text-uppercase fs-8 ls-1 text-white">
            News
          </span>
        </div> */}
      </div>
      {/* -----------Setting---------- */}
      {/* {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-white" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error ? (
        <div className="text-center mt-3 text-white">{error}</div> // Display the error message on the UI
      ) : (
        menuItems
      )} */}
      <AsideMenuItem to="/user/setting" icon="setting" title="Settings" />{" "}
      {/* -----------Billing---------- */}
      {/* <AsideMenuItem to="/user/billing" icon="wallet" title="Billing" />{" "} */}
      {/* </div>  */}
    </>
  );
}

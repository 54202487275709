import React, { FC, useEffect, useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { Dropdown1 } from "../../../_metronic/partials/content/dropdown/Dropdown1";
import { LatestFeed } from "./LatestFeed";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import axios from "axios";
import authHeader from "../../auth-header";
import { AxiosRequestHeaders } from "axios";
import { Setting } from "./Setting";
import IframeWrapper from "./Iframe/IframeWrapper";
import { useZoom } from "../../../_metronic/partials/layout/zoom/ZoomContext";
import { AddFeed } from "../../../_metronic/partials";
import Newmyfeed from "./Newmyfeed";

type FeedItem = {
  id: number;
  source_type: string;
  url: string;
  domain: string;
  date_time: string;
  source_name: string;
  user: number;
  source_image: string;
  feed_id: number;
  feed_keyword: string;
  feed_title: string;
  blacklist_keyword: string;
  interval_type: string;
  is_enabled: boolean;
  bundle_title: string;
};

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "My Feeds",
    path: "/news/myfeed",
    isSeparator: false,
    isActive: false,
  },
];

const MyFeed: FC = () => {
  // const [selectedKeywordId, setSelectedKeywordId] = useState(null);

  // const [view, setView] = useState<string>("1");
  // const [feeds, setFeeds] = useState<FeedItem[] | null>(null);
  // const [isLoading, setIsLoading] = useState(true);
  // const { zoomLevel } = useZoom();
  // const [isAddFeedOpen, setIsAddFeedOpen] = useState(false);
  // const toggleAddFeed = () => {
  //   console.log("Toggle Add Feed called");
  //   setIsAddFeedOpen(!isAddFeedOpen);
  // };
  // console.log("isAddFeedOpen:", isAddFeedOpen);

  // useEffect(() => {
  //   // console.log("auth",authHeader())

  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/policy/get_feeds_v1`, {
  //       headers: authHeader() as AxiosRequestHeaders,
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         const fetchedFeeds = response.data.result;
  //         setFeeds(fetchedFeeds);
  //         console.log("Feeds:", fetchedFeeds);
  //       } else if (response.status === 401) {
  //         console.log("Feeds:", response.data);

  //         window.location.href = "/auth/login";
  //         window.location.reload();
  //       } else {
  //         console.error("Error fetching feeds. Status:", response.status);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching feeds:", error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  return (
    <div>
      <PageTitle>My Feeds</PageTitle>
      {/* <div className="d-flex justify-content-end mt-2">
        <a
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_add_feed"
        href="/myfeed"
          // href="#"
          className="btn btn-sm btn-light-primary"
          onClick={toggleAddFeed}
        >
          <KTIcon iconName="plus" className="fs-3" />
          Add Feed
        </a>
      </div> */}
      {/* {isAddFeedOpen && console.log("Add Feed Popup Open")} */}

      {/* {view === "1" ? (
        <div className="row g-5 g-xl-12 mt-2">
          <div className="col-xl-12">
            {isLoading ? (
              <div className="text-center mt-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : feeds === null ? (
              <div className="text-center mt-5">
              </div>
            ) : (
              feeds.map((data) => (
                <div key={data.id} className="col-lg-10">
                  <LatestFeed
                    className=""
                    source_type={data.source_type}
                    title={data.source_name}
                    url={data.url}
                    date_created={data.date_time}
                    date_accessed={data.date_time}
                    domain={data.domain}
                    source_image={data.source_image}
                    feed_id={data.feed_id}
                    feed_title={data.feed_title}
                    feed_keyword={data.feed_keyword}
                    blacklist_keyword={data.blacklist_keyword}
                    interval_type={data.interval_type}
                    is_enabled={data.is_enabled}
                    bundle_title={data.bundle_title}
                  />
                </div>
              ))
            )}
          </div>
          <div className="col-xl-4">
            <div className="container overflow-y-scroll mh-75"></div>
          </div>
        </div>
      ) : (
        <div>
          {feeds === null ? (
            <div className="text-center mt-5">
              {/* <p>Loading...</p> */}
      {/* </div>
          ) : (
            <div className="row mt-5">
              {feeds.map((data) => (
                <div key={data.id} className="col-lg-6">
                  <LatestFeed
                    className=""
                    source_type={data.source_type}
                    title={data.source_name}
                    url={data.url}
                    date_created={data.date_time}
                    date_accessed={data.date_time}
                    domain={data.domain}
                    source_image={data.source_image}
                    feed_id={data.feed_id}
                    feed_title={data.feed_title}
                    feed_keyword={data.feed_keyword}
                    blacklist_keyword={data.blacklist_keyword}
                    interval_type={data.interval_type}
                    is_enabled={data.is_enabled}
                    bundle_title={data.bundle_title}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      // )} */}

      <Newmyfeed />
      {/* {isAddFeedOpen && <AddFeed />} */}
    </div>
  );
};

export { MyFeed };

import React, { useState } from 'react';
import Nav from './Nav';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../../../_metronic/helpers';

const HeaderOne = () => {

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const OffcanvasHandleClose = () => setShowOffcanvas(false);
    const OffcanvasHandleShow = () => setShowOffcanvas(true);

    // const sticky = StickyHeader(100);

    const MobileMenuHandler = () => {
        document.querySelector('.mobilemenu-popup').classList.toggle("show");
        document.querySelector('body').classList.toggle("mobilemenu-show");

        var elements = document.querySelectorAll('.mobilemenu-popup .menu-item-has-children > a');
    
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.axil-submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    }


    return (
        <>
        
             
                 
                     
                
                            <div className="  ">
                              
                                <Nav/>
                            </div>
                           
                 
                   
            
          
          
        </>
    )
}

export default HeaderOne;
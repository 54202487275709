import React, { useEffect, useState } from "react";
import axios from "axios";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";

type Props = {
  className: string;
};

type SourceData = {
  source: string;
  result: string;
  icon: string;
};
const xImageUrl =
  // "https://www.google.com/images/branding/googlelogo/2x/googlelogo_light_color_92x30dp.png";
  "/media/logos/noimage.png";
const FeedResultWidget: React.FC<Props> = ({ className }) => {
  const [sources, setSources] = useState<SourceData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/article/newsbycount_v1`,
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );
        console.log("feeds resul tnews ", response.data);
        console.log("Feed result news count:", response.data.result.length);
        console.log("Source counts:");
        response.data.result.forEach((item: any) => {
          console.log(item.source_count);
        });
        if (response.status === 200) {
          const { result } = response.data;

          const newSources: SourceData[] = result.map((sourceData: any) => {
            const absoluteImageUrl = toAbsoluteUrl(sourceData.source_logo);
            // console.log("Absolute URL for image:", absoluteImageUrl);
            // console.log("sourceData:", sourceData);
            console.log("Source count:", sourceData.source_count.length);

            return {
              source: sourceData.source_name,
              result: sourceData.source_count,
              icon: sourceData.source_logo,
            };
          });
          setSources(newSources);

          setSources(newSources);
        } else if (response.status === 401) {
          window.location.href = "/auth/login";
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Sort sources array by result (count) in descending order (larger to smaller)
  const sortedSources = [...sources].sort(
    (a, b) => parseInt(b.result) - parseInt(a.result)
  );

  const constructURL = (source: string) => `/user/${source.toLowerCase()}`;

  return (
    <div
      className={`card  ${className}`}
      style={{
        marginTop: "10px",
        overflowX: "hidden",
        width: "250px",
        height: "610px",
      }}
    >
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Feed Results</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            From the below sources
          </span>
        </h3>
      </div>
      <div className="card-body py-3">
        <div>
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="table-responsive">
                {sortedSources.length === 0 ? (
                  <div className="text-center mt-4">No Sources Found</div>
                ) : (
                  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                    {/* <thead>
                      <tr className="border-0">
                        <th className="p-0 w-50px"></th>
                        <th className="p-0 min-w-150px"></th>
                        <th className="p-0 min-w-140px"></th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {sortedSources.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <div className="symbol symbol-25px me-2">
                              <span className="symbol-label">
                                {/* <img
                                  src={toAbsoluteUrl(data.icon) || xImageUrl}
                                  className="h-50 align-self-center"
                                  alt=""
                                /> */}
                                <img
                                  src={
                                    data.icon !== null
                                      ? toAbsoluteUrl(data.icon)
                                      : xImageUrl
                                  }
                                  className="h-50 align-self-center"
                                  alt=""
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a
                              href={constructURL(data.source)}
                              className="ms-2 d-flex justify-content-between text-dark fw-bold text-hover-primary mb-1 fs-6"
                            >
                              <span>{data.source}</span>
                              <span>{data.result}</span>
                            </a>
                          </td>
                          <td className="text-end">
                            <span className="badge badge-light-primary"></span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedResultWidget;

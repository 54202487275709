// @flow
import * as React from "react";
import axios from "axios";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "Setting Page",
    path: "/setting",
    isSeparator: false,
    isActive: false,
  },
];

interface User {
  first_name: string;
  last_name: string;
  email: string;
  status: boolean;
}

const User: React.FC = () => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchUsers = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${apiUrl}/api/admin/get_users`, {
        headers: authHeader() as AxiosRequestHeaders,
      });
      setUsers(response.data.result);
      setIsLoading(false); // Set isLoading to false after data is fetched
      // console.log("This is ", response)
    } catch (error) {
      setIsLoading(false); // Set isLoading to false even if there's an error
      console.error("Error fetching users:", error);
    }
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="card">
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">User Details</span>
          {/* <span className="text-muted mt-1 fw-semibold fs-7">History</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {isLoading ? ( // Show loader if isLoading is true
          <div className="text-center" style={{paddingTop:"120px"}}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">First Name</th>
                    <th className="min-w-150px">Last Name</th>
                    <th className="min-w-150px">Email</th>
                    <th className="min-w-120px">Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {users.map((user) => {
                    return (
                      <tr key={user.email}>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary fs-6">
                            {user?.first_name}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary fs-6">
                            {user?.last_name}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                            {user?.email}
                          </span>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">
                            {/* Display package_type here if needed */}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark fw-bold text-hover-primary d-block fs-6">
                            {user?.status ? "Active" : "Inactive"}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default User;

import React from 'react';
import Header from './Header';
import Herosection from './Herosection';
import Carouselnews from './Carouselnews';
import Footerabove from './Footerabove';
import FooterOne from '../landingpage/FooterOne';



// import FooterOne from './FooterOne';

// import HeadlineNews from './Main/Headlinenews';
// import News from './News';
// import AboveFooterArticles from './Abovefooter';
const Privatelanding = () => {

    return (
        <>
     
        <main className="main-wrapper"style={{overflowX:"hidden"}}>
           
           <Header/>
            <div style={{marginTop:"100px"}}>
            <Herosection/>
            </div>
        <div className='d-flex justify-content-center align-items-center'> 
            <Carouselnews/>
        </div> 
        <Footerabove/>
        <FooterOne/>
        {/* <News/>
     <AboveFooterArticles/>
           <FooterOne/> */} 
   
        </main>
        </>
    )
}

export default Privatelanding;


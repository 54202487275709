import React, { useEffect } from 'react';
import axios, { AxiosRequestHeaders } from 'axios'; 
import { useLocation ,Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../../../auth-header';
import { RiCheckboxCircleFill } from "react-icons/ri"; // Importing React Icons
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Success: React.FC = () => {
  const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const sessionId = urlParams.get('session_id');
      const planId = urlParams.get('plan_id');
      console.log("Session ID:", sessionId); 
      console.log("Plan ID:", planId); 
  
      if (sessionId && planId) {
        const formData = {
          session_id: sessionId,
          plan_id: planId
        };
  
        const sendSuccessData = async () => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/policy/payment_success`,
              formData,
              { headers: authHeader() as AxiosRequestHeaders }
            );
            if (response.status === 200) {
              toast.success('Payment successful!', { autoClose: 5000 });
            }
          } catch (error) {
            console.error('Error:', error);
            toast.error('Error processing payment. Please try again.');
          }
        };
  
        sendSuccessData();
      } else {
        console.error("Session ID or Plan ID is null.");
      }
    }, [location.search]);

  return (
    <div className='container'>
  
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="mt-5 text-center">
        <RiCheckboxCircleFill className="text-success" size={50} />
        <h2 className="mt-3">Congratulations</h2>
        <div className="mb-5 mt-3">
          <p>Your payment was successful.</p>
        </div>
      </div>
      <div className=" row text-center">
        <Link to="/user/dashboard">
          <Button
            variant="primary"
            className="btn-lg w-100 mb-2"
            onClick={() => navigate("/user/home")}
          >
            Go to dashboard
          </Button>
        </Link>
      </div>
    </div>
    </div>
  );
};

export default Success;

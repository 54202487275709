/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { KTIcon } from "../../../helpers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify"; 

interface Source {
  id: number;
  platform: string;
  domain: string;
  source_type: string;
}

interface ContactFormData {
  keyword: string;
  email: string;
}

const AddKeyword: FC = () => {
  const [platforms, setPlatforms] = useState<Source[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [apiResponse, setApiResponse] = useState<{ status: number; message: string } | null>(null);
  // const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const initialValues: ContactFormData = { keyword: "", email: "" };

  useEffect(() => {
    fetchPlatforms();
  }, []);

  const fetchPlatforms = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_bundles_sources`, {
        headers: authHeader() as AxiosRequestHeaders,
      });
      const data = response.data;

      if (data.status === 200) {
        setPlatforms(data.result);
        setSelectedPlatform(data.result.length > 0 ? data.result[0].platform : "");
      } else if (data.status === 400) {
        setApiResponse(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values: ContactFormData) => {
    try {
      const selectedSource = platforms.find((platform) => platform.platform === selectedPlatform);
      if (selectedSource) {
        const requestBody = {
          addkeywords: values.keyword,
          sourceid: selectedSource.id,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/policy/createkeywords`,
          requestBody,
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );

        const responseData = response.data;
        if (response.status === 200) {
          toast.success(responseData.message, { autoClose: 3000 });
                    // setSuccessMessage(responseData.message);
          window.location.reload();
        } else {
          alert("Keyword creation failed. Please try again later.");
        }
      } else {
        alert("Selected source not found.");
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while adding the keyword. Please try again later.");
    }
  };

  return (
    <div className="modal fade" id="kt_modal_add_keyword" aria-hidden="true">
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Add Keyword</h1>
            </div>
            {apiResponse && apiResponse.status === 400 && (
              <div className="text-center text-danger">{apiResponse.message}</div>
            )}
            {/* {successMessage && (
              <div className="text-center text-success">{successMessage}</div>
            )} */}
            {!apiResponse && 
            // !successMessage &&
             (
              <div>
                <div className="d-flex flex-row justify-content-between">
                  <div className="align-items-center">
                    <label
                      className="required form-label fs-6 fw-bolder text-dark"
                    >
                      Select Platform
                    </label>
                    <select
                      className="form-select form-select-sm form-select-solid w-100px w-xxl-125px"
                      data-control="select2"
                      data-placeholder="Latest"
                      data-hide-search="true"
                      value={selectedPlatform}
                      onChange={(e) => setSelectedPlatform(e.target.value)}
                    >
                      {platforms.map((platform) => (
                        <option key={platform.id} value={platform.platform}>
                          {platform.platform}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
              keyword: Yup.string().required("Keyword Title is required"),
            })}
          >
            <Form>
              <div className="d-flex flex-row mt-5 justify-content-between flex-sm-row flex-column">
                <div>
                  <label
                    htmlFor="keyword"
                    className="required form-label fs-6 fw-bolder text-dark"
                  >
                    Keyword Title:
                  </label>
                  <Field
                    type="text"
                    id="keyword"
                    name="keyword"
                    className="form-control bg-transparent"
                  />
                  <ErrorMessage name="keyword" component="div" className="text-danger" />
                </div>
              </div>
              <div className="text-center mt-13">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary me-3"
                >
                  Add Keyword
                </button>
              </div>
            </Form>
          </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AddKeyword };

import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-phone-number-input/style.css";
import ReactTimezoneSelect from "react-timezone-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authHeader from "../../../auth-header";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";

const Resetpassword = () => {
  const initialFormData = {
    new_password: "",
    passwordConfirmation: "",
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "red" : "blue",
      padding: 20,
      backgroundColor: "black",
    }),
  };
  const [formData, setFormData] = useState(initialFormData);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isProfileCreated, setIsProfileCreated] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setHasChanges(true);
  };

  const showNoChangesToast = () => {
    toast.warn("No changes made.", {
      position: "top-right",
      autoClose: 3000,
    });
  };
  const location = useLocation();

  // Access the search property from the location object
  const searchParams = new URLSearchParams(location.search);

  // Now you can get individual search parameters
  const token = searchParams.get("token");
  // console.log(token);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validation checks
    if (!formData.new_password) {
      toast.error("Please fill in all fields.");
      return;
    }
    if (formData.new_password.length < 8) {
      toast.error("Password must be at least 8 characters long.");
      return;
    }
    if (!formData.passwordConfirmation) {
      toast.error("Please Enter Password Confirmation.");
      return;
    }
    if (formData.new_password !== formData.passwordConfirmation) {
      toast.error("Passwords do not match.");
      return;
    }

    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/user_portal/api/reset_password?token=${token}`,
        formDataToSubmit,
        {
          headers: {
            ...authHeader(),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log("API Response after submission:", response.data);

        if (response.data.status === 200) {
          toast.success(successMessage);
          setErrorMessage("");
          setTimeout(() => {
            setSuccessMessage(response.data.message);
          }, 3000);
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
          setErrorMessage("");
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        } else {
          setErrorMessage("Failed to update profile. Please try again.");
          setSuccessMessage("");
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        setErrorMessage("An error occurred while updating the profile.");
        setSuccessMessage("");
      });
  };

  return (
    <div className="container">
      <form>
        <div className="text-center mb-10">
          <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            Enter your current and new password.
          </div>
        </div>
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            New Password
          </label>
          <div className="input-group">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder=""
              autoComplete="off"
              className="form-control"
              id="new_password"
              name="new_password"
              value={formData.new_password}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="d-flex justify-content-center align-items-center btn btn-primary btn-outline-secondary"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {" "}
            Password Confirmation
          </label>
          <div className="input-group">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder=""
              autoComplete="off"
              className="form-control"
              id="passwordConfirmation"
              name="passwordConfirmation"
              value={formData.passwordConfirmation}
              onChange={handleInputChange}
            />
            <button
              type="button"
              className="d-flex justify-content-center align-items-center btn btn-primary btn-outline-secondary"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        <div className=" justify-content-center d-flex gap-5">
          <button
            onClick={handleSubmit}
            className="btn btn-lg btn-primary  mb-5"
          >
            Submit{" "}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-primary  mb-5"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Resetpassword;

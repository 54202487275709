/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { Link } from "react-router-dom";
type Props = {
  className: string;
};

const FeedsWidget6: React.FC<Props> = ({ className }) => {
  return (
    <Link to="/myfeed/feedlist" className="link-primary">
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className="card-body pb-0">
          {/* begin::Header */}
          <div className="d-flex align-items-center mb-5">
            {/* begin::User */}
            <div className="d-flex align-items-center flex-grow-1">
              {/* begin::Avatar */}

              {/* end::Avatar */}

              {/* begin::Info */}
              <div className="d-flex flex-column">
                <a
                  href="#"
                  className="text-gray-800 text-hover-primary fs-6 fw-bold"
                >
                  Mat Dillon
                </a>
                <span className="text-gray-400 fw-semibold">Last month</span>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* begin::Menu */}

            {/* end::Menu */}
          </div>
          {/* end::Header */}

          {/* begin::Post */}
          <div className="mb-6">
            {/* begin::Text */}
            <div className="text-gray-800 fs-6 fw-normal mb-5">
              Warren Buffet is Missing Out on This Year Marke ...
            </div>
            {/* end::Text */}

            {/* begin::Video */}
            <div className="mb-5">
              <iframe
                title="widget11-video"
                className="embed-responsive-item rounded h-300px w-100"
                src="https://www.youtube.com/embed/qIHXpnASPAA"
                allowFullScreen={true}
              />
            </div>
            {/* end::Video */}

            {/* begin::Toolbar */}
            <div className="d-flex align-items-center mb-5">
              <a
                href="#"
                className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4"
              >
                <KTIcon iconName="eye" className="fs-3" />
              </a>
            </div>
            {/* end::Toolbar */}
          </div>
          {/* end::Bottom */}

          {/* begin::Separator */}
          <div className="separator mb-4"></div>
          {/* end::Separator */}

          {/* begin::Reply input */}
          {/* <form className='position-relative mb-6'>
          <textarea
            className='form-control border-0 p-0 pe-10 resize-none min-h-25px'
            data-kt-autosize='true'
            rows={1}
            placeholder='Reply..'
          ></textarea>

          <div className='position-absolute top-0 end-0 me-n5'>
            <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
              <KTIcon iconName='paper-clip' className='fs-3 mb-3' />
            </span>

            <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
              <KTIcon iconName='geolocation' className='fs-2 mb-3' />
            </span>
          </div>
        </form> */}
          {/* edit::Reply input */}
        </div>
        {/* end::Body */}
      </div>
    </Link>
  );
};

export { FeedsWidget6 };

import React, { useEffect, useState } from 'react';
import { useAuth } from "../../../../app/modules/auth";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu, Search } from "../../../partials";
import axios from 'axios'; 
import authHeader from '../../../../app/auth-header';

const AsideToolbar = () => {
  const { currentUser } = useAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/user_portal/api/get_user_profile`, {
      headers: authHeader(),
    })
    .then(response => {
console.log("ADMIN",response.data)
      const { full_name, email } = response.data;

      setUserInfo({ full_name, email });
    })
    .catch(error => {
      // Handle errors
      console.error("Error fetching user profile: ", error);
    });
  }, []);

  return (
    <>
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-5">
        {/* <div className="symbol symbol-50px">
          <img src={toAbsoluteUrl("/media/avatars/300-1.jpg")} alt="" />
        </div> */}
        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          <div className="d-flex">
            <div className="flex-grow-1 me-2">
              <a
                href="#"
                className="text-white text-hover-primary fs-6 fw-bold"
              >
                {currentUser?.first_name} 
              </a>
              {userInfo && (
                <div>
                  <span className="text-gray-600 fw-bold d-block fs-8 mb-1">
                    {userInfo.first_name}
                  </span>
                  <span className="text-gray-600 fw-bold d-block fs-8 mb-1">
                    {userInfo.email}
                  </span>
                </div>
              )}
              {/* <div className="d-flex align-items-center text-success fs-9">
                <span className="bullet bullet-dot bg-success me-1"></span>
                online
              </div> */}
            </div>
            <div className="me-n2">
              <a
                href="#"
                className="btn btn-icon btn-sm btn-active-color-primary mt-n2"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-overflow="false"
              >
                <KTIcon iconName="setting-2" className="text-muted fs-1" />
              </a>
              <HeaderUserMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AsideToolbar };

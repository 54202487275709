// AsideMenuItem.tsx
import React, { FC } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTIcon, WithChildren } from '../../../helpers';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  enable?: boolean;
};

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  enable,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <div className={enable ? 'menu-item nav-link disabled' : 'menu-item'}>
      {enable ? (
        ''
      ) : (
        <Link className={clsx('menu-link without-sub', { active: isActive })} to={to}>
          {hasBullet && (
            <span className="menu-bullet">
              <span className="bullet bullet-dot"></span>
            </span>
          )}
          {icon && (
            <span className="menu-icon">
              <KTIcon iconName={icon} className="fs-2" />
            </span>
          )}
          {fontIcon && <i className={clsx('bi fs-3', fontIcon)}></i>}
          <span className={clsx('menu-title', { 'text-light': isActive })}>{title}</span>
        </Link>
      )}

      {children}
    </div>
  );
};

export { AsideMenuItem };

import React, { FC, useState } from "react";
import { KTIcon } from "../../../helpers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import authHeader from "../../../../app/auth-header";
import { AxiosRequestHeaders } from "axios";
import Modal from "react-modal";

interface Source {
  id: number;
  platform: string;
  domain: string;
  source_type: string;
}

interface ContactFormData {
  keyword: string;
  email: string;
}

interface UpdateKeywordProps {
  isOpen: boolean;
  onClose: () => void;
  keywordId: number | null;
}

const validationSchema = Yup.object().shape({
  keyword: Yup.string().required("Please enter a keyword"),
  email: Yup.string().email("Please enter a valid email address").required("Please enter your email"),
});

const customStyles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
  },
};

const UpdateKeyword: FC<UpdateKeywordProps> = ({ isOpen, onClose, keywordId }) => {
  const [platforms, setPlatforms] = useState<Source[]>([]);
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");

  const initialValues: ContactFormData = { keyword: "", email: "" };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const requestBody = {
        keyword: values.keyword,
        source_id: keywordId as number, // Use keywordId instead of selectedKeywordId
      };

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/policy/update_new_keyword/${keywordId}`,
        requestBody,
        {
          headers: authHeader() as AxiosRequestHeaders,
        }
      );

      alert("Keyword updated successfully!");
      onClose(); // Close the modal
    } catch (error) {
      console.error(error);
      alert("An error occurred while updating the keyword. Please try again later.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Add Blacklist Keyword Modal"
      ariaHideApp={false}
      style={customStyles}
    >
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose}>
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Update Keyword</h1>
            </div>

            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <Form>
                  <div className="d-flex flex-row mt-5 justify-content-between flex-sm-row flex-column">
                    <div>
                      <label
                        htmlFor="keyword"
                        className="required form-label fs-6 fw-bolder text-dark"
                      >
                        Enter New Keyword Name
                      </label>
                      <Field
                        type="text"
                        id="keyword"
                        name="keyword"
                        className="form-control bg-transparent"
                      />
                      <ErrorMessage name="keyword" />
                    </div>
                  </div>
                  <div className="text-center mt-13">
                    <button
                      type="submit"
                      onClick={() => handleSubmit(values)}
                      className="btn btn-sm btn-primary me-3"
                    >
                      Update Keyword
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { UpdateKeyword };

// ColorBlindSimulator.js
import tinycolor from 'tinycolor2';

const applyColorBlindness = (type) => {
  const elements = document.querySelectorAll('*');

  elements.forEach((element) => {
    const originalBackgroundColor = window.getComputedStyle(element).backgroundColor;
    const originalColor = tinycolor(originalBackgroundColor);

    const simulateColorBlindness = (color) => {
      switch (type) {
        case 'protanopia':
          return color.desaturate().toHexString();
        case 'deuteranopia':
          return color.desaturate().toHexString();
        case 'tritanopia':
          return color.desaturate().toHexString();
        default:
          return color.toHexString();
      }
    };

    // Simulate background color
    element.style.backgroundColor = simulateColorBlindness(originalColor);

    // Simulate text color
    const originalTextColor = window.getComputedStyle(element).color;
    const textColor = tinycolor(originalTextColor);
    element.style.color = simulateColorBlindness(textColor);

    // Additional style adjustments can be added as needed
  });
};

export { applyColorBlindness };

import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../../../../../../auth-header";
import CreateBundleSocialDropdown from "./CreateBundleSocialDropdown";
import CreateBundleNewsDropdown from "./CreateBundleNewsDropdown";
import { toast } from "react-toastify";
import { useZoom } from "../../../../../../../_metronic/partials/layout/zoom/ZoomContext";
import Feedlabel from "./Feedlabel";
const CreateBundle = ({ isOpen, onClose, initialBundle, initialSources }) => {
  const initialFormState = {
    bundle_title: "",
    feeds: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const { zoomLevel } = useZoom();
  const [feeds, setFeeds] = useState([]);
  const [newFeed, setNewFeed] = useState("");
  const [formData, setFormData] = useState(initialBundle || initialFormState);
  const [validationError, setValidationError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [intervalTypes, setIntervalTypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedFeeds, setSelectedFeeds] = useState([]);

  const fetchIntervalTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_enabled_feeds`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data && response.data.result) {
          setIntervalTypes(response.data.result);
        } else if (
          response.data.status === 204 &&
          response.data.message ===
            "Enabled Feeds not found. Please create or enable any feeds."
        ) {
          setValidationError(
            "Please create or enable at least one feed to create a bundle."
          );
        } else {
          console.error("Invalid response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching interval types:", error);
      });
  };

  useEffect(() => {
    setFormData({
      ...initialBundle,
      sources: initialBundle?.sources || initialSources || "",
    });

    fetchIntervalTypes();
  }, [initialBundle, initialSources]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (selectedCountries) => {
    setSelectedFeeds(selectedCountries);
  };

  const handleSaveChanges = (event) => {
    event.preventDefault();
    if (!formData.bundle_title || formData.bundle_title.trim() === "") {
      setValidationError("Please enter a title.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }
    // if (!formData.feeds || formData.feeds.trim() === "") {
    //   setValidationError("Please enter a feeds.");
    //   setTimeout(() => {
    //     setValidationError("");
    //   }, 5000);
    //   return;
    // }
    if (selectedFeeds.length === 0) {
      setValidationError("Please select at least one feed.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }
    const feedIds = selectedFeeds.join(",");

    const dataToSend = {
      bundle_title: formData.bundle_title,
      // feeds: formData.feed_id,
      feeds: feedIds,
    };
    setIsLoading(true);
    console.log("datatoSend", dataToSend);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/policy/create_bundle_v8_with_new_flow`,
        dataToSend,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("API create Response:", response);
        if (response.data.status === 201) {
          toast.success(response.data.message, { autoClose: 3000 });
          window.location.reload();
        } else if (response.data.status === 409) {
          if (response.data.message === "Bundle Already Created") {
            toast.error(response.data.message, { autoClose: 3000 });
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(response.data.message, { autoClose: 3000 });
          }
        } else {
          // Handle other status codes here
          setValidationError("");
          setSaveSuccess(true);
          onClose();
        }
      })
      .catch((error) => {
        console.error("Error updating bundle:", error);
        setIsLoading(false);
      });
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{
        display: isOpen ? "block" : "none",
        transform: `scale(${zoomLevel})`,
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Bundle</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          {intervalTypes.length === 0 ? (
            <div
              className="modal-body text-center"
              style={{ marginTop: "50px" }}
            >
              <p>
                Please create or enable at least one feed to create a bundle.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSaveChanges}>
              <div className="modal-body">
                {validationError && (
                  <div className="alert alert-danger" role="alert">
                    {validationError}
                  </div>
                )}
                <div className="mb-3">
                  <label htmlFor="bundle_title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="bundle_title"
                    name="bundle_title"
                    value={formData.bundle_title}
                    onChange={handleInputChange}
                    placeholder="Enter Title"
                  />
                </div>

                <Feedlabel
                  onCountryChange={handleCountryChange}
                  selectedFeeds={selectedFeeds}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? "Adding...Please Wait" : "Create"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateBundle;

// @flow
import * as React from "react";
import Profile from "./Profile";
import Sharing from "./Sharing";
import ExportData from "./ExportData";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import Displaystats from "./Displaystats";
import Updateemail from "./Updateemail";
import { useZoom } from "../../../_metronic/partials/layout/zoom/ZoomContext";
import Deactivate from "./Deactivateuser";
import Deactivateuser from "./Deactivateuser"
import { useColorBlind } from '../../../_metronic/partials/layout/colorblind/ColorBlindContext';

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: "Setting Page",
    path: "/setting",
    isSeparator: false,
    isActive: false,
  },
];

export default function Setting() {
  const provider = localStorage.getItem("provider");
  const { zoomLevel } = useZoom();
  // const { simulatedType, setSimulatedType, applyColorBlindness } = useColorBlind();
  // React.useEffect(() => {
  //   console.log('Simulated type changed:', simulatedType);

  //   applyColorBlindness(simulatedType);
  // }, [simulatedType, applyColorBlindness]);
  
  // Conditionally render Updateemail only if the provider is "email"
  const renderUpdateEmail = () => {
    if (provider === "email") {
      return <Updateemail />;
    } else {
      return null; // hide Updateemail for other providers
    }
  };
  return (
    <div className="card" style={{ transform: `scale(${zoomLevel})` }}>
      <PageTitle breadcrumbs={wizardsBreadCrumbs}>Setting</PageTitle>
      <div className=" row g-5 g-xl-12">
        <div className="col-xl-6  mb-3">
          <div>
            <div className="d-flex justify-content-center align-items-center card pt-9 pb-0">
              <Profile />
            </div>
            <div className="card pt-9 pb-0 mt-5">
          
            {renderUpdateEmail()}
            </div>
          </div>
        </div>
        <div className="col-xl-6  mb-3">

          <div>
            <div className="card pt-9 pb-0">
              {/* <ProfilGet/> */}
              <Displaystats className=""  />
                          </div>
          </div>
          <Deactivateuser/>

        </div>
      </div>
    </div>
  );
}

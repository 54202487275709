import React, { useState } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "../../modules/auth/components/ForgotPassword";
import Forget from "../../modules/auth/components/Forget";

const UpdateEmail = () => {
  // const [email, setEmail] = useState("");
  
  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };

  // const handleUpdateEmail = (event) => {
  //   event.preventDefault();

  //   const dataToSend = {
  //     email: email,
  //   };

  //   axios
  //     .patch(`${process.env.REACT_APP_API_URL}/user_portal/api/update_email`, dataToSend, {
  //       headers: authHeader(),
  //     })
  //     .then((response) => {
  //       const responseData = response.data;
  //       // console.log("API Response:", responseData);
  //       if (response.status === 200) { 
  //         // console.log("Success Message:", responseData.message);
  //         toast.success(responseData.message, { autoClose: 3000 });
  //       } else {
  //         console.error("Error Message:", "Error updating email.");
  //         toast.error("Error updating email.");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error updating email:", error);
  //       toast.error("Error updating email.");
  //     });
  // };

  return (
    <div className="container">
  <Forget/>
      {/* <form onSubmit={handleUpdateEmail}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter Email"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center p-2">
          <button type="submit" className="btn btn-primary">
            Update Email
          </button>
        </div>
      </form> */}

      {/* <ToastContainer /> */}
    </div>
  );
};

export default UpdateEmail;

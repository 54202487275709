import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import { FaArrowRight } from 'react-icons/fa';

const HeadlineNews = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/policy/home_page_articles_for_public_users?section=carousel_section`)
      .then(response => {
        console.log("carosel data",response)
        if (response.data.results && response.data.results.result) {
          setArticles(response.data.results.result);
        }
      })
      .catch(error => {
        console.error('Error fetching articles:', error);
      });
  }, []);
  const capitalizeFirstLetter = (str) => {
    if (!str || typeof str !== 'string') return ''; // Add a null check here
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  
  const getTruncatedContent = (content, maxLength) => {
    if (content.length <= maxLength) {
      return content;
    } else {
      return content.substring(0, maxLength) + '...';
    }
  };
  
  
  return (
    <div className="headline-news-container bg-dark">
     <Carousel>
  {articles.map(article => (
    <Carousel.Item key={article.id}>
      <div className="container flex flex-wrap carousel-item-container d-flex justify-content-between">
        <div className="text-headline text-white" style={{width:"50%"}}>
          <h2 className='text-white'style={{height:"20px"}}>{article.title}</h2>
          <h5 className='mt-2' style={{color:"#FF6E00"}}>{capitalizeFirstLetter(article.article_category)}</h5>   
          <div className='d-flex mb-10 mt-2 gap-2'>
            <h5 className='text-bold fs-6 text-white'>{capitalizeFirstLetter(article.source)}</h5>
            <h5 className='text-bold fs-6 text-white'> | </h5>
            <h5 className='text-bold fs-6 text-white'>{article.author}</h5>
          </div>                  
          <p>{getTruncatedContent(article.summarized_content, 250)}</p>
          <a style={{color:"#FF6E00"}} className="fs-7" href={article.link} target="_blank" rel="noopener noreferrer">
            Learn More <FaArrowRight />
          </a>
        </div>
        <div className="image-headline p-10">
          <img className='img-carousel' src={article.image} alt="News" />
        </div>
      </div>
    </Carousel.Item>
  ))}
</Carousel>

    </div>
  );
};

export default HeadlineNews;

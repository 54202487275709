import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../../../../app/auth-header";
import { toast } from "react-toastify";
import { useZoom } from "../../layout/zoom/ZoomContext";
const CreateFeed = ({ isOpen, onClose, initialBundle, initialSources }) => {
  const initialFormState = {
    // bundle_title: "",
    feed_title: "",
    feed_keyword: "",
    feed_black_list_keyword: "",
    interval_type: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const { zoomLevel } = useZoom();
  const [feeds, setFeeds] = useState([]);
  const [newFeed, setNewFeed] = useState("");
  const [formData, setFormData] = useState(initialBundle || initialFormState);
  const [validationError, setValidationError] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [intervalTypes, setIntervalTypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedFeeds, setSelectedFeeds] = useState([]);

  const fetchIntervalTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_interval_types`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data && response.data.result) {
          setIntervalTypes(response.data.result);
        } else if (
          response.data.status === 204 &&
          response.data.message ===
            "Enabled Feeds not found. Please create or enable any feeds."
        ) {
          setValidationError(
            "Please create or enable at least one feed to create a bundle."
          );
        } else {
          console.error("Invalid response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching interval types:", error);
      });
  };

  useEffect(() => {
    setFormData({
      ...initialBundle,
      sources: initialBundle?.sources || initialSources || "",
    });

    fetchIntervalTypes();
  }, [initialBundle, initialSources]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (selectedCountries) => {
    setSelectedFeeds(selectedCountries);
  };
  const handleSaveChanges = (event) => {
    event.preventDefault();
    if (!formData.feed_title || formData.feed_title.trim() === "") {
      setValidationError("Please enter a title.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }

    if (!formData.feed_keyword || formData.feed_keyword.trim() === "") {
      setValidationError("Please enter a keyword.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }
    if (!formData.interval_type || formData.interval_type.trim() === "") {
      setValidationError("Please select interval type.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }

    const dataToSend = {
      feed_keyword: formData.feed_keyword.toString(),
      feed_black_list_keyword: formData.feed_black_list_keyword?.toString(),
      feed_title: formData.feed_title.toString(),
      interval_type: formData.interval_type.toString(),
    };
    setIsLoading(true);
    console.log("datatoSend", dataToSend);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/policy/createfeed_v3`,
        dataToSend,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("API create Response:", response);
        if (response.data.status === 201) {
          console.log("Feed created:", response.data);
          toast.success(response.data.message);
          setTimeout(() => {}, 3000);
          window.location.reload();
        } else if (response.data.status === 204) {
          toast.error(response.data.message);
          setIsLoading(false);
          setTimeout(() => {}, 3000);
          window.location.reload();
        } else if (response.data.status === 400) {
          toast.error(response.data.message);
          setIsLoading(false);
        } else if (response.data.status === 409) {
          if (
            response.data.message ===
            "Feed already exists with the same title and keyword."
          ) {
            console.log("Feed already exists:", response.data.message);
            toast.error(response.data.message, { autoClose: 3000 });
            setIsLoading(false);
            return; // Prevent onClose() from being called
          } else {
            console.log("Other 409 error:", response.data.message);
            setValidationError("");
            toast.error(response.data.message);
          }
        } else if (response.data.status === 204) {
          if (
            response.data.message === "Please Create Bundle First To Add Feed."
          ) {
            toast.error(response.data.message);
            setTimeout(() => {}, 3000);
          } else {
            setValidationError("");
            toast.error(response.data.message);
          }
        }

        setValidationError("");
        setSaveSuccess(true);
        onClose();
      })
      .catch((error) => {
        console.error("Error updating bundle:", error);
        setIsLoading(false);
      });
  };

  // const handleSaveChanges = (event) => {
  //   event.preventDefault();
  //   if (!formData.feed_title || formData.feed_title.trim() === "") {
  //     setValidationError("Please enter a title.");
  //     setTimeout(() => {
  //       setValidationError("");
  //     }, 5000);
  //     return;
  //   }

  //   if (!formData.feed_keyword || formData.feed_keyword.trim() === "") {
  //     setValidationError("Please enter a keyword.");
  //     setTimeout(() => {
  //       setValidationError("");
  //     }, 5000);
  //     return;
  //   }
  //   if (!formData.interval_type || formData.interval_type.trim() === "") {
  //     setValidationError("Please select interval type.");
  //     setTimeout(() => {
  //       setValidationError("");
  //     }, 5000);
  //     return;
  //   }

  //   const dataToSend = {
  //     feed_keyword: formData.feed_keyword.toString(),
  //     feed_black_list_keyword: formData.feed_black_list_keyword?.toString(),
  //     feed_title: formData.feed_title.toString(),
  //     // source_type: source_type.toString(),
  //     interval_type: formData.interval_type.toString(),
  //     //   bundle_title: formData.bundle_title,
  //     // feeds: formData.feed_id,
  //     //   feeds: feedIds,
  //   };
  //   setIsLoading(true);
  //   console.log("datatoSend", dataToSend);

  //   axios
  //     .post(
  //       `${process.env.REACT_APP_API_URL}/policy/createfeed_v3`,
  //       dataToSend,
  //       {
  //         headers: authHeader(),
  //       }
  //     )
  //     .then((response) => {
  //       console.log("API create Response:", response);
  //       if (response.data.status === 201) {
  //         console.log("Feed created:", response.data);
  //         toast.success(response.data.message);
  //         setTimeout(() => {}, 3000);
  //         window.location.reload();
  //       } else if (response.data.status === 204) {
  //         toast.error(response.data.message);
  //         setIsLoading(false);

  //         setTimeout(() => {}, 3000);
  //         window.location.reload();
  //       } else if (response.data.status === 400) {
  //         toast.error(response.data.message);
  //         setIsLoading(false);
  //       } else if (response.data.status === 409) {
  //         if (
  //           response.data.message ===
  //           "Feed already exists with the same title and keyword."
  //         ) {
  //           toast.error(response.data.message, { autoClose: 3000 });
  //           setIsLoading(false);
  //         }
  //       }
  //       //   else if (response.data.status === 409) {
  //       //   toast.error(response.data.message);
  //       //   setTimeout(() => {}, 3000);
  //       //   setIsLoading(false);
  //       // }
  //       else if (response.data.status === 204) {
  //         if (
  //           response.data.message === "Please Create Bundle First To Add Feed."
  //         ) {
  //           toast.error(response.data.message);
  //           setTimeout(() => {}, 5000);
  //         } else {
  //           setValidationError("");

  //           toast.error(response.data.message);
  //         }
  //       }

  //       setValidationError("");
  //       setSaveSuccess(true);
  //       onClose();
  //     })
  //     .catch((error) => {
  //       console.error("Error updating bundle:", error);
  //       setIsLoading(false);
  //     });
  // };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{
        display: isOpen ? "block" : "none",
        transform: `scale(${zoomLevel})`,
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Feed</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          {/* {intervalTypes.length === 0 ? (
            <div
              className="modal-body text-center"
              style={{ marginTop: "50px" }}
            >
              <p>
                Please create or enable at least one feed to create a bundle.
              </p>
            </div>
          ) : ( */}
          <form onSubmit={handleSaveChanges}>
            <div className="modal-body">
              {validationError && (
                <div className="alert alert-danger" role="alert">
                  {validationError}
                </div>
              )}
              <div className="mb-3">
                <div>
                  <label
                    htmlFor="feed_title"
                    className="required form-label fs-6 fw-bolder text-dark"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="feed_title"
                    name="feed_title"
                    value={formData.feed_title}
                    onChange={handleInputChange}
                    placeholder="Enter Title"
                    className="form-control bg-transparent"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="feed_keyword"
                  className="required form-label fs-6 fw-bolder text-dark"
                >
                  Whitelisted Keyword
                </label>
                <input
                  type="text"
                  id="feed_keyword"
                  name="feed_keyword"
                  value={formData.feed_keyword}
                  onChange={handleInputChange}
                  placeholder="Enter Whitelisted Keyword"
                  className="form-control bg-transparent"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="feed_black_list_keyword"
                  className=" form-label fs-6 fw-bolder text-dark"
                >
                  Blacklisted Keyword
                </label>
                <input
                  type="text"
                  id="feed_black_list_keyword"
                  name="feed_black_list_keyword"
                  value={formData.feed_black_list_keyword}
                  onChange={handleInputChange}
                  placeholder="Enter Whitelisted Keyword"
                  className="form-control bg-transparent"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="interval_type"
                  className="required form-label fs-6 fw-bolder text-dark"
                >
                  Interval Type
                </label>
                <select
                  value={formData.interval_type}
                  onChange={handleInputChange}
                  placeholder="Enter Title"
                  id="interval_type"
                  name="interval_type"
                  className="form-select bg-transparent"
                >
                  <option value="">Select Interval Type</option>
                  {intervalTypes.map((type) => (
                    <option key={type.interval_name} value={type.interval_type}>
                      {type.interval_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? "Adding...Please Wait" : "Create"}
              </button>
            </div>
          </form>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default CreateFeed;

// import React, { Suspense, useState } from "react";
// import { Outlet } from "react-router-dom";
// import { I18nProvider } from "../_metronic/i18n/i18nProvider";
// import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
// import { MasterInit } from "../_metronic/layout/MasterInit";
// import { AuthInit } from "./modules/auth";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { ZoomProvider } from "../_metronic/partials/layout/zoom/ZoomContext";
// // import { ColorBlindProvider } from "../_metronic/partials/layout/colorblind/ColorBlindContext"
// const App = () => {
//   // const [zoomLevel, setZoomLevel] = useState(1);

//   // const handleZoomIn = () => {
//   //   setZoomLevel(prevZoom => prevZoom + 0.1);
//   // };

//   // const handleZoomOut = () => {
//   //   setZoomLevel(prevZoom => Math.max(1.0, prevZoom - 0.1));
//   // };

//   return (
//     <Suspense fallback={<LayoutSplashScreen />}>
//       <I18nProvider>
//         <LayoutProvider>
//           <AuthInit>
//             {/* <div className="zoomed-content" style={{ transform: `scale(${zoomLevel})` }}> */}
//             <ZoomProvider>
//               {/* <ColorBlindProvider> */}
//               <Outlet />
//               {/* </ColorBlindProvider> */}
//             </ZoomProvider>
//             {/* </div> */}
//             <ToastContainer />
//             <MasterInit />
//           </AuthInit>
//         </LayoutProvider>
//       </I18nProvider>
//     </Suspense>
//   );
// };

// export { App };
import React, { Suspense, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "../_metronic/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ZoomProvider } from "../_metronic/partials/layout/zoom/ZoomContext";
import axios from "axios";
import { Link } from "react-router-dom";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true); // Initially assuming user is authenticated

  // Function to handle logout
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    setIsAuthenticated(false);
  };

  useEffect(() => {
    // Axios interceptor to handle 401 errors
    const interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          logout(); // Logout the user
          window.location.href = "/auth/login"; // Redirect to login page
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            {isAuthenticated ? (
              <React.Fragment>
                <ZoomProvider>
                  <Outlet />
                </ZoomProvider>
                <ToastContainer />
                <MasterInit />
              </React.Fragment>
            ) : null}
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };

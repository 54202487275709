import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MyFeed } from "../pages/feeds/MyFeed";
import Setting from "./../pages/settings/Setting";
import Billing from "./../pages/billing/Billing";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import DetailNews from "../modules/News/DetailNews/DetailNews";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import Bundles from "./../modules/apps/home/components/Bundles";
import { useParams } from "react-router-dom";
import { AdminDashboardWrapper } from "../../_metronic/layout/AdminDashboard/AdminDashboardWrapper";
import { AdminMasterLayout } from "../../_metronic/layout/AdminDashboard/AdminMasterLayout";
import { AdminMyFeed } from "../pages/feeds/AdminMyFeed";
import AdminSetting from "../pages/settings/AdminSetting";
import UserFeeds from "../../_metronic/layout/AdminDashboard/UserFeeds";
import User from "../../_metronic/layout/AdminDashboard/User";
import { useState , useEffect } from "react";
// import Success from "../pages/billing/Success";
import Success from "../modules/auth/components/Success";
const AdminPrivateRoutes = () => {

    // Create a state variable to store the is_admin value
    const [isAdmin, setIsAdmin] = useState(false);

    // Read the is_admin value from localStorage when the component mounts
    useEffect(() => {
      const is_admin = localStorage.getItem("is_admin");
      if (is_admin) {
        setIsAdmin(JSON.parse(is_admin));
      }
    }, []);


  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const Home = lazy(() => import("../modules/apps/home/Home"));

  const FeedList = lazy(() => import("../../_metronic/layout/AdminDashboard/UserFeeds"));
  const NewsPage = lazy(() => import("./../modules/News/NewsPage"));
  const PoliciesPage = lazy(() => import("./../modules/Policies/PoliciesPage"));
  const { id } = useParams();
  const parsedId = id ? parseInt(id) : 0; // Provide a fallback value (0 in this case)

  return (
    <Routes>

 {isAdmin ? <Route element={<AdminMasterLayout/>}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path="auth/*" element={<Navigate to="/dashboard" />} /> */}
        {/* Pages */}
        {/* <Route path="dashboard" element={<DashboardWrapper />} /> */}
        <Route path="dashboard" element={<AdminDashboardWrapper />} />
        <Route path="userfeeds" element={<UserFeeds/>} />
        {/* <Route path="feeds" element={<AdminMyFeed />} /> */}
        <Route path="settings" element={<AdminSetting />} />
        <Route path="users" element={<User/>} />

        <Route
          path="builder"
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />

        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="news/pages/*"
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />
    <Route
  path="/detailnews/:id"
  element={<DetailNews />}
/>
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/home/*"
          element={
            <SuspensedView>
              <Home />
            </SuspensedView>
          }
        />

        <Route
          path="myfeeds/*"
          element={
            <SuspensedView>
              <PoliciesPage />
            </SuspensedView>
          }
        />
        <Route
          path="/myfeed/feedlist"
          element={
            <SuspensedView>
              <FeedList />
            </SuspensedView>
          }
        />
         <Route
          path='/success/:query_string'
          element={
            <SuspensedView>
              <Success />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error" />} />
      </Route> : ("")}

    
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { AdminPrivateRoutes };

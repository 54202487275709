import React, { useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../../auth-header";
import { debounce } from "lodash";
import { FaTimes } from 'react-icons/fa';

interface Country {
  country_code: string;
  country_name: string;
}

interface CountryProps {
  onCountryChange: (selectedCountries: string[]) => void;
}

const CountryDropdown: React.FC<CountryProps> = ({ onCountryChange }) => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [isSelectionManual, setIsSelectionManual] = useState<boolean>(false);

 
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/policy/article_countries_list_v1`,
          {
            headers: authHeader() as { Authorization: string },
          }
        );

        if (response.data && Array.isArray(response.data.country_code)) {
          const fetchedCountries = response.data.country_code.map((country: any) => ({
            country_code: country.country_code,
            country_name: capitalizeFirstLetter(country.country_name),
          }));

          setCountries(fetchedCountries);

        

        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, []);



  const debouncedHandleCountryChange = debounce(
    (event: React.ChangeEvent<HTMLSelectElement>) => handleCountryChange(event),
    300
  );

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    // console.log("Selected value:", value);
    // console.log("Selected countries:", selectedCountries);
    // console.log("Is selection manual:", isSelectionManual);

    if (value === "") {
      return;
    }

    if (value === "all") {
      const updatedSelection = isSelectionManual
        ? []
        : countries.map((country) => country.country_code);

      // console.log("Updated selection:", updatedSelection);

      setSelectedCountries(updatedSelection);
      setIsSelectionManual(!isSelectionManual); // Toggle the manual selection state
      onCountryChange(updatedSelection);

      try {
        // Assuming fetchData is an async function
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      const isSelected = selectedCountries.includes(value);

      let updatedSelection: string[];

      if (isSelected) {
        updatedSelection = selectedCountries.filter((code) => code !== value);
      } else {
        updatedSelection = [...selectedCountries, value].filter(
          (code, index, arr) => arr.indexOf(code) === index
        );
      }

      // console.log("Updated selection:", updatedSelection);

      setSelectedCountries(updatedSelection);
      setIsSelectionManual(true);
      onCountryChange(updatedSelection);

      try {
        // Assuming fetchData is an async function
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  
  const setIsSelectionManualAsync = async (value: boolean) => {
    return new Promise<void>((resolve) => {
      setIsSelectionManual(value);
      resolve();
    });
  };
  
  
  const fetchData = async () => {
    try {
      // Your API call logic here
      // console.log("Fetching data...");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRemoveCountry = (countryCode: string) => {
    const updatedSelection = selectedCountries.filter(
      (code) => code !== countryCode
    );
    setSelectedCountries(updatedSelection);
    onCountryChange(updatedSelection);
  };
  const resetDropdown = () => {
    setSelectedCountries([]);
    setIsSelectionManual(false);
    onCountryChange([]);
  };
  return (
    <div className="mt-5">
      <p>
        <small className="text-muted">Publisher's Country</small>
      </p>
      <select
        className="rounded form-control"
        style={{
          height: "38px",
          borderColor: "black",
          fontSize: "13px",
          width: "160px",
        }}
        id="country"
        value={selectedCountries.length > 0 ? selectedCountries.join(",") : ""}
        onChange={handleCountryChange}
      >
        <option key="choose-option" value="">
          Choose an option
        </option>
        <option key="select-all" value="all">
          Select All
        </option>

        {countries.map((country, index) => (
          <option
            key={`${country.country_code}-${index}`}
            value={country.country_code}
          >
            {country.country_name}
          </option>
        ))}
      </select>

      {selectedCountries.length > 0 && (
        <div className="mt-2">
          <p>
            <small className="text-muted">Selected Country Codes:</small>
          </p>

          <div className="col-xl-8">
            {selectedCountries.map((code) => {
              const selectedCountry = countries.find(
                (country) => country.country_code === code
              );
              return (
                <div
                  key={code}
                  className="badge badge-info"
                  style={{ marginRight: "10px", marginBottom: "5px" }}
                >
                  <span
                    className="badge badge-info"
                    style={{ fontSize: "12px", cursor: "pointer" }}
                  >
                    {selectedCountry
                      ? selectedCountry.country_name
                      : code}{' '}
                    <a
                      style={{ color: "black", marginLeft: "5px" }}
                      onClick={() => handleRemoveCountry(code)}
                    >
                      <FaTimes />
                    </a>
                  </span>
                </div>
              );
            })}
          </div>

          <div className="mt-2">
            <button className="btn btn-primary" onClick={resetDropdown}>
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountryDropdown;

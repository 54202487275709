import React from 'react';

const Error = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <h1>Error !</h1>
        <p>You are not authorized to access this page</p>
      </div>
    </div>
  );
};

export default Error;

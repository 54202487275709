import React, { useState, useEffect } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { FaTimes } from 'react-icons/fa';
import authHeader from "../../../../../../auth-header";
interface Country {
  feed_id: string;
  feed_title: string;
}

interface CountryProps {
  onCountryChange: (selectedCountries: string[]) => void;
}

const Feedlabel: React.FC<CountryProps> = ({ onCountryChange }) => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [isSelectionManual, setIsSelectionManual] = useState<boolean>(false);

 
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_enabled_feeds`, {
          headers: authHeader() as { Authorization: string },
        });
        console.log("feedsget ", response.data);
    
        if (response.data && Array.isArray(response.data.result)) {
          const fetchedCountries = response.data.result.map((bundle: any) => ({
            feed_id: bundle.feed_id,
            feed_title: bundle.feed_title,
          }));
    
          setCountries(fetchedCountries);
          console.log('Fetched countries:', fetchedCountries);
          console.log('Countries:', countries);

        } else {
          console.error("Invalid response structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setIsLoading(false);
      }
    };
    
    

    fetchCountries();
  }, []);



  const debouncedHandleCountryChange = debounce(
    (event: React.ChangeEvent<HTMLSelectElement>) => handleCountryChange(event),
    300
  );

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    // console.log("Selected value:", value);
    // console.log("Selected countries:", selectedCountries);
    // console.log("Is selection manual:", isSelectionManual);

    if (value === "") {
      return;
    }

    if (value === "all") {
      const updatedSelection = isSelectionManual
        ? []
        : countries.map((country) => country.feed_id);

      // console.log("Updated selection:", updatedSelection);

      setSelectedCountries(updatedSelection);
      setIsSelectionManual(!isSelectionManual); // Toggle the manual selection state
      onCountryChange(updatedSelection);

      try {
        // Assuming fetchData is an async function
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      const isSelected = selectedCountries.includes(value);

      let updatedSelection: string[];

      if (isSelected) {
        updatedSelection = selectedCountries.filter((code) => code !== value);
      } else {
        updatedSelection = [...selectedCountries, value].filter(
          (code, index, arr) => arr.indexOf(code) === index
        );
      }

      // console.log("Updated selection:", updatedSelection);

      setSelectedCountries(updatedSelection);
      setIsSelectionManual(true);
      onCountryChange(updatedSelection);

      try {
        // Assuming fetchData is an async function
        fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  
  const setIsSelectionManualAsync = async (value: boolean) => {
    return new Promise<void>((resolve) => {
      setIsSelectionManual(value);
      resolve();
    });
  };
  
  
  const fetchData = async () => {
    try {
      // Your API call logic here
      // console.log("Fetching data...");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRemoveCountry = (countryCode: string) => {
    const updatedSelection = selectedCountries.filter(
      (code) => code !== countryCode
    );
    setSelectedCountries(updatedSelection);
    onCountryChange(updatedSelection);
  };
  const resetDropdown = () => {
    setSelectedCountries([]);
    setIsSelectionManual(false);
    onCountryChange([]);
  };
  return (
    <div className="mb-5">
      <label  className="form-label">
                    Feeds
                  </label>
      <select
className="form-select"       
         
        id="country"
        value={selectedCountries.length > 0 ? selectedCountries.join(",") : ""}
        onChange={handleCountryChange}
      >
        <option key="choose-option" value="">
          Choose an option
        </option>
       

        {countries.map((country, index) => (
          <option
            key={`${country.feed_title}-${index}`}
            value={country.feed_id}
          >
            {country.feed_title}
          </option>
        ))}
      </select>

      {selectedCountries.map((code) => {
  console.log('Current code:', code);
  const selectedFeed = countries.find(
    (country) => country.feed_id.toString() === code
  );
    console.log('Selected feed:', selectedFeed);
  return (
    <div
      key={code}
      className="badge badge-info"
      style={{ marginRight: "10px", marginBottom: "5px" }}
    >
      <span
        className="badge badge-info"
        style={{ fontSize: "12px", cursor: "pointer" }}
      >
        {selectedFeed ? selectedFeed.feed_title : code}{" "}
        <a
          style={{ color: "black", marginLeft: "5px" }}
          onClick={() => handleRemoveCountry(code)}
        >
          <FaTimes />
        </a>
      </span>
    </div>
  );
})}



    </div>
  );
};

export default Feedlabel;

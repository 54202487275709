import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import {
 
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
  RedditIcon,
  WhatsappIcon,
  PinterestIcon,
  InstapaperIcon,
  MastodonIcon 
} from 'react-share';
import { FaMastodon } from "react-icons/fa";

import { FiCopy } from 'react-icons/fi';

const Share = ({ onClose, articleTitle, articleImage , articleLink }) => {
  const [showShareArticleModal, setShowShareArticleModal] = React.useState(true);
  const emailSubject = encodeURIComponent(articleTitle);
  const emailBody = encodeURIComponent(`${articleTitle}\n\n${articleLink}`);
  const emailShareUrl = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  const closeShareModal = () => {
    setShowShareArticleModal(false);
    onClose();
  };
console.log(articleLink,"link")

  const copyToClipboard = () => {
    navigator.clipboard.writeText(articleLink);
    alert("Link copied to clipboard!");
  };
  return (
    <>
      {/* Add Helmet to set meta tags */}
      <Helmet>
        <title>{articleTitle}</title>
        <meta property="og:title" content={articleTitle} />
        <meta property="og:description" content={articleTitle} />
        <meta property="og:image" content={articleImage} />
        <meta property="og:url" content={articleLink} />

        {/* Add more meta tags as needed */}
      </Helmet>
      
      <Modal show={showShareArticleModal} onHide={closeShareModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Share Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex gap-5">
            <div>
              <img src={articleImage} alt={articleTitle} style={{ width: "100%", borderRadius: "10px" }} />
            </div>
            <div>
              <h4>{articleTitle}</h4>
            </div>
          </div>
          <div className="d-flex mt-5 gap-5 justify-content-center">
        
            <a  onClick={copyToClipboard}>
            <FiCopy size={20} className="mt-2" style={{ marginRight: "5px" }} />
            </a>
            <a href={emailShareUrl} target="_blank" rel="noopener noreferrer">
              <EmailIcon size={32} round />
            </a>
            {/* <a href={`https://www.linkedin.com/sharing/share-offsite/?url=$${encodeURIComponent(articleImage)}&quote=${encodeURIComponent(articleTitle)}`} target="_blank" rel="noopener noreferrer">
              <LinkedinIcon size={32} round />
            </a> */}
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(articleLink)}&quote=${encodeURIComponent(articleTitle)}`} target="_blank" rel="noopener noreferrer">
              <FacebookIcon size={32} round />
            </a>
            <a href={`https://twitter.com/intent/tweet?u=${encodeURIComponent(articleLink)}&quote=${encodeURIComponent(articleTitle)}`} target="_blank" rel="noopener noreferrer">
            <TwitterIcon size={32} round />
          </a>
          <a href={ `https://www.reddit.com/submit?url=${encodeURIComponent(articleLink)}&quote=${encodeURIComponent(articleTitle)}`} target="_blank" rel="noopener noreferrer">
              <RedditIcon size={32} round />
            </a>
            <a href={ `https://mastodon.social/share?text=${encodeURIComponent(articleLink)}&quote=${encodeURIComponent(articleTitle)}`} target="_blank" rel="noopener noreferrer">
              <FaMastodon size={32} round />
            </a>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeShareModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Share;


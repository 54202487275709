import React from 'react';
import { Link } from 'react-router-dom';
import HeaderOne from './Header/HeaderOne';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import main from '../landingpage/about/document.png'
import FooterOne from './FooterOne';


const Documentation = () => {
    return (
      
            <div className="section section-padding case-study-featured-area">
                  <HeaderOne/>
                <div className="container d-flex justify-content-between" style={{marginTop:"200px"}}>
                    <div className="row d-flex justify-content-between">
                 
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle mt-5 fs-2 fw-bold"style={{color:"#FF6E00"}}>Welcome to the Documentation page of</span>
                            <h2 className="title mt-2 fs-4">RSS Feeds platform!</h2>
                            <p>Where you'll find all the information you need to make the most out of your experience. Whether you're a seasoned user or just getting started, this comprehensive guide will walk you through the features and functionalities that our platform has to offer.</p>
                            {/* <Link style={{textDecoration:"none"}} to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link> */}
                        </div>
                   
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb ">
                        {/* <img src={process.env.PUBLIC_URL + "/landingpage/about/main.png"} alt="main" /> */}
                         <img src={main} alt="main" />
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne/>
        </div>

    )
}

export default Documentation;
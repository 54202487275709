import React, { useEffect, useState } from "react";
import { toAbsoluteUrl, KTIcon } from "../../../_metronic/helpers";
import { Link } from "react-router-dom";
import CreateBundle from "../../modules/apps/home/components/Bundles/CreateBundle/CreateBundle";
import axios from "axios";
import authHeader from "../../auth-header";
import EditBundleV1 from "../../modules/apps/home/components/Bundles/EditBundle";
import KeywordBundle from "../../modules/apps/home/components/Bundles/CreateBundle/KeywordBundle";
import BlacklistBundle from "../../modules/apps/home/components/Bundles/CreateBundle/BlacklistBundle";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useZoom } from "../../../_metronic/partials/layout/zoom/ZoomContext";
import CreateFeed from "../../../_metronic/partials/modals/add-feed/CreateFeed";
import EditMyfeed from "../../modules/apps/home/components/Bundles/EditMyfeed";
const Newmyfeed = () => {
  const [selectedKeywordId, setSelectedKeywordId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bundleSources, setBundleSources] = useState([]);
  const [isKeywordBundleOpen, setIsKeywordBundleOpen] = useState(false);
  const [isBlacklistBundleOpen, setIsBlacklistBundleOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [isSourcePopupOpen, setIsSourcePopupOpen] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [showDeleteConfirmationPopup, setShowDeleteConfirmationPopup] =
    useState(false);
  const [feedsToDelete, setFeedsToDelete] = useState([]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [bundleToDelete, setBundleToDelete] = useState(null);
  const { zoomLevel } = useZoom();

  const openModal = (keywordId) => {
    setSelectedKeywordId(keywordId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedKeywordId(null);
    setIsModalOpen(false);
  };

  const openEditModal = (bundle) => {
    console.log("Open Edit Modal Function Called");

    setSelectedBundle(bundle);
    setIsEditModalOpen(true);
  };
  // const opendeleteConfirmation = (bundle) => {
  //   setFeedsToDelete(bundle.feeds);
  //   setShowDeleteConfirmationPopup(true);
  // };

  const closeEditModal = () => {
    console.log("Close Edit Modal Function Called");

    setSelectedBundle(null);
    setIsEditModalOpen(false);
  };
  const [reloadComponent, setReloadComponent] = useState(false);
  const deleteBundle = async (bundleId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/policy/delete_feed/${bundleId}`,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 200) {
        console.log("Bundle deleted successfully.", response.data);
        toast.success(response.data.message);

        window.location.reload();
        fetchBundles();
      } else {
        console.error(
          "Failed to delete bundle. API response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error deleting bundle:", error);
    }
  };
  // const toggleBundle = async (bundleId) => {
  //   try {
  //     const bundleToUpdate = bundles.find(
  //       (bundle) => bundle.feed_id === bundleId
  //     );
  //     if (!bundleToUpdate) {
  //       console.error("Bundle not found for toggle.");
  //       return;
  //     }

  //     const newIsEnabled = !bundleToUpdate.is_enabled;
  //     const apiUrl = `${
  //       process.env.REACT_APP_API_URL
  //     }/policy/feed_enable_disable_v1/${bundleId}/${
  //       newIsEnabled ? "enable" : "disable"
  //     }`;

  //     const response = await axios.post(apiUrl, null, {
  //       headers: authHeader(),
  //     });

  //     if (response.status === 200) {
  //       const updatedBundles = bundles.map((bundle) =>
  //         bundle.id === bundleId
  //           ? { ...bundle, is_enabled: newIsEnabled }
  //           : bundle
  //       );
  //       setBundles(updatedBundles);
  //       toast.success(response.data.message);
  //       console.log("enable disable", response);
  //     } else {
  //       console.error(
  //         "Failed to toggle bundle. API response status:",
  //         response.status
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error toggling bundle:", error);
  //   }
  // };

  // const fetchBundles = async () => {
  //   try {
  //     setIsLoading(true);

  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/policy/get_feeds_v1`,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //     console.log("get feeds", response);
  //     if (response.status === 200) {
  //       const responseData = response.data;
  //       if (responseData.status === 200) {
  //         // Extract bundles data from the response
  //         const bundlesData = responseData.result;
  //         setBundles(bundlesData);
  //       } else if (responseData.status === 401) {
  //         window.location.href = "/auth/login";
  //       } else {
  //         console.error(
  //           "API request successful, but status is not 200:",
  //           responseData.status
  //         );
  //       }
  //     } else if (response.status === 204) {
  //       console.log("No data found.");
  //     } else {
  //       console.error("API request failed with status:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching bundles:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const toggleBundle = async (bundleId) => {
    try {
      const bundleToUpdate = bundles.find(
        (bundle) => bundle.feed_id === bundleId
      );
      if (!bundleToUpdate) {
        console.error("Bundle not found for toggle.");
        return;
      }

      const newIsEnabled = !bundleToUpdate.is_enabled;
      const apiUrl = `${
        process.env.REACT_APP_API_URL
      }/policy/feed_enable_disable_v1/${bundleId}/${
        newIsEnabled ? "enable" : "disable"
      }`;

      const response = await axios.post(apiUrl, null, {
        headers: authHeader(),
      });

      if (response.status === 200) {
        const updatedBundles = bundles.map((bundle) =>
          bundle.feed_id === bundleId
            ? { ...bundle, is_enabled: newIsEnabled }
            : bundle
        );
        setBundles(updatedBundles);
        toast.success(response.data.message);
      } else {
        console.error(
          "Failed to toggle bundle. API response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error toggling bundle:", error);
    }
  };

  // Get
  const fetchBundles = async () => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/policy/get_feeds_v1`,
        {
          headers: authHeader(),
        }
      );
      console.log("get bundles", response.data);
      if (response.status === 200) {
        const data = response.data;
        if (data.status === 200) {
          const bundlesData = data.result;
          setBundles(bundlesData);
        } else {
          console.error(
            "NO Bundels API request successful, but data.status is not 200:",
            data.status
          );
        }
      } else if (response.status === 204) {
        // console.log("No data found.");
      } else {
        console.error("API request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching bundles:", error);
    } finally {
      setIsLoading(false);
    }
  };
  // Get

  useEffect(() => {
    fetchBundles();
  }, [reloadComponent]);
  const closeDeleteConfirmation = () => {
    setBundleToDelete(null);
    setShowDeleteConfirmation(false);
  };
  const confirmDeleteBundle = () => {
    if (bundleToDelete) {
      deleteBundle(bundleToDelete.feed_id);
      closeDeleteConfirmation();
    }
  };

  const addNewBundle = async (newBundleData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/policy/create_bundle_v3_with_periodic_source`,
        newBundleData,
        {
          headers: authHeader(),
        }
      );
      // console.log("bundle", response.data);
      if (response.status === 200) {
        // console.log("Bundle created successfully.");
        window.location.reload();

        const updatedBundles = [
          ...bundles,
          { ...newBundleData, is_enabled: true },
        ];
        setBundles(updatedBundles);
        window.location.reload();

        closeModal();
      } else {
        console.error(
          "Failed to create bundle. API response status:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error creating bundle:", error);
    }
  };

  const openKeywordBundle = () => {
    setIsKeywordBundleOpen(true);
  };
  const openBlacklistBundle = () => {
    setIsBlacklistBundleOpen(true);
  };
  const closeKeywordBundle = () => {
    setIsKeywordBundleOpen(false);
  };
  const closeBlacklistBundle = () => {
    setIsBlacklistBundleOpen(false);
  };
  const openDeleteConfirmation = (bundle) => {
    setBundleToDelete(bundle);
    setShowDeleteConfirmation(true);
  };

  return (
    <div>
      {" "}
      <div className="card">
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">Feed</span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              Feed You Have Added
            </span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a keyword"
          ></div>
        </div>
        <div
          className="card-body py-3"
          style={{ transform: `scale(${zoomLevel})` }}
        >
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <div className="d-flex justify-content-end gap-4">
                <a
                  href="#"
                  className="btn btn-sm btn-light-primary"
                  onClick={() => openModal(selectedKeywordId)}
                >
                  <KTIcon iconName="plus" className="fs-3" />
                  Add Feed
                </a>
              </div>
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="w-25px"></th>
                    <th className="min-w-150px text-dark fw-bold fs-6">
                      Title
                    </th>
                    {/* <th className="min-w-140px text-dark fw-bold fs-6">Type</th> */}
                    <th className="min-w-150px text-dark fw-bold fs-6">
                      Whitelisted Keyword
                    </th>
                    <th className="min-w-150px text-dark fw-bold fs-6">
                      Blacklisted Keyword{" "}
                    </th>
                    <th className="min-w-150px text-dark fw-bold fs-6">
                      Interval Type
                    </th>

                    <th className="min-w-150px text-end text-dark fw-bold fs-6">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bundles.map((bundle, index) => (
                    <tr key={index}>
                      <td></td>

                      <td className="text-dark">{bundle.feed_title}</td>
                      <td className="text-dark">{bundle.feed_keyword}</td>
                      <td className="text-dark">
                        {" "}
                        {bundle.feed_black_list_keyword}
                      </td>
                      <td className="text-dark">{bundle.interval_type}</td>

                      <td>
                        <div className="d-flex justify-content-end flex-shrink-0">
                          <a
                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                            // href="#"
                            onClick={() => openEditModal(bundle)}
                          >
                            <KTIcon iconName="pencil" className="fs-3" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                            onClick={() => openDeleteConfirmation(bundle)}
                          >
                            <KTIcon iconName="trash" className="fs-3" />
                          </a>
                          <button
                            type="button"
                            className="btn"
                            style={{
                              width: "50px",
                              height: "25px",
                              backgroundColor: bundle.is_enabled
                                ? "#4caf50"
                                : "#e0e0e0",
                              borderRadius: "25px",
                              position: "relative",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleBundle(bundle.feed_id)}
                          >
                            <span
                              style={{
                                display: "block",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                left: bundle.is_enabled ? "25px" : "5px",
                                transition: "left 0.3s ease",
                              }}
                            ></span>
                            <span
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "5px",
                                transform: "translateY(-50%)",
                                color: bundle.is_enabled ? "#fff" : "#000",
                                fontSize: "8px",
                              }}
                            >
                              {/* {bundle.bundle_is_enabled ? "ON" : "OFF"} */}
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showDeleteConfirmation && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    <p>Are you sure you want to delete this Feed?</p>
                    <div style={{ marginTop: "20px" }}>
                      <button
                        className="btn btn-primary"
                        onClick={confirmDeleteBundle}
                      >
                        Yes
                      </button>{" "}
                      <button
                        className="btn btn-secondary"
                        onClick={closeDeleteConfirmation}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {isSourcePopupOpen && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "20px",
                      borderRadius: "5px",
                      maxWidth: "80%",
                      overflow: "auto",
                      maxHeight: "400px",
                    }}
                  >
                    <button
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        cursor: "pointer",
                      }}
                      className="btn btn-link btn-sm close-button fw-bold text-lg"
                      onClick={() => setIsSourcePopupOpen(false)}
                    >
                      Sources
                      <RxCross1 style={{ marginLeft: "75px" }} />
                    </button>
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      {selectedSources.map((source, sourceIndex) => (
                        <li style={{ marginBottom: "20px" }} key={sourceIndex}>
                          <span>{source.platform}</span>
                          {/* ({source.type}) */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CreateFeed
          isOpen={isModalOpen}
          onClose={closeModal}
          onAdd={addNewBundle}
        />
        {isEditModalOpen && (
          <div>
            <EditMyfeed
              isOpen={isEditModalOpen}
              onClose={closeEditModal}
              initialBundle={selectedBundle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Newmyfeed;

import React, { FC, lazy, Suspense, useState, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MyFeed } from "../pages/feeds/MyFeed";
import Setting from "./../pages/settings/Setting";
import Billing from "./../pages/billing/Billing";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import DetailNews from "../modules/News/DetailNews/DetailNews";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import Bundles from "./../modules/apps/home/components/Bundles";
import { useParams } from "react-router-dom";
import { AdminDashboardWrapper } from "../../_metronic/layout/AdminDashboard/AdminDashboardWrapper";
import { AdminMasterLayout } from "../../_metronic/layout/AdminDashboard/AdminMasterLayout";
import Favourite from "../modules/apps/home/components/Favourite";
import ReadLater from "../modules/apps/home/components/ReadLater";
import FeedsBundle from "../modules/apps/home/components/Bundles/CreateBundle/FeedsBundle";
import Search from "../pages/Search/Search";
import { Keyword } from "../modules/Policies/components/Keyword";
import { Blacklisted } from "../modules/Policies/components/Blacklisted";
import axios from "axios";
import authHeader from "../auth-header";
import { AxiosRequestHeaders } from "axios";
import { BarLoader } from "react-spinners";
import Error from "../pages/Error";
// import Success from "../pages/billing/Success";
import Success from "../modules/auth/components/Success";
import Verifyemail from "../pages/settings/Verifyemail";
import Getpocket from "../pages/dashboard/Getpocket";
import Sourceschedule from "../modules/apps/home/components/Bundles/CreateBundle/Sourceschedule";
import BundleArticle from "../modules/apps/home/components/Bundles/CreateBundle/Bundlearticle";
import Error404 from "../pages/Error404";
import BillingHistory from "../pages/billing/BillingHistroy";
import Usage from "../pages/billing/Usage";
import Privatelanding from "../modules/apps/home/components/privatelandingpage/Privatelanding";
import Searchgpt from "../pages/Searchgpt/Searchgpt";

const PrivateRoutes = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const is_admin = localStorage.getItem("is_admin");
    if (is_admin) {
      setIsAdmin(JSON.parse(is_admin));
    }
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_feeds_v1`, {
        headers: authHeader() as AxiosRequestHeaders,
      })
      .then((response) => {
        // console.log("Response in URLs", response.status);
        if (response.status === 200) {
          const fetchedFeeds = response.data.result;
          setTokenValid(true);
        } else if (response.status === 401) {
          // console.log("Feeds:", response.data);
          setTokenValid(false);
        } else {
          console.error("Error fetching feeds. Status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching feeds:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const spinnerStyles = {
    display: "block",
    margin: "0 auto",
    borderColor: "#007bff",
  };

  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));

  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const Home = lazy(() => import("../modules/apps/home/Home"));

  const FeedList = lazy(() => import("../pages/feeds/FeedList"));
  const NewsPage = lazy(() => import("./../modules/News/NewsPage"));
  const PoliciesPage = lazy(() => import("./../modules/Policies/PoliciesPage"));
  const { id } = useParams();
  const parsedId = id ? parseInt(id) : 0;
  const authRoute = <Navigate to="/auth" replace />;
  // console.log(tokenValid);

  if (loading) {
    return (
      <div className="spinner-container">
        <BarLoader color={"#007bff"} loading={loading} style={spinnerStyles} />
      </div>
    );
  }
  return (
    <Routes>
      <Route path="*" element={<Error404 />} />

      {isAdmin ? (
        ""
      ) : tokenValid ? (
        <Route element={<MasterLayout />}>
          <Route path="user/dashboard" element={<DashboardWrapper />} />

          <Route path="user/feeds" element={<MyFeed />} />
          {/* <Route path="user/getpocket" element={<Getpocket />} /> */}
          <Route path="user/article/fetcharticle" element={<Getpocket />} />

          {/* <Route path="user/article/fetcharticle" element={<FetchArticle />} /> */}

          <Route path="user/keyword" element={<Keyword />} />
          <Route path="user/blacklisted" element={<Blacklisted />} />

          <Route path="user/search" element={<Search />} />
          <Route path="user/searchgpt" element={<Searchgpt />} />

          <Route path="user/setting" element={<Setting />} />
          <Route path="user/verifyemail" element={<Verifyemail />} />
          <Route path="user/billing" element={<Billing />} />
          <Route path="user/favourite" element={<Favourite />} />
          <Route path="user/read-later" element={<ReadLater />} />
          <Route path="user/bundle" element={<FeedsBundle />} />
          <Route
            path="/user/:bundleTitle/:bundleId"
            element={<BundleArticle />}
          />
          <Route path="user/sourceschedule" element={<Sourceschedule />} />
          <Route path="user/upgrade" element={<Usage />} />
          {/* <Route path="user/subscriber" element={<Privatelanding/>} /> */}

          <Route
            path="builder"
            element={
              <SuspensedView>
                <BuilderPageWrapper />
              </SuspensedView>
            }
          />

          <Route path="menu-test" element={<MenuTestPage />} />

          <Route
            path="crafted/pages/profile/*"
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path="crafted/pages/wizards/*"
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path="crafted/widgets/*"
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path="user/*"
            element={
              <SuspensedView>
                <NewsPage />
              </SuspensedView>
            }
          />
          <Route path="/detailnews/:id" element={<DetailNews />} />
          <Route
            path="crafted/account/*"
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path="apps/chat/*"
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path="apps/user-management/*"
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path="apps/home/*"
            element={
              <SuspensedView>
                <Home />
              </SuspensedView>
            }
          />

          <Route
            path="myfeeds/*"
            element={
              <SuspensedView>
                <PoliciesPage />
              </SuspensedView>
            }
          />
          <Route
            path="/myfeed/feedlist"
            element={
              <SuspensedView>
                <FeedList className="mb-5 mb-xl-6" />
              </SuspensedView>
            }
          />
          {/* <Route
          path='/success/:query_string'
          element={
            <SuspensedView>
              <Success/>
            </SuspensedView>
          }
        /> */}
          <Route path="*" element={<Error />} />
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/auth/login" />} />
      )}
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

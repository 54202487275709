// import React from 'react';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

// const Zoom = () => {
//   return (
//     <TransformWrapper
//       initialScale={1}
//       initialPositionX={0}
//       initialPositionY={0}
//     >
//       {({ zoomIn, zoomOut, resetTransform }) => (
//         <React.Fragment>
//           <TransformComponent>
//             {/* Your entire website content goes here */}
//             <div style={{ width: '100%', height: '100%', background: '#f0f0f0' }}>
//               <h1>Zoom pan</h1>
//               {/* Add more content here */}
//             </div>
//           </TransformComponent>
//         </React.Fragment>
//       )}
//     </TransformWrapper>
//   );
// };

// export default Zoom;



// export default Zoom;
// Zoom.jsx
// Zoom.jsx
// import React, { useState, ReactNode } from 'react';

// interface ZoomProps {
//   children?: ReactNode;
// }

// const Zoom: React.FC<ZoomProps> = ({ children }) => {
//   const [zoomLevel, setZoomLevel] = useState(1);

//   const handleZoomIn = () => {
//     setZoomLevel(prevZoom => prevZoom + 0.1);
//   };

//   const handleZoomOut = () => {
//     setZoomLevel(prevZoom => Math.max(1.0, prevZoom - 0.1));
//   };

//   return (
//     <div className="zoomed-content" style={{ transform: `scale(${zoomLevel})` }}>
//       {children}
//       {/* Render any other components or content here */}
//       {/* You can also add UI controls for zooming in and out */}
//       <button onClick={handleZoomIn}>Zoom In</button>
//       <button onClick={handleZoomOut}>Zoom Out</button>
//     </div>
//   );
// };

// export default Zoom;
// ZoomContext.js
import React, { createContext, useContext, useState } from 'react';

const ZoomContext = createContext();

export const ZoomProvider = ({ children }) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const zoomIn = () => {
    setZoomLevel(prevZoom => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoomLevel(prevZoom => Math.max(1.0, prevZoom - 0.1));
  };

  return (
    <ZoomContext.Provider value={{ zoomLevel, zoomIn, zoomOut }}>
      {children}
    </ZoomContext.Provider>
  );
};

export const useZoom = () => {
  return useContext(ZoomContext);
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import authHeader from "../../auth-header";
import { AxiosRequestHeaders } from "axios";

interface BillingData {
  total_credits: number;
  credits_used: number;
  remaining_credits: number;
  expiry_date: string;
}

const BillingHistory = () => {
  const [billingData, setBillingData] = useState<BillingData | null>(null);

  useEffect(() => {
  
    axios
    .get(`${process.env.REACT_APP_API_URL}/policy/get_user_credits`, {
      headers: authHeader() as AxiosRequestHeaders,
    })
     
      .then((response) => {
        setBillingData(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);
  

  return (
    <div className="card">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Credit History</span>
          <span className="text-muted mt-1 fw-semibold fs-7">History</span>
        </h3>
      </div>
      <div className="card-body py-3">
        {billingData ? (
          <table className="table">
            <thead>
              <tr className="fw-bold">
                <th>Total Credits</th>
                <th>Credits Used</th>
                <th>Remaining Credits</th>
                <th>Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{billingData.total_credits}</td>
                <td>{billingData.credits_used}</td>
                <td>{billingData.remaining_credits}</td>
                <td>{billingData.expiry_date}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {/* end::Body */}
    </div>
  );
};

export default BillingHistory;

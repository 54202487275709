import React from 'react';
import { Link } from 'react-router-dom';
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaLinkedin,
  FaInstagram,
  FaVimeoV,
  FaDribbble,
  FaBehance,
  FaEnvelopeOpen
} from 'react-icons/fa';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
// import ServiceData from '../../data/service/ServiceMain.json';
// import { slugify } from '../../utils';

// const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer class="mt-10 footer-area bg-dark text-center text-lg-start bg-body-tertiary text-muted">
      <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div class="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
    
        <div>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-google"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-linkedin"></i>
          </a>
          <a href="" class="me-4 text-reset">
            <i class="fab fa-github"></i>
          </a>
        </div>
      </section>
    
      <section class="">
     
        <div class=" text-center text-md-start mt-5">
          <div class="row mt-3">
       <div  >   <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/wire_logo.png")}
                className=""
                style={{ height: "90px" }}
              /></div>
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
         
              <h6 class="text-uppercase fw-bold mb-4 text-white">
               RSS Feeds
              </h6>
              <p>
              We would love to hear from you! If you have any questions, comments, or feedback about our products or services, please
                    don't hesitate to get in touch with us.
              </p>
            </div>
    
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4 text-white">
                Products
              </h6>
              <p>
                <a  class="text-reset">Bundle Creation</a>
              </p>
              <p>
                <a  class="text-reset">Feeds Creation</a>
              </p>
              <p>
                <a  class="text-reset">Preodic Tasks</a>
              </p>
             
            </div>
    
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 class="text-uppercase fw-bold mb-4 text-white">
                Useful links
              </h6>
              <p>
                <a href="/about" class="text-reset">About Us</a>
              </p>
              <p>
                <a href="/pricing" class="text-reset">Pricing</a>
              </p>
            
              <p>
                <a href="/documentation" class="text-reset">Documentation</a>
              </p>
              {/* <p>
                <a href="#!" class="text-reset">Help</a>
              </p> */}
            </div>
    
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 class="text-uppercase fw-bold mb-4 text-white">Contact</h6>
              <p><i class="fas fa-home me-3"></i> New York, NY 10012, US</p>
              <p>
                <i class="fas fa-envelope me-3"></i>
                info@example.com
              </p>
              <p><i class="fas fa-phone me-3"></i> + 01 234 567 88</p>
              <p><i class="fas fa-print me-3"></i> + 01 234 567 89</p>
            </div>
          </div>
        </div>
      </section>
    
      <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2021 Copyright:
        <a class="text-reset fw-bold" href="https://mdbootstrap.com/">Rssfeeds.com</a>
      </div>
    </footer>
  );
};

export default FooterOne;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Card, Button, Row, Col } from 'react-bootstrap';
import authHeader from '../../../auth-header';
import { AxiosRequestHeaders } from "axios";

interface Plan {
  id: number;
  title: string;
  description: string;
  price_id: string;
  price: number;
  credits: {
    credit_value: number;
  };
}

const Price = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const biannualResponse = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_subscription_plans/biannual`);
        const monthlyResponse = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_subscription_plans/monthly`);
        const annualResponse = await axios.get(`${process.env.REACT_APP_API_URL}/policy/get_subscription_plans/annual`);

        if (biannualResponse.data.status === 200 && monthlyResponse.data.status === 200 && annualResponse.data.status === 200) {
          setPlans([...biannualResponse.data.result, ...monthlyResponse.data.result, ...annualResponse.data.result]);
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    };

    fetchPlans();
  }, []);

  const handleSubmit = async (planId: number, priceId: string, title: string) => {
    if (window.localStorage.getItem('token')) {
      const formData = new FormData();
      formData.append('id', planId.toString()); // Using 'id' as the key
      console.log("ID", planId);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/policy/create_checkout_session`, // URL
          formData, // Data to be sent in the request body
          {
            headers: authHeader() as AxiosRequestHeaders,
          }
        );
  
        if (response.data.status === 200) {
          console.log("pricing", response.data)
          window.location.href = response.data.url;
        } else if (response.data.status === 400) {
          console.error('CANNOT PROCEED PAYMENT');
        }
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
    } else {
      navigate('/auth/login');
    }
  };
  

  return (
    <Row className='justify-content-center d-flex p-10' style={{ margin: 0, gap: 0 }}>
      <div className='d-flex justify-content-center'>
        <h1 className='Subscriptionplan fw-bold text-xxl'>One Subscription, Unlimited Possibilities</h1>
      </div>
      {plans.map(plan => (
    <Col key={plan.id} sm={12} md={6} lg={4} className='mt-5 d-flex justify-content-center' style={{ margin: 0 }}>
    <Card className='pricingpackages shadow card-hover' style={{ width: '80%', margin: 0 }}>
            <Card.Body>
              <div className='d-flex align-items-center justify-content-center gap-1 '>
                <div>{/* Image */}</div>
                <div className='fs-1 fw-bold'>{plan.title}</div>
              </div>
              <div className='d-flex gap-3 align-items-center ps-2 pe-2'>
                <div className='d-flex'>
                  <div className='fw-bold' style={{ fontSize: '38px' }}> $</div>
                  <div className='fw-bold' style={{ fontSize: '28px' }}>
                    {plan.price}
                  </div>
                </div>
                <div className='fs-7 fw-bold'>
                  {plan.description}
                </div>
              </div>
              <div className='justify-content-center d-flex'>
              <Button
                className='mt-3 '
                onClick={() => handleSubmit(plan.id, plan.price_id, plan.title)}
              >
                Go {plan.title}
              </Button>
              </div>
             
              <div className='d-flex flex-column gap-3 mt-3'>
                <h4 style={{ fontFamily: 'Raleway', color: 'black' }} className='fs-3'>
                  Package Detail
                </h4>
                <div className='d-flex flex-column gap-2'>
                  <div className='fs-5 fw-bold d-flex gap-2 align-items-center'>
                    <i className='bi bi-check-circle fs-3'></i>
                    <div className='d-flex'>Credits: {plan.credits.credit_value}</div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Price;


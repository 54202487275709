// Import necessary dependencies
import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../../auth-header';

// Deactivate component
const Deactivateuser = () => {
  // Function to handle the delete account button click
  const handleDeleteAccount = async () => {
    try {
      // Prepare data to send to the backend API
      const dataToSend = {
        action_type: 'disable',
      };

      // Make API request using Axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_portal/api/disable_user_account`,
        dataToSend,
        {
          headers: authHeader(),
        }
      );

      console.log(response.data, "deactivate");

      // Check if the response status is 200
      if (response.data && response.data.status === 200) {
        // Display success message
        toast.success(response.data.message);

        // Handle any other actions or UI updates you may need here
      } else if  (response.data && response.data.status === 204)  {
        toast.error(response.data.message);
      }
      else if  (response.data && response.data.status === 400)  {
        toast.error(response.data.message);
      }
      else if  (response.data && response.data.status === 401)  {
        toast.error(response.data.message);
      }
      
    } catch (error) {
      // Display error message
      toast.error('Error deactivating account. Please try again.');

      // Handle any other error-related actions or UI updates here
    }
  };

  return (
    <div className='mt-3'>
      {/* Heading */}
      <h1 style={{ fontWeight: 'bold' }}>Deactivate Account</h1>

      {/* Text */}
      <p>Deactivating your account will remove all of your information from our database. This cannot be undone.</p>

      {/* Delete Account Button */}
      <button className='btn btn-primary' onClick={handleDeleteAccount}>Deactivate Account</button>

      {/* Toast Container for displaying messages */}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default Deactivateuser;

import React from 'react';
import { Link } from 'react-router-dom';
import HeaderOne from './Header/HeaderOne';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import main from '../landingpage/about/main.png'
import FooterOne from './FooterOne';


const About = () => {
    return (
      
            <div className="section section-padding case-study-featured-area">
                  <HeaderOne/>
                <div className="container d-flex justify-content-between" style={{marginTop:"200px"}}>
                    <div className="row d-flex justify-content-between">
                 
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle mt-5 fs-2 fw-bold"style={{color:"#FF6E00"}}>Welcome to our platform</span>
                            <h2 className="title mt-2 fs-4">The ultimate destination for RSS Feeds enthusiasts!</h2>
                            <p>Designed with convenience and efficiency in mind, our platform serves as your personalized hub for collecting, organizing, and accessing your favorite RSS feeds. Whether you're an avid news reader, a tech enthusiast, or a passionate follower of niche blogs, our platform offers a seamless experience to keep you informed and entertained.</p>
                            <p>With our intuitive interface, you can effortlessly subscribe to your preferred RSS feeds, ensuring that you never miss out on the latest updates from your favorite sources. Our powerful organization features allow you to categorize and prioritize your feeds, making it easy to navigate through the vast sea of content available online. Whether you're browsing from your desktop or on the go with our mobile app, our platform ensures that you have instant access to the content that matters most to you. Join us today and revolutionize the way you consume content with our feature-rich RSS Feeds platform!</p>
                            {/* <Link style={{textDecoration:"none"}} to="#" className="axil-btn btn-fill-primary btn-large">Read More</Link> */}
                        </div>
                        {/* <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="7" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="20" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Sources are being monitored</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb ">
                        {/* <img src={process.env.PUBLIC_URL + "/landingpage/about/main.png"} alt="main" /> */}
                         <img src={main} alt="main" />
                        </div>
                    </div>
                </div>
            </div>
            <FooterOne/>
        </div>

    )
}

export default About;
import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

import axios from "axios";
import { toast } from "react-toastify";
import Feedlabel from "./CreateBundle/Feedlabel";
import EditFeeds from "../../../../../pages/feeds/EditFeeds";
import authHeader from "../../../../../auth-header";
import EditFeed from "./CreateBundle/Editfeed";
const EditBundleV1 = ({
  isOpen,
  onClose,
  initialBundle,
  initialSources,
  news_sources,
  social_sources,
}) => {
  console.log(initialBundle);
  const [bundleData, setBundleData] = useState({
    bundle_title: initialBundle ? initialBundle.bundle_title : "",
    // interval_type: initialBundle ? initialBundle.interval_type : "",
    feed_id: initialBundle ? initialBundle.feed_id : "",
    feed_title:
      initialBundle && initialBundle.feeds && initialBundle.feeds.length > 0
        ? initialBundle.feeds[0].feed_title
        : "",
  });
  console.log("initialBundle ", initialBundle);
  const [formData, setFormData] = useState({ feedTitles: [] });
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newKeywordPrompt, setNewKeywordPrompt] = useState(false);
  const initialFeedIds =
    initialBundle && initialBundle.feeds
      ? initialBundle.feeds.map((feed) => feed.id)
      : [];
  const [selectedFeeds, setSelectedFeeds] = useState(initialFeedIds);
  console.log(selectedFeeds);
  const [whitelistedKeywords, setWhitelistedKeywords] = useState(
    initialBundle.keywords
  );
  const [newKeyword, setNewKeyword] = useState("");

  const [newsSources, setNewsSources] = useState(news_sources);
  const [socialSources, setSocialSources] = useState(social_sources);
  useEffect(() => {
    fetchFeedTitles();
  }, []);

  const fetchFeedTitles = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/policy/get_enabled_feeds`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.status === 200) {
          const feedTitles = response.data.result;
          // Update the state to store feed titles
          setFormData((prevFormData) => ({
            ...prevFormData,
            feedTitles: feedTitles,
          }));
        } else {
          console.error("Failed to fetch feed titles:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching feed titles:", error);
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setBundleData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleKeywordChange = (event) => {
    setNewKeyword(event.target.value);
    setNewKeywordPrompt(event.target.value.trim().length > 0);
  };

  const handleKeywordKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      handleAddKeyword();
    }
  };

  const handleAddKeyword = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (newKeyword && newKeyword.trim() !== "") {
      setWhitelistedKeywords((prevKeywords) => {
        const existingKeyword = prevKeywords.find(
          (keyword) => keyword.keyword === newKeyword
        );
        const updatedKeywords = existingKeyword
          ? prevKeywords.map((keyword) =>
              keyword.keyword === newKeyword
                ? { ...keyword, id: existingKeyword.id }
                : keyword
            )
          : [...prevKeywords, { id: null, keyword: newKeyword }];

        return updatedKeywords;
      });

      setNewKeyword("");
    }
  };

  const handleRemoveKeyword = (remove_index) => {
    let updatedKeywords = whitelistedKeywords.filter(
      (_, index) => index !== remove_index
    );

    setWhitelistedKeywords(updatedKeywords);
  };

  const handleNewsChange = (newValue) => {
    setNewsSources((prevSources) => {
      const isPresent = prevSources.includes(newValue);

      if (isPresent) {
        return prevSources.filter((source) => source !== newValue);
      } else {
        return [...prevSources, newValue];
      }
    });
  };

  const handleSocialChange = (newValue) => {
    setSocialSources((prevSources) => {
      const isPresent = prevSources.includes(newValue);

      if (isPresent) {
        return prevSources.filter((source) => source !== newValue);
      } else {
        return [...prevSources, newValue];
      }
    });
  };

  useEffect(() => {
    if (!bundleData.bundle_title || bundleData.bundle_title.trim() === "") {
      setValidationError("Please enter a title.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
    }
  }, [bundleData.bundle_title]);

  useEffect(() => {
    if (selectedFeeds.length === 0) {
      setValidationError("Please select at least one feed.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
    }
  }, [selectedFeeds]);

  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (!bundleData.bundle_title || bundleData.bundle_title.trim() === "") {
      setValidationError("Please enter a title.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }

    if (selectedFeeds.length === 0) {
      setValidationError("Please select at least one feed.");
      setTimeout(() => {
        setValidationError("");
      }, 5000);
      return;
    }

    console.log("Form submitted", selectedFeeds);

    const formData = new FormData();
    console.log("selectedFeeds", selectedFeeds);
    formData.append("id", initialBundle.id);
    formData.append("bundle_title", bundleData.bundle_title);
    formData.append("feeds", selectedFeeds.map(Number).join(","));
    console.log("bundle_title", bundleData.bundle_title);
    console.log("feeds", bundleData.feeds);

    setIsLoading(true);
    console.log("formData", formData);

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/policy/update_bundle_v6_with_new_flow/${initialBundle.id}`,
        formData,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        console.log("this is response", response);
        if (response.data.status === 200) {
          toast.success(response.data.message, { autoClose: 5000 });
          window.location.reload();
        } else {
          toast.error(response.data.message, { autoClose: 5000 });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error updating bundle:", error);
        setIsLoading(false);
        // Handle error
        // toast.error("An error occurred while updating the bundle.", {
        //   autoClose: 5000,
        // });
      });
  };

  const handleCountryChange = (updatedSelection) => {
    setSelectedFeeds(updatedSelection);
    // if (initialSelectedCountries && initialSelectedCountries.length > 0) {
    //   // If there are initially selected feeds, keep them selected
    //   setSelectedFeeds(initialSelectedCountries);
    //   console.log("this is response", response);

    // }
  };
  // const handleCountryChange = (updatedSelection: string[]) => {
  //   setSelectedFeeds(updatedSelection);
  //   onCountryChange(updatedSelection);
  // };
  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      tabIndex={-1}
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Bundle</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>
          {validationError && (
            <div className="alert alert-danger" role="alert">
              {validationError}
            </div>
          )}
          <form onSubmit={handleSubmitForm}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="bundle_title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="bundle_title"
                  name="bundle_title"
                  value={bundleData.bundle_title}
                  onChange={handleInputChange}
                  placeholder="Enter Title"
                />
              </div>

              <EditFeed
                initialBundle={initialBundle}
                value={bundleData.feed_title}
                onCountryChange={handleCountryChange}
                selectedFeeds={selectedFeeds}
                setSelectedFeeds={setSelectedFeeds}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                {isLoading ? "Adding...Please Wait" : "Update"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditBundleV1;

import React, { useState, useEffect } from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { MdError } from "react-icons/md";

import { useLocation } from "react-router-dom";
import axios from "axios";

function Deactivate() {
  const location = useLocation();
  const [responseStatus, setResponseStatus] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");

    if (token) {
      const apiUrl = `${process.env.REACT_APP_API_URL}/user_portal/api/verify_user_before_disable_their_account?token=${token}`;

      axios
        .get(apiUrl)
        .then((response) => {
          setResponseStatus(response.data.status);
          setResponseMessage(response.data.message);
        })
        .catch((error) => {
          console.error("Error verifying email:", error);
          // If error occurs, assume link expired
          setResponseStatus(400);
          setResponseMessage("Link expired. Please regenerate the link.");
        });
    }
  }, [location.search]);

  localStorage.removeItem("token");
  localStorage.removeItem("auth_token");

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="mt-5 text-center">
        {responseStatus === 200 ? (
          <>
            <RiCheckboxCircleFill className="text-success" size={50} />
            <h2 className="mt-3">
              Your Account has been Deactivated Successfully!
            </h2>
          </>
        ) : (
          <div>
            {" "}
            <MdError style={{ color: "red" }} size={50} />
            <h2 className="mt-3">{responseMessage}</h2>
          </div>
        )}
      </div>
    </div>
  );
}

export default Deactivate;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { useLayout } from "../../core";
import { HeaderToolbar } from "./HeaderToolbar";
import { responsiveFontSizes } from "@mui/material";
import PopularTopicsWidget from "../../../partials/widgets/lists/Populartopicswidget";

export function HeaderWrapper() {
  const { config, classes, attributes } = useLayout();
  const { aside } = config;

  return (
    <div>
      {/* Include <PopularTopicsWidget /> as the header */}
      <PopularTopicsWidget />

      <div
        id="kt_header"
        className={clsx(
          "header",
          classes.header.join(" "),
          "align-items-stretch"
        )}
        {...attributes.headerMenu}
      >
        {/* begin::Brand */}
        <div className="header-brand">
          {/* begin::Logo */}
          <Link to="/user/dashboard">
            <div
              className="header-logo d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/wire_logo.png")}
                className=""
                style={{ height: "90px" }}
              />
              {/* <span className="logo-text text-center" style={{ marginLeft: "10px", fontSize: "18px", color: "white" }}>
              The Wire Today
            </span> */}
            </div>
          </Link>
          {/* end::Logo */}

          {aside.minimize && (
            <div
              id="kt_aside_toggle"
              className="btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
            >
              <KTIcon
                iconName="exit-left"
                className="fs-1 me-n1 minimize-default"
              />
              <KTIcon iconName="entrance-left" className="fs-1 minimize-active" />
            </div>
          )}

          {/* begin::Aside toggle */}
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-30px h-30px"
              id="kt_aside_mobile_toggle"
            >
              <KTIcon iconName="abstract-14" className="fs-1" />
            </div>
          </div>
          {/* end::Aside toggle */}
        </div>
        {/* end::Brand */}
        <HeaderToolbar />
      </div>
    </div>
  );
}

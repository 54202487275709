import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authHeader from "../../../../auth-header";
function ResendMFA() {
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerificationCodeValid, setIsVerificationCodeValid] = useState(true);
  const navigate = useNavigate();

  const handleVerificationCodeChange = (e) => {
    const value = e.target.value;
    setVerificationCode(value);
    setIsVerificationCodeValid(true);
  };

  // const authHeader = () => {
  //   const tokenString = localStorage.getItem("token");
  //   const token = tokenString ? JSON.parse(tokenString) : null;
  //   if (token) {
  //     return { Authorization: `Bearer ${token.access}` };
  //   } else {
  //     return {};
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      verification_code: verificationCode,
      email: localStorage.getItem("email"),

    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_portal/api/verify2fa`,
        requestData,
        // { headers: authHeader(false) }
      );
      // console.log(response.data);

      if (response.data.status === 200) {
        toast.success(response.data.message);
        localStorage.setItem("get_pocket_token", response.data.result.get_pocket_token);
        localStorage.setItem('provider', response.data.result.provider);

        localStorage.setItem("is_admin", response.data.result.is_admin);
        // localStorage.setItem('token', response.data.result.token);
        
        // console.log("token",response.data.result.token)
        localStorage.setItem('token',response.data.result.token.access);

        // localStorage.setItem('get_pocket_token', response.data.result.get_pocket_token);
        if (response.data.result.is_admin) {
          navigate("/admin/dashboard");
        } else {
          navigate("/user/dashboard");
        }
      }else if (response.data.status === 401) {
        toast.error(response.data.message);
      }  else {
        // console.log(verificationCode, "Invalid verification code");
        // console.log(response.data, "Invalid verification code");

        toast.error(response.data.message);
        setIsVerificationCodeValid(false);
      }
    } catch (error) {
      console.error(error);
      // console.log("error");
      toast.error("An error occurred");
    }
  };
  const handleResendCode = async () => {
    try {
      const userEmail = localStorage.getItem("email");
      if (!userEmail) {
        toast.error("User email not found");
        return;
      }

      const requestData = {
        email: userEmail,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_portal/api/resend_mfa_code`,
        requestData
      );

      // console.log("response of otp", response.data);

      if (response.data.status === 200) {
        toast.success(response.data.message);
      } else if (response.data.status === 400) {
        toast.error(response.data.message);
      } else {
        toast.error(response.data.message || "Failed to resend verification code");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred");
    }
  };

  

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="mt-5 text-center">
          <h2>Authenticate Your Account</h2>
          <div className="mb-2">
            <p>
              Protecting your tickets is our top priority. Please confirm your
              account by entering the verification code sent to
            </p>
          </div>
          <input
            type="text"
            name="verificationCode"
            className={`mfa-input ${isVerificationCodeValid ? "" : "invalid"}`}
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            placeholder="Enter Verification Code"
          />
        </div>
        <div className="mt-5 row text-center">
          <div className="col-8">
            <p >
              It may take a minute to receive your code. Haven't received it?{" "}
              <button style={{border:"none" , backgroundColor:"transparent" , color:"blue"}} onClick={handleResendCode} className="btn-link">
                Resend a new code
              </button>
            </p>
          </div>

          <div className="col">
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResendMFA;

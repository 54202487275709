

// export default function authHeader() {
//     const tokenString = localStorage.getItem("token");
//     const token = tokenString ? JSON.parse(tokenString) : null;
//     if (token) {
//         // For Django Boot back-end
//         return { Authorization: "Bearer " + token.access };
//     } else {
//         return {};
//     }
// }
export default function authHeader() {
    const tokenString = localStorage.getItem("token");
    // const token = tokenString ? JSON.stringify(tokenString) : null;
    // console.log(token)
    if (tokenString ) {
      // For Django Boot back-end
      
      return { Authorization: "Bearer " + tokenString };
    } else {
      return {}; // Return an empty object if there's no token
    }
  }
  
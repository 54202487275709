import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";

const AboveFooterArticles = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/policy/home_page_articles_for_public_users?section=above_footer_section`
      );
      setArticles(response.data.results.result);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return an empty string if the input is null or undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  

  const getTruncatedContent = (content, maxLength) => {
    if (content.length <= maxLength) {
      return content;
    } else {
      return content.substring(0, maxLength) + '...';
    }
  };

  return (
    <div className="p-5">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
        {articles.map((article, index) => (
          <div className="col" key={index}>
            <div className="mb-5">
              <h2 className=" fs-5" style={{height:"50px"}}>{article.title}</h2>
              <h5 className="mt-2" style={{ color: "#FF6E00" }}>
                {capitalizeFirstLetter(article.article_category)}
              </h5>
              <div className="d-flex mb-10 mt-2 gap-2">
                <h5 className="text-bold fs-6">{capitalizeFirstLetter(article.source)}</h5>
                <h5 className="text-bold fs-6"> | </h5>
                <h5 className="text-bold fs-6">{article.author}</h5>
              </div>
              <p>{getTruncatedContent(article.summarized_content, 150)}</p>
              <a
                style={{ color: "#FF6E00" }}
                className="fs-7"
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More <FaArrowRight />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboveFooterArticles;
